import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/services/header.service';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { NlpDataset } from 'src/app/models/nlp/nlp-dataset/nlp-dataset';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';
import { AddNlpDatasetFileModalComponent } from 'src/app/components/modals/add-nlp-dataset-file-modal/add-nlp-dataset-file-modal.component';
import dayjs from 'dayjs';
import { getSidebarItems } from 'src/app/pages/portal/nlp/utils';
import { SidebarService } from 'src/app/services/sidebar.service';
import { ButtonType } from 'src/app/components/common/_types/ButtonType';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-nlp-dataset-files',
  templateUrl: './nlp-dataset-files.component.html',
  styleUrls: ['./nlp-dataset-files.component.scss']
})
export class NlpDatasetFilesComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  private nlpDatasetFilesUpdatesSubscription: Subscription;
  nlpDataset: NlpDataset;
  loading: boolean;
  items: SimpleListItem[] = [];
  corpId: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private nlpDojoService: NlpDojoService,
    private headerService: HeaderService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.corpId = 'default';
    this.setSidebarItems();
    this.reloadNlpDatasetFiles();
    this.nlpDatasetFilesUpdatesSubscription = this.nlpDojoService.nlpDatasetFileUpdates.subscribe(() => {
      this.reloadNlpDatasetFiles();
    });
  }

  reloadNlpDatasetFiles() {
    this.paramMapSubscription = combineLatest([this.route.paramMap]).subscribe(results1 => {
      const params = results1[0];
      const datasetSystemName = params.get('dataset');
      if (!datasetSystemName) {
        return;
      }
      this.loading = true;
      this.crtDataSubscription = combineLatest([
        this.nlpDojoService.getDataset('default', datasetSystemName)
      ]).subscribe(
        results2 => {
          if (!results2 || !results2[0]) {
            return;
          }
          this.loading = false;
          this.nlpDataset = results2[0];
          this.items = (this.nlpDataset?.files || [])
            .filter(file => file.created_at != null && file.updated_at != null)
            .map(file => {
              return {
                title: file.name,
                dataPoints: [
                  {
                    label: 'Created At',
                    value: dayjs(file.created_at).format('MM-DD-YYYY hh:mm A'),
                    className: 'simple-list-item-data-point--sm'
                  },
                  {
                    label: 'Updated At',
                    value: dayjs(file.updated_at).format('MM-DD-YYYY hh:mm A'),
                    className: 'simple-list-item-data-point--sm'
                  }
                ],
                buttons: [
                  {
                    label: 'Edit',
                    link: ['/portal', 'nlp', 'datasets', datasetSystemName, 'files', file.system_name]
                  },
                  {
                    label: 'Delete',
                    type: ButtonType.danger,
                    action: () => this.confirmAndDeleteDatasetFile(file.id, file.name)
                  }
                ]
              };
            });
          this.refreshUI();
        },
        error => {
          this.loading = false;
          this.toaster.error(error);
        }
      );
    });
  }

  private confirmAndDeleteDatasetFile(datasetFileId: string, datasetFileName: string) {
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.typeOfEntity = 'Dataset File';
    modalRef.content.entityValue = datasetFileName;
    modalRef.content.onDeleteConfirmed.subscribe(async () => {
      await this.nlpDojoService
        .deleteDatasetFile(this.corpId, this.nlpDataset.id, datasetFileId)
        .pipe(take(1))
        .toPromise();
      this.toaster.success('Dataset file deleted');
      this.reloadNlpDatasetFiles();
    });
  }

  refreshUI() {
    this.headerService.setPageTitle(`Dataset ${this.nlpDataset.name}`);
    this.setBreadcrumb(this.nlpDataset);
  }

  showAddModal() {
    this.modalService.show(AddNlpDatasetFileModalComponent, {
      ignoreBackdropClick: true,
      initialState: { datasetSystemName: this.nlpDataset.system_name }
    });
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.nlpDatasetFilesUpdatesSubscription) {
      this.nlpDatasetFilesUpdatesSubscription.unsubscribe();
    }
  }

  private setBreadcrumb(nlpDataset: NlpDataset) {
    this.breadcrumbService.set([
      {
        label: 'Training Datasets',
        route: `/portal/nlp/datasets`
      },
      {
        label: 'NLP Dataset Files',
        route: `/portal/nlp/datasets/${nlpDataset.system_name}`
      }
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }
}
