import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FlowTemplateModel } from 'src/app/models';
import { FlowTemplatesService, NodesService } from 'src/app/services/firestore';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';

@Component({
  selector: 'app-delete-flow-template-modal',
  templateUrl: './delete-flow-template-modal.component.html',
  styleUrls: ['./delete-flow-template-modal.component.scss'],
})
export class DeleteFlowTemplateModalComponent implements OnInit {
  flowTemplate: FlowTemplateModel;
  loading: boolean;

  deleteOptionName: string;
  deleteOptionMode = 'LEAVE_CHILDREN';

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private flowTemplatesService: FlowTemplatesService,
    private nodesService: NodesService,
    public modal: BsModalRef,
    private toaster: ToastrService,
    options: ModalOptions,
  ) {
    this.flowTemplate = new FlowTemplateModel();

    if (!options.initialState) {
      return;
    }
    const { flowTemplate } = options.initialState as any;
    this.flowTemplate = flowTemplate;
  }

  async ngOnInit() {}

  async deleteTemplate() {
    this.actionProgress.start();
    if (!this.deleteOptionMode) {
      this.toaster.error('Please Select Deletion Strategy');
      return;
    }
    try {
      await this.flowTemplatesService.removeFlowTemplate(this.flowTemplate.systemName);
      if (this.deleteOptionMode === 'DELETE_DERIVED_NODES') {
        await this.nodesService.removeNodesInFlow(this.flowTemplate.systemName);
      }
      this.toaster.success('Template deleted successfully');
    } catch (error) {
      this.toaster.error(error);
    }
    this.actionProgress.complete();
    this.modal.hide();
  }
}
