import { IFlowEditorEvent, FlowEventEditorTypes } from './flow-editor.events';
import { NodeModel } from 'src/app/models/node';
import { GlobalVariable } from 'src/app/models/bot';

export abstract class FlowEditorEventsDispatcher {
  handleFlowEditorEvent(flowEditorEvent: IFlowEditorEvent) {
    if (!flowEditorEvent) {
      return;
    }
    switch (flowEditorEvent.type) {
      case FlowEventEditorTypes.CREATE_NODE:
        this.createNode(flowEditorEvent.payload);
        break;

      case FlowEventEditorTypes.DELETE_NODE:
        this.deleteNode(flowEditorEvent.payload);
        break;

      case FlowEventEditorTypes.UPDATE_NODE:
        this.updateNode(flowEditorEvent.payload);
        break;

      case FlowEventEditorTypes.SAVE_CHANGES:
        this.saveChanges();
        break;

      case FlowEventEditorTypes.EDITOR_HAS_CHANGES:
        this.editorHasChanges(flowEditorEvent.payload);
        break;

      case FlowEventEditorTypes.SAVE_GLOBAL_VARIABLES:
        this.saveGlobalVariables(flowEditorEvent.payload);
        break;
    }
  }

  abstract createNode(node: NodeModel): void;
  abstract deleteNode(node: NodeModel): void;
  abstract updateNode(node: NodeModel): void;
  abstract saveChanges(): void;
  abstract editorHasChanges(hasChanges: boolean): void;
  abstract saveGlobalVariables(node: GlobalVariable[]): void;
}
