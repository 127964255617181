import { Injectable } from '@angular/core';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { last, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  task: AngularFireUploadTask;

  constructor(private storage: AngularFireStorage) {}

  upload(path: string, file: File): Observable<string> {
    this.task = this.storage.upload(path, file, {
      contentType: file.type,
    });
    return this.task.snapshotChanges().pipe(
      last(),
      mergeMap(() => {
        return this.storage.ref(path).getDownloadURL();
      }),
    );
  }

  getFileByPath(path: string): Observable<string> {
    return this.storage.ref(path).getDownloadURL();
  }
}
