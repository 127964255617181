import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { take } from 'rxjs/internal/operators/take';
import { UserModel } from 'src/app/models';
import { HLConversationModel } from 'src/app/models/conversations';
import { HierarchyElementModel } from 'src/app/models/hierarchy-element';
import { HierarchyElementsService } from 'src/app/services/firestore/hierarchy-elements.service';

@Component({
  selector: 'app-hl-conversation-sidebar',
  templateUrl: './hl-conversation-sidebar.component.html',
  styleUrls: ['./hl-conversation-sidebar.component.scss'],
})
export class HlConversationSidebarComponent implements OnChanges {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'strikeThrough',
        'redo',
        'superscript',
        'subscript',
        'backgroundColor',
        'textColor',
        'insertVideo',
        'insertImage',
      ],
      ['fontSize', 'fontName'],
    ],
  };

  @Input()
  selectedConversationId = '';

  @Input()
  conversation: HLConversationModel;

  @Output()
  removeTag: EventEmitter<string>;

  @Input()
  tags: string[] = [];

  @Input()
  userName: string;

  @Input()
  updatedAt: string;

  @Input()
  botUrl;

  @Input()
  serviceReps: UserModel[] = [];

  hierarchyLabel = '';

  constructor(private hierarchyElementsService: HierarchyElementsService) {
    this.removeTag = new EventEmitter<string>();
  }

  async ngOnChanges() {
    if (this.conversation.botId) {
      const { botId } = this.conversation;
      const hierarchyLookupKey = this.getHierarchyLookupKey(botId);
      const hierarchy = await this.getLastHierarchy(hierarchyLookupKey);

      if (hierarchy) {
        this.hierarchyLabel = hierarchy.label;
      }
    }
  }

  private getHierarchyLookupKey(botId: string): string {
    const botIdSplit = botId.split('-');
    const botIdSplitLength = botIdSplit.length;
    const hierarchyLookupKey = botIdSplit.slice(0, botIdSplitLength - 1).join('-');

    return hierarchyLookupKey;
  }

  private async getLastHierarchy(hierarchyLookupKey: string): Promise<HierarchyElementModel | null> {
    const hierarchyElement: HierarchyElementModel | null = await this.hierarchyElementsService
      .getHierarchyElement(hierarchyLookupKey)
      .pipe(take(1))
      .toPromise();
    return hierarchyElement;
  }
}
