import { NodeKpiModel, NodeModel } from '../../../models/node';

export function getBotUniqueKpis(nodes: NodeModel[]): NodeKpiModel[] {
  const uniqueKPIs: NodeKpiModel[] = [];

  nodes.forEach(node => {
    node.kpis?.forEach(nodeKpi => {
      const existingKpiIndex = uniqueKPIs.findIndex(kpi => kpi.systemName === nodeKpi.systemName);
      if (existingKpiIndex < 0) {
        uniqueKPIs.push(nodeKpi);
      }
    });
  });

  return uniqueKPIs;
}
