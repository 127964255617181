import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AvatarPickerModalComponent } from '../../modals/avatar-picker-modal/avatar-picker-modal.component';

@Component({
  selector: 'app-editable-image',
  templateUrl: './editable-image.component.html',
  styleUrls: ['./editable-image.component.scss']
})
export class EditableImageComponent implements OnInit {
  @Input()
  source: string | null;

  @Input()
  path: string;

  @Input()
  editable = true;

  @Input()
  avatarWidth: number;

  @Input()
  avatarHeight: number;

  // tslint:disable-next-line: no-output-native
  @Output()
  fileSelect: EventEmitter<any>;

  @Output()
  remove: EventEmitter<void>;

  reader: FileReader;

  constructor(private modalService: BsModalService) {
    this.fileSelect = new EventEmitter<any>();
    this.remove = new EventEmitter<void>();
    this.reader = new FileReader();
    this.reader.onload = event => {
      if (event.target) {
        this.source = this.reader.result as string;
      }
    };
  }

  ngOnInit() {}

  openAvatarPickerModal() {
    const modalRef = this.modalService.show(AvatarPickerModalComponent, { ignoreBackdropClick: true });
    modalRef.content.path = this.path;
    modalRef.content.fileSelect.subscribe(file => {
      this.fileSelect.emit(file);
      this.reader.readAsDataURL(file);
    });
  }

  get crtSource(): string | null {
    return this.source;
  }

  get scaledAvatarWidth() {
    return `${this.avatarWidth}px`;
  }

  get scaledAvatarHeight() {
    return `${this.avatarHeight}px`;
  }

  handleRemove(event) {
    this.remove.next();
    this.fileSelect.next(undefined);
    this.source = null;
    event.stopPropagation();
  }
}
