import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { CorpModel } from 'src/app/models/corp';
import { UploadService } from 'src/app/services/upload.service';
import { Observable, of, from } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { CorpsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-add-corp-modal',
  templateUrl: './add-corp-modal.component.html',
  styleUrls: ['./add-corp-modal.component.scss'],
})
export class AddCorpModalComponent implements OnInit {
  corp: CorpModel;
  loading: boolean;

  // Adding file upload support
  logoFile: File | null;

  @Input()
  writable: boolean;

  constructor(
    private corpsService: CorpsService,
    public modal: BsModalRef,
    private authService: AuthService,
    private clientEnvironmentService: ClientEnvironmentService,
    private toaster: ToastrService,
    private uploadService: UploadService,
  ) {
    this.corp = new CorpModel();
    this.loading = false;
    this.writable = true;
  }

  async ngOnInit() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }
    this.corp.createdBy = user.uid;
  }

  save() {
    this.loading = true;
    this.corp.id = this.corp.label
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/ /g, '_');

    const uploadObservable: Observable<void | null> = this.logoFile
      ? this.uploadService.upload('corps/' + this.corp.id + '/logo', this.logoFile).pipe(
          map(downloadUrl => {
            if (!this.corp) {
              return;
            }
            this.corp.logo = downloadUrl;
          }),
        )
      : of(null);
    uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.corp) {
            return of();
          }
          return from([await this.corpsService.addCorp(this.corp)]);
        }),
      )
      .subscribe(
        async () => {
          await this.clientEnvironmentService.setInitialEnvironment(this.corp.id);
          this.loading = false;
          this.modal.hide();
          this.toaster.success('Corp created');
        },
        error => {
          this.loading = false;
          this.toaster.error(error);
        },
      );
  }
}
