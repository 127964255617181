import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-lead-source-report',
  templateUrl: './lead-source-report.component.html',
  styleUrls: ['./lead-source-report.component.scss'],
})
export class LeadSourceReportComponent implements OnInit, AfterViewInit, OnChanges {
  reportDetails;
  displayTotals;
  dataSource;
  isLoading: boolean;
  reportType = 'LSR';

  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();
  @ViewChildren('leadSourceSort') leadSourceSort: QueryList<MatSort>;
  @ViewChildren('leadSourcePaginator') leadSourcePaginator: QueryList<MatPaginator>;

  columnsToDisplay = [
    'lead_source',
    'customers_messaged',
    'engagement_percent',
    'engagements',
    'appointments',
    'appointment_percent',
    'messages_sent',
  ];

  constructor(
    private apiGatewayAnalyticsService: ApiGatewayAnalyticsService,
    private authService: AuthService,
    private toaster: ToastrService,
  ) {}

  async ngOnInit() {
    const user = await this.authService.currentUser;
    this.isLoading = true;
    await this.getReportDetails();
    this.isLoading = false;
    this.dataSource.sort = this.leadSourceSort;
    this.dataSource.paginator = this.leadSourcePaginator;
  }

  ngAfterViewInit() {
    this.leadSourceSort.changes.subscribe((comps: QueryList<MatSort>) => {
      if (this.dataSource && this.dataSource.sort) {
        this.dataSource.sort = comps.first;
      }
    });
    this.leadSourcePaginator.changes.subscribe((comps: QueryList<MatPaginator>) => {
      if (this.dataSource && this.dataSource.paginator) {
        this.dataSource.paginator = comps.first;
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.startDate &&
        changes.startDate.previousValue &&
        changes.startDate.currentValue &&
        changes.startDate.currentValue !== changes.startDate.previousValue) ||
      (changes.endDate &&
        changes.endDate.previousValue &&
        changes.endDate.currentValue &&
        changes.endDate.currentValue !== changes.endDate.previousValue)
    ) {
      this.isLoading = true;
      this.reportDetails = [];
      await this.getReportDetails();
      this.isLoading = false;
    }
  }

  async getReportDetails() {
    try {
      const response: AnalyticsDashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
        this.botId,
        this.startDate,
        this.endDate,
        this.reportType,
      );

      this.reportDetails = [];
      if (response.tables.report.details != null) {
        const result = await Promise.all(
          response.tables.report.details.map(async details => {
            let key = 'engagements';
            const engagements = parseInt(details[key], 10);
            key = 'appointments';
            const appointments = parseInt(details[key], 10);
            key = 'customers_messaged';
            const customers_messaged = parseInt(details[key], 10);
            key = 'lead_source';
            const lead_source = details[key];
            key = 'messages_sent';
            const messages_sent = parseInt(details[key], 10);
            const engagement_percent = (customers_messaged > 0 ? engagements / customers_messaged : 0) * 100;
            const appointment_percent = (engagements > 0 ? appointments / engagements : 0) * 100.0;
            return {
              lead_source,
              customers_messaged,
              appointments,
              engagements,
              messages_sent,
              engagement_percent,
              appointment_percent,
            };
          }),
        );
        this.displayTotals = result.length > 0 ? result.slice(-1) : [];
        result.splice(result.length - 1);
        this.reportDetails = result;
      }
      this.dataSource = new MatTableDataSource(this.reportDetails);
    } catch (error) {
      console.log(error);
      this.toaster.error(error);
    }
  }

  onSearch(event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
