import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/services/header.service';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { NlpDatasetFile } from 'src/app/models/nlp/nlp-dataset/nlp-dataset-file';
import { SimpleEditorTab } from 'src/app/components/editors/simple-editor/_types/SimpleEditorTab';
import { getSidebarItems } from 'src/app/pages/portal/nlp/utils';
import { SidebarService } from 'src/app/services/sidebar.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RenameDatasetFileModalComponent } from 'src/app/components/modals/rename-dataset-file-modal/rename-dataset-file-modal.component';

@Component({
  selector: 'app-nlp-dataset-file',
  templateUrl: './nlp-dataset-file.component.html',
  styleUrls: ['./nlp-dataset-file.component.scss']
})
export class NlpDatasetFileComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  datasetSystemName: string;
  fileSystemName: string;
  nlpDatasetFile: NlpDatasetFile;
  loading: boolean;
  tabs: SimpleEditorTab[] = [
    {
      id: 'intent',
      label: 'Dataset File',
      data: '',
      language: 'chatito',
      logs: []
    }
  ];

  private nlpDatasetSubscription: Subscription;

  constructor(
    private modal: BsModalService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private nlpDojoService: NlpDojoService,
    private authService: AuthService,
    private toaster: ToastrService,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      results1 => {
        const params = results1[0];
        this.datasetSystemName = params.get('dataset') || '';
        this.fileSystemName = params.get('file') || '';
        if (this.datasetSystemName == '' || this.fileSystemName == '') {
          return;
        }
        this.loading = true;
        this.crtDataSubscription = combineLatest([
          this.nlpDojoService.getDatasetFile('default', this.datasetSystemName, this.fileSystemName)
        ]).subscribe(results2 => {
          if (!results2 || !results2[0]) {
            return;
          }
          this.loading = false;
          this.nlpDatasetFile = results2[0];
          this.tabs[0].data = NlpDatasetFile.getDecodedBlob(this.nlpDatasetFile.blob);
          this.refreshUI(this.datasetSystemName, this.fileSystemName);
        });
      }
    );
  }

  refreshUI(datasetSystemName: string, datasetFileSystemName: string) {
    this.setSidebarItems();
    this.headerService.setPageTitle(`Dataset File ${this.nlpDatasetFile.name}`);
    this.setBreadcrumb(datasetSystemName, datasetFileSystemName);
  }

  renameFile(tabs: SimpleEditorTab[]) {
    this.modal.show(RenameDatasetFileModalComponent, {
      ignoreBackdropClick: true,
      initialState: { datasetFile: this.nlpDatasetFile, datasetSystemName: this.datasetSystemName }
    });
  }

  async saveAll(tabs: SimpleEditorTab[]) {
    if (tabs[0].data === '') {
      this.toaster.warning(`Can't Save Empty File`);
      return;
    }
    NlpDatasetFile.setEncodeBlob(this.nlpDatasetFile, tabs[0].data);
    this.nlpDatasetSubscription = this.nlpDojoService
      .updateDatasetFile('default', this.datasetSystemName, this.fileSystemName, this.nlpDatasetFile)
      .subscribe(
        response => {
          this.toaster.success('Saved Dataset File');
          tabs[0].logs = [];
        },
        error => {
          this.toaster.warning('Error occured saving file, Please check the logs at the bottom of the page');
          tabs[0].logs.push({
            className: 'simple-editor-log--error',
            text: error
          });
        }
      );
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.nlpDatasetSubscription) {
      this.nlpDatasetSubscription.unsubscribe();
    }
  }

  private setBreadcrumb(datasetSystemName: string, datasetFileSystemName: string) {
    this.breadcrumbService.set([
      {
        label: 'Training Datasets',
        route: `/portal/nlp/datasets`
      },
      {
        label: 'NLP Dataset Files',
        route: `/portal/nlp/datasets/${datasetSystemName}`
      },
      {
        label: 'NLP Dataset File',
        route: `/portal/nlp/datasets/${datasetSystemName}/files/${datasetFileSystemName}`
      }
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }
}
