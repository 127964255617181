import { TimeInterval } from 'src/app/components/analytics/_types/TimeInterval';

export class AnalyticsCardInterval7D extends TimeInterval {
  constructor() {
    super('7d');
  }

  getPastDate(): Date {
    const date = new Date(Date.now());
    date.setDate(date.getDate() - 13);
    return date;
  }

  getExplanation(): string {
    return 'Over the last 7 days';
  }
}
