import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  authGuard$: Observable<boolean>;

  constructor(private router: Router, private authService: AuthService) {
    this.authGuard$ = this.authService.user$.pipe(
      map((user) => {
        if (!user) {
          this.router.navigate(['/login']);
        }
        return user != null;
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    if (url && !url.includes('login')) {
      this.authService.redirectUrl = url;
    } else {
      this.authService.redirectUrl = '';
    }
    return this.authGuard$;
  }

  canActivateChild(): Observable<boolean> {
    return this.authGuard$;
  }
}
