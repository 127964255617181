import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { ToastrService } from 'ngx-toastr';
import { NlpDataset } from '../../../models/nlp/nlp-dataset/nlp-dataset';
@Component({
  selector: 'app-nlp-dataset-create-modal',
  templateUrl: './nlp-dataset-create-modal.component.html',
  styleUrls: ['./nlp-dataset-create-modal.component.scss']
})
export class NlpDatasetCreateModalComponent implements OnInit {
  loading: boolean;
  corpId: string;
  datasetName: string;
  datasetAdded: EventEmitter<NlpDataset>;

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private nlpDojoService: NlpDojoService,
    public toaster: ToastrService
  ) {
    this.corpId = (options.initialState as any)?.corpId;
    this.datasetAdded = new EventEmitter<NlpDataset>();
  }

  ngOnInit() {}

  createDataset() {
    this.nlpDojoService.createDataset(this.corpId, this.datasetName).subscribe(createdNlpDataset => {
      this.modal.hide();
      this.datasetAdded.emit(createdNlpDataset);
      this.toaster.success('Dataset created successfully');
    });
  }
}
