import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export type recallCampaign = {
  fileId: string;
  fileName: string;
  dealershipCode: string;
  startDate: string;
  maxBatch: number;
  userName: string;
  id: string;
  customersCount: number;
};

type getConversationsReturn = {
  conversations: recallCampaign[];
};

const mock = false;

const urlValidate = environment.updateCampaign.urlValidate;
const urlMoveToProcess = environment.updateCampaign.urlMoveToProcess;
const urlGetConversations = environment.updateCampaign.getConversations;

const fileContentHEADER = 'data:text/csv;base64,';

export type validationResult = {
  success: boolean;
  errorLines: any[];

  errorMessage: string;
  numberOfErrors: number;
  fileId: string;
};
@Injectable({
  providedIn: 'root',
})
export class UploadCampaignService {
  constructor(private http: HttpClient) {}

  async getConversations(dealershipId: string): Promise<recallCampaign[]> {
    try {
      const finalURL = `${urlGetConversations}/${dealershipId}/conversation`;

      const ret = await this.http
        .get<getConversationsReturn>(finalURL, {
          headers: { Authorization: 'allow', 'Content-Type': 'application/json' },
        })
        .toPromise();

      return ret.conversations;
    } catch (e) {
      return e.message;
    }
  }

  async uploadData(
    fileId: string,
    dealershipId: string,
    startDate: string,
    maxBatch: number,
    userName: string,
    conversationName: string,
  ) {
    try {
      const body = {
        fileId,
        dealershipId,
        startDate,
        maxBatch,
        userName,
        conversationName,
      };

      await this.http
        .post(urlMoveToProcess, body, { headers: { Authorization: 'allow', 'Content-Type': 'application/json' } })
        .toPromise();

      return '';
    } catch (e) {
      return e.message;
    }
  }
  async validateInputMock(
    campaignName: string,
    email: string,
    fileName: string,
    fileContents: string,
  ): Promise<validationResult> {
    if (fileName === 'mocks.csv') {
      return {
        success: true,
        errorLines: [],
        errorMessage: '',
        numberOfErrors: 0,
        fileId: '',
      } as validationResult;
    }

    if (fileName === 'mocksError.csv') {
      return {
        success: false,
        errorLines: [
          {
            firstName: 'Guilherme',
            lastName: 'Bencke',
            email: 'gbencke@gmail.com',
            vin: 12345678,
            mmy: 'Ford/Fiesta/2015',
            phone: '55-99989121',
          },
          {
            firstName: 'Juan',
            lastName: 'Costa',
            email: 'jcosta@gmail.com',
            vin: 98765544,
            mmy: 'Nissan/Frontier/2015',
            phone: '55-99567556',
          },
        ],
        errorMessage: 'There are issues in the data',
        numberOfErrors: 100,
      } as validationResult;
    }

    return {
      success: false,
      errorLines: [],
      errorMessage: 'Unknown error',
      numberOfErrors: 0,
      fileId: '',
    } as validationResult;
  }
  async validateInput(
    campaignName: string,
    email: string,
    fileName: string,
    fileContents: string,
    dealershipId: string,
  ): Promise<validationResult> {
    if (mock) {
      return await this.validateInputMock(campaignName, email, fileName, fileContents);
    }

    try {
      const body = {
        data: fileContents.replace(fileContentHEADER, ''),
        filename: fileName,
        returnEmail: email,
        dealershipId,
        conversationName: campaignName,
      };

      const returned: any = await this.http
        .post(urlValidate, body, { headers: { Authorization: 'allow', 'Content-Type': 'application/json' } })
        .toPromise();

      if (returned.fileId && returned.invalid.quantity === 0) {
        return {
          success: true,
          errorLines: [],
          errorMessage: '',
          numberOfErrors: 0,
          fileId: returned.fileId,
        } as validationResult;
      } else {
        return {
          success: false,
          errorLines: returned.invalid.entries.map(x => {
            const row = x.row;
            if (x.missingField) {
              row[x.missingField] += 'Missing Field';
            }
            return row;
          }),
          errorMessage: '',
          numberOfErrors: returned.invalid.entries.length,
          fileId: '',
        } as validationResult;
      }

      return {} as validationResult;
    } catch (e) {
      return {
        success: false,
        errorLines: [],
        errorMessage: `API Returned: ${e.message}`,
        numberOfErrors: 0,
        fileId: '',
      } as validationResult;
    }
  }
}
