import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { Subject } from 'rxjs';
import { handlebarsToJson } from '../../../utils/handlebars/handlebars.utils';
import { tryParseToJson } from '../../../utils/strings/string.utils';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-visual-template-editor',
  templateUrl: './visual-template-editor.component.html',
  styleUrls: ['./visual-template-editor.component.scss']
})
export class VisualTemplateEditorComponent implements OnInit, AfterContentInit {
  public onClose: Subject<boolean>;
  public templateAsJson: any;
  public openBlocks: string[];
  public closeBlocks: string[];
  public readonly show = !environment.production;

  @Output() dataChanged = new EventEmitter();
  @Input() data: string;

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  ngAfterContentInit(): void {
    this.templateAsJson = handlebarsToJson(this.data) ?? [{ element: 'template' }];
  }

  onTemplateSaved(jsonData) {
    this.dataChanged.emit(jsonData);
  }
}
