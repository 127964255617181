export class ConversationLogFilter {
  conversationId?: string;
  userId?: string;
  containingText?: string;
  dateRange?: Date[] = [new Date(), new Date()];
  anyDate?: boolean;
  sortByTimeOrder: 'asc' | 'desc' = 'desc'; // :eyes

  constructor(anyDate: boolean, conversationId?: string) {
    this.anyDate = anyDate;
    if (conversationId) {
      this.conversationId = conversationId;
    }
  }
}
