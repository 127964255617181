import { NodeKpiModel } from './node';

export class CorpModel {
  label: string;
  id: string;
  logo: string | undefined;
  createdBy: string;
  kpis: NodeKpiModel[];
  inactive: boolean;
}
