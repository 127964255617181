import { SimpleCardItemButton } from './SimpleCardItemButton';

export class SimpleCardItem {
  id: string;
  text: string;
  buttons: SimpleCardItemButton[];

  constructor(id: string, text: string, buttons: SimpleCardItemButton[]) {
    this.id = id;
    this.text = text;
    this.buttons = buttons;
  }
}
