import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ApiQueryModel } from 'src/app/models/api-query';
import { ToastrService } from 'ngx-toastr';
import { ApiQueriesService, FlowTemplatesService } from 'src/app/services/firestore';
import { ActionProgressComponent } from '../../action-progress/action-progress.component';

@Component({
  selector: 'app-api-query-general-info',
  templateUrl: './api-query-general-info.component.html',
  styleUrls: ['./api-query-general-info.component.scss'],
})
export class ApiQueryGeneralInfoComponent implements OnInit {
  loading: boolean;

  @Input()
  writable: boolean;

  @Input()
  apiQuery: ApiQueryModel;

  @Input()
  shouldAdd = false;

  @Output()
  saved: EventEmitter<void>;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;

  constructor(
    private apiQueriesService: ApiQueriesService,
    private flowTemplatesService: FlowTemplatesService,
    private toaster: ToastrService,
  ) {
    this.saved = new EventEmitter<void>();
  }

  ngOnInit() {}

  save() {
    this.loading = true;
    if (this.shouldAdd) {
      this.add();
    } else {
      this.update();
    }
  }

  private async add() {
    this.loading = true;
    this.actionProgress.start();
    ApiQueryModel.generateSystemName(this.apiQuery);
    try {
      if (this.apiQuery.flowTemplateId) {
        await this.flowTemplatesService.addFlowTemplatesApiQuery(this.apiQuery);
      } else {
        await this.apiQueriesService.addApiQuery(this.apiQuery);
      }
      this.toaster.success('API Query created');
      this.saved.emit();
    } catch (error) {
      this.toaster.error(error);
    }
    this.loading = false;
    this.actionProgress.complete();
  }

  private async update() {
    this.loading = true;
    this.actionProgress.start();
    ApiQueryModel.generateSystemName(this.apiQuery);
    try {
      if (this.apiQuery.flowTemplateId) {
        await this.flowTemplatesService.updateFlowTemplatesApiQuery(this.apiQuery);
      } else {
        await this.apiQueriesService.updateApiQuery(this.apiQuery);
      }
      this.toaster.success('API Query updated');
      this.saved.emit();
    } catch (error) {
      this.toaster.error(error);
    }
    this.loading = false;
    this.actionProgress.complete();
  }
}
