import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'app-sparkline-chart',
  templateUrl: './sparkline-chart.component.html',
  styleUrls: ['./sparkline-chart.component.scss']
})
export class SparklineChartComponent implements OnChanges, AfterViewInit {
  loading: boolean;

  @ViewChild('chart', { static: true }) chartEl: ElementRef;

  @Input()
  sparklineData: number[];

  sparklineLabels: string[];
  chart: Chart;

  constructor() {
    this.loading = true;
  }

  ngOnChanges() {
    // we just need to lave a list of labels having the same length with the datasets
    this.sparklineLabels = this.sparklineData.map(data => 'x');

    if (this.chart) {
      this.updateChartData();
    }
  }

  private updateChartData() {
    this.loading = true;
    this.chart.config.data = {
      labels: this.sparklineLabels,
      datasets: [
        {
          data: this.sparklineData
        }
      ]
    };

    this.chart.update({ easing: 'easeInOutQuart' });
    this.loading = false;
  }

  ngAfterViewInit() {
    this.chart = new Chart(this.chartEl.nativeElement, this.getSparklineChartOptions());
    this.updateChartData();
  }

  initializeChart() {
    this.chart = new Chart(this.chartEl.nativeElement, {});
  }

  getSparklineChartOptions() {
    return {
      type: 'line',
      options: {
        responsive: false,
        legend: {
          display: false
        },
        elements: {
          line: {
            borderColor: '#28a745',
            borderWidth: 1
          },
          point: {
            radius: 0
          }
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [
            {
              display: false
            }
          ],
          xAxes: [
            {
              display: false
            }
          ]
        }
      }
    };
  }
}
