import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { environment } from 'src/environments/environment';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { HierarchyElementModel } from 'src/app/models';
import { BotsService, CorpsService, HierarchyElementsService } from 'src/app/services/firestore';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { StageModel } from 'src/app/models/client-environment';
import { take } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
// tslint:disable-next-line:max-line-length
import { SingleItemDeleteConfirmationModalComponent } from 'src/app/components/modals/single-item-delete-confirmation-modal/single-item-delete-confirmation-modal.component';

@Component({
  selector: 'app-bot-settings',
  templateUrl: './bot-settings.component.html',
  styleUrls: ['./bot-settings.component.scss'],
})
export class BotSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('scriptCodeModal', { static: true })
  scriptCodeModalRef: TemplateRef<any>;
  scriptCodeBsModal: BsModalRef;
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  hierarchyElement: HierarchyElementModel;
  corp: CorpModel;
  bot: BotModel;
  botExists = false;
  currentSelectedStage: StageModel;
  loading: boolean;
  scriptCode: string;
  canSaveChanges = true;
  botNameList: string[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private router: Router,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private hierarchyElementsService: HierarchyElementsService,
    private authService: AuthService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private clientEnvironmentService: ClientEnvironmentService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([
      this.route.paramMap,
      this.authService.currentUser,
      this.clientEnvironmentService.items$,
    ]).subscribe(([params, user, envs]) => {
      const corpId = params.get('corp');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      const botCode = params.get('bot');
      if (!corpId || !hierarchyElementSystemName || !botCode || !user || !envs) {
        this.loading = false;
        return;
      }
      this.loading = true;
      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
        this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
      ]).subscribe(
        ([corp, hierarchyElement, bot]) => {
          if (!corp || !hierarchyElement || !bot) {
            this.loading = false;
            return;
          }
          this.botExists = true;
          // If stage is changed from previous selected stage, update this.bot
          if (this.currentSelectedStage && this.currentSelectedStage.systemName !== envs?.selectedStage.systemName) {
            this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs).then(b => {
              if (b) {
                this.bot = b;
              }
            });
          }
          this.botsService
            .getBotsByHierarchyElement(`${corpId}-${hierarchyElementSystemName}`)
            .pipe(take(1))
            .toPromise()
            .then(list => {
              this.botNameList = list.map(b => b.code).filter(b => b !== this.bot.code);
            });

          // Only allow saving/updating on development stage
          if (envs) {
            this.currentSelectedStage = envs.selectedStage;
            this.canSaveChanges = envs.selectedStage.systemName === 'development';
          }

          this.loading = false;
          this.corp = corp;
          this.hierarchyElement = hierarchyElement;
          this.bot = bot;
          // set default avatar size to 100% if not set
          if (!this.bot.styles.avatarSize) {
            this.bot.styles.avatarSize = 100; // percent
          }
          if (!this.bot.styles.mobileHeight) {
            this.bot.styles.mobileHeight = 100;
          }
          if (!this.bot.styles.teaserStyle) {
            this.bot.styles.teaserStyle = BotModel.defaultTeaserStyle();
          }
          this.scriptCode = `<script id="carlabs-bot-script" data-bot-id="${this.bot.id}" src="${environment.botWebChatUrl}"></script>`;
          this.refreshUI();
        },
        error => {
          this.loading = false;
          this.toaster.error(error);
        },
      );
    });
  }

  canEditBot(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_EDIT_BOT_SETTINGS);
  }

  canDeleteBot(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_DELETE_BOT);
  }

  canGenerateBotEmbeddableCode(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_GENERATE_BOT_EMBEDDABLE_CODE);
  }

  processSave() {
    this.bot = plainToClass(BotModel, this.bot);
  }

  navigateToParentScreen() {
    this.router.navigate([
      // tslint:disable-next-line:max-line-length
      `portal/corps/${this.corp?.id}/hierarchy-el/${this.hierarchyElement.systemNameForUrl}/bots/${this.bot.code}`,
    ]);
  }

  showScriptCodeModal() {
    this.scriptCodeBsModal = this.modalService.show(this.scriptCodeModalRef, {
      animated: true,
      ignoreBackdropClick: true,
    });
  }

  processCopySuccess() {
    this.toaster.success('Successfully copied to clipboard');
  }

  confirmAndDeleteBot() {
    const modalRef = this.modalService.show(SingleItemDeleteConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.typeOfEntity = 'Bot';
    modalRef.content.entityValue = this.bot.label;
    modalRef.content.onDeleteConfirmed.subscribe(() => {
      this.deleteBot();
    });
  }

  refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
    this.headerService.setPageTitle(`${this.bot.label} Settings & Test`);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Settings & Test', 'settings'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  private async deleteBot() {
    try {
      await this.botsService.removeBot(this.bot.code, this.bot.corpId, this.hierarchyElement.systemNameForUrl);
      this.toaster.success('Assistant deleted');
      this.router.navigate([`/portal/corps/${this.corp.id}/hierarchy-el/${this.hierarchyElement.systemNameForUrl}`]);
    } catch (error) {
      this.toaster.error(error);
    }
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
