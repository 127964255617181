import { SidebarItemVM } from 'src/app/components/layout/sidebar/_types/SidebarItemVM';
import { CorpHierarchyModel, CorpModel, HierarchyElementModel } from 'src/app/models';
import { BreadcrumbNodeVM } from 'src/app/components/breadcrumb/_types/BreadcrumbNodeVM';

export const getSidebarItems = (
  corpId: string,
  corpHierarchies: CorpHierarchyModel[],
  corpHierarchy: CorpHierarchyModel,
  parentSystemName: string
): SidebarItemVM[] => {
  const sidebar = [
    {
      path: `/admin/corps`,
      title: 'Corps',
      type: 'link',
      icontype: 'ni-world-2 text-primary'
    },
    {
      path: `/admin/corps/${corpId}/hierarchies`,
      title: 'Hierarchies',
      type: 'link',
      icontype: 'fa fa-sitemap text-success'
    }
  ];

  if (corpHierarchies[0]) {
    const highestCorphierarchy = corpHierarchies[0];
    sidebar.push({
      path: `/admin/corps/${corpId}/hierarchies/${highestCorphierarchy.systemName}`,
      title: highestCorphierarchy.label,
      type: 'link',
      icontype: 'fas fa-arrow-circle-right text-gray'
    });
  }

  if (corpHierarchy.order > 1) {
    for (let index = 2; index <= corpHierarchy.order; index++) {
      const corphierarchy = corpHierarchies[index - 1];
      const parentSystemName$1 = parentSystemName.replace(`${corpId}-`, '');
      const hierarchyElementSystemName = parentSystemName$1
        .split('-')
        .slice(0, index - 1)
        .join('-');
      sidebar.push({
        path: `/admin/corps/${corpId}/hierarchies/${corphierarchy.systemName}/hierarchy-el/${hierarchyElementSystemName}`,
        title: corphierarchy.label,
        type: 'link',
        icontype: 'fas fa-arrow-circle-right text-gray'
      });
    }
  }

  sidebar.push({
    path: `/admin/corps/${corpId}/settings`,
    title: 'Corp Settings',
    type: 'link',
    icontype: 'ni-settings-gear-65 text-success'
  });

  return sidebar;
};

export const getBreadcrumbItems = (
  corp: CorpModel,
  corpHierarchies: CorpHierarchyModel[],
  hierarchyElements: Array<HierarchyElementModel | null>
): BreadcrumbNodeVM[] => {
  const breadCrumbs = [
    {
      label: 'Corporations',
      route: `admin/corps`
    },
    {
      label: corp.label,
      icon: corp.logo,
      route: `admin/corps/${corp.id}`
    }
  ];

  hierarchyElements.forEach(parentElement => {
    if (!parentElement) {
      return;
    }
    const corpHierarchy = corpHierarchies.find(({ systemName }) => systemName === parentElement.hierarchyName);
    if (!corpHierarchy) {
      return;
    }
    const descendantHierarchy = corpHierarchies.find(({ order }) => order === corpHierarchy.order + 1);
    breadCrumbs.push({
      route: `/admin/corps/${corp.id}/hierarchies/${descendantHierarchy?.systemName}/hierarchy-el/${parentElement.systemNameForUrl}`,
      label: `${parentElement.label}`,
      icon: parentElement.logo
    });
  });
  return breadCrumbs;
};
