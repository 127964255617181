import { environment } from 'src/environments/environment.prod';
import { LocaleEnum } from './countries/LocaleEnum';
import { AwsRegionEnum } from './countries/AwsRegionEnum';
import { BotEnvironment } from './client-environment';

export type BotNlpModel = {
  url: string;
  model: string;
};

export interface BotTeaserModel {
  type: 'button' | 'text';
  title: string;
  value: string;
  nodeId: string;
  data: object;
}

export type BotConfigModel = {
  googleSheetUrl: string;
  triggers: object[];
  locale: LocaleEnum | string;
  awsRegion: AwsRegionEnum;
  nlp: BotNlpModel;
  type: string;
  handoverConfig: HandoverConfig;
};

export type BotTeaserStyleModel = {
  backgroundColor: string;
  backgroundImageUrl: string;
  buttonBorderRadius: number;
  textFontSize: number;
  buttonBackgroundColor: string;
  showBackground: boolean;
};

export type BotStylesModel = {
  headerText: string;
  headerSize: number;
  headerTextColor: string;
  headerTextSize: number;
  headerTextFontFamily: string;
  headerBackgroundColor: string;
  closeButtonSize: number;

  chatContainerWidth: number;
  chatContainerHeight: number;
  chatContainerBorderRadius: number;
  chatContainerBackgroundImageUrl: string;
  chatContainerBackgroundColor: string;

  textBubbleRadius: number;
  distanceBetweenBubbles: number;
  textFontSize: number;
  textFontFamily: string;
  textUserColor: string;
  textBotColor: string;
  textUserBackgroundColor: string;
  textBotBackgroundColor: string;

  suggestedActionsBackgroundColor: string;
  suggestedActionsBorder: string;
  suggestedActionsHeight: number;
  actionButtonsDistance: number;
  actionButtonBorder: string;
  actionButtonBorderRadius: number;
  actionButtonColor: string;
  actionButtonBackgroundColor: string;
  actionButtonFontSize: number;
  actionButtonFontWeight: number;
  actionButtonFontFamily: string;
  suggestedActionsArrowWidth: number;
  suggestedActionsArrowBorder: string;
  suggestedActionsArrowBackgroundColor: string;
  suggestedActionsArrowSize: number;
  suggestedActionsArrowImageUrlLeft: string;
  suggestedActionsArrowImageUrlRight: string;

  inputBoxPlaceholderText: string;
  inputHeight: number;
  inputBorder: string;
  showHamburger: boolean;
  sendImageSize: number;

  mobileHeight: number;

  avatarSize: number;
  showAvatar: boolean;
  avatarImageUrl: string | undefined;
  avatarAnimation: 'bounce' | 'static';

  teaserText: string[];
  nonInteractiveTeaserText: string;
  chatPosition: 'left' | 'right';
  fixedPosition: boolean;
  fullScreenOnMobile: boolean;
  teaserButtons: BotTeaserModel[];
  interactiveTeaser: boolean;
  showTeaser: boolean;
  openBotOnInit: boolean;
  teaserStyle: BotTeaserStyleModel;
};

export type HandoverConfig = {
  name: string;
  authToken: string;
  metaData: string;
  isDefault: boolean;
};

export type BotFacebookPageConfigModel = {
  appId: string;
  appSecret: string;
  pageId: string;
  pageAccessToken: string;
  passThreadControlTargetAppId: string;
};

export type EventTriggerType = 'page-url' | '';

export type EventTriggersModel = {
  eventTriggerId: string;
  eventTriggerType: EventTriggerType;
  eventTriggerInput: string;
  eventTriggerNodeId: string;
  eventTriggerNodeName: string;
};

export enum GlobalVariableType {
  BOT = 'BOT',
  USER_INPUT = 'USER_INPUT',
  API_DATA = 'API_DATA',
}

export const VariableTypeMapping = {
  [GlobalVariableType.API_DATA]: 'api',
  [GlobalVariableType.USER_INPUT]: 'input',
  [GlobalVariableType.BOT]: 'bot',
};

export interface GlobalVariable {
  id: string;
  label: string;
  slug: string;
  defaultValue?: string;
  description: string;
  type: GlobalVariableType;
}

export class BotModel {
  label: string;
  code: string;
  createdBy: string;
  corpId: string;
  hierarchyElementId: number;
  hierarchyElementSystemName: string;
  clientEnvironment: BotEnvironment;
  apiGatewayServiceId: string;
  rootElementId: string;
  errorMessage: string;
  sessionStorageAccess: string;
  initOnLoad: boolean;
  clearPreviousSessionOnInit: boolean;
  debugMode: boolean;
  flowTemplateImportsPrefixes?: Record<string, string[]>;
  facebookPageIds: string[];
  facebookPageConfigs: BotFacebookPageConfigModel[] = [];
  eventTriggers: EventTriggersModel[] = [];
  scriptInjectionSource: string;
  config: BotConfigModel;
  styles: BotStylesModel;
  initNodeId: string;
  vendorId: string;
  introVideo: string;
  globalVariables: GlobalVariable[] = [];
  buttonClickCallback: () => void;

  get id(): string {
    // No need for corp-id since the hierarchyElementsystemName already has the corp-id

    // To handle previous bots with no environments, we check if clientEnvironment is available and change ID based on that.
    // TODO: Create helper cloud functions to do a safe and efficient update to existing bots.
    return this.clientEnvironment
      ? `${this.hierarchyElementSystemName}-${this.code}_${this.clientEnvironment}`
      : `${this.hierarchyElementSystemName}-${this.code}`;
  }

  static generateDefaultBot(): BotModel {
    const bot = new BotModel();
    bot.rootElementId = 'carlabs-root-element';
    bot.initOnLoad = true;
    bot.debugMode = false;
    bot.clearPreviousSessionOnInit = false;
    bot.errorMessage = "I'm sorry, but I didn't quite get that. Can you rephrase your question?";
    bot.config = {
      googleSheetUrl: '',
      triggers: [],
      locale: '',
      awsRegion: AwsRegionEnum.US_EAST_1,
      nlp: {
        url: environment.nlpDojoUrl,
        model: '',
      },
      type: '',
      handoverConfig: {
        name: '',
        authToken: '',
        metaData: '',
        isDefault: true,
      },
    };
    bot.facebookPageIds = [];
    bot.facebookPageConfigs = [];
    bot.eventTriggers = [];
    bot.scriptInjectionSource = '';
    bot.initNodeId = '';
    bot.globalVariables = [];
    bot.styles = {
      headerText: 'Chat with me',
      headerSize: 50,
      headerTextColor: '#ffffff',
      headerTextSize: 16,
      headerTextFontFamily: 'Open Sans, sans-serif',
      headerBackgroundColor: '#cc0000',
      closeButtonSize: 24,

      chatContainerWidth: 500,
      chatContainerHeight: 600,
      chatContainerBorderRadius: 20,
      chatContainerBackgroundImageUrl: '',
      chatContainerBackgroundColor: '#fff',

      textBubbleRadius: 20,
      distanceBetweenBubbles: 20,
      textFontSize: 14,
      textFontFamily: 'Open Sans,sans-serif',
      textUserColor: '#fff',
      textBotColor: '#000',
      textUserBackgroundColor: '#2196F3',
      textBotBackgroundColor: '#eee',

      suggestedActionsBackgroundColor: '#fff',
      suggestedActionsBorder: '1px solid #cccccc',
      suggestedActionsHeight: 46,
      actionButtonsDistance: 10,
      actionButtonBorder: '1px solid #adadad',
      actionButtonBorderRadius: 10,
      actionButtonColor: '#0078d7',
      actionButtonBackgroundColor: '#fff',
      actionButtonFontSize: 14,
      actionButtonFontWeight: 400,
      actionButtonFontFamily: 'Segoe UI, sans-serif',
      suggestedActionsArrowWidth: 25,
      suggestedActionsArrowBorder: 'none',
      suggestedActionsArrowBackgroundColor: '#fff',
      suggestedActionsArrowImageUrlLeft: 'https://s3.amazonaws.com/client-images.webchat/left-arrow.png',
      suggestedActionsArrowImageUrlRight: 'https://s3.amazonaws.com/client-images.webchat/right-arrow.png',
      suggestedActionsArrowSize: 15,

      inputBoxPlaceholderText: 'Type your message here...',
      inputHeight: 50,
      inputBorder: '1px solid #cccccc',
      showHamburger: true,
      sendImageSize: 16,

      mobileHeight: 100, // percentage

      avatarSize: 100, // percentage size
      showAvatar: true,
      avatarImageUrl: 'https://s3.amazonaws.com/client-images.webchat/carlabs_avatar.png',
      avatarAnimation: 'bounce',

      teaserText: ['Hi!', 'Ask me something'],
      nonInteractiveTeaserText: 'Talk to me',
      chatPosition: 'right',
      fixedPosition: true,
      fullScreenOnMobile: true,
      teaserButtons: [],
      openBotOnInit: true,
      interactiveTeaser: false,
      showTeaser: false,
      teaserStyle: {
        backgroundColor: '',
        backgroundImageUrl: '',
        textFontSize: 14,
        buttonBorderRadius: 20,
        buttonBackgroundColor: '',
        showBackground: false,
      },
    };
    return bot;
  }

  static defaultTeaserStyle(): BotTeaserStyleModel {
    const style: BotTeaserStyleModel = {
      backgroundColor: '',
      backgroundImageUrl: '',
      buttonBorderRadius: 20,
      textFontSize: 14,
      buttonBackgroundColor: '#832f2f',
      showBackground: true,
    };
    return style;
  }
}
