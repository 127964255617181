import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-single-item-delete-confirmation-modal',
  templateUrl: './single-item-delete-confirmation-modal.component.html',
  styleUrls: ['./single-item-delete-confirmation-modal.component.scss']
})
export class SingleItemDeleteConfirmationModalComponent implements OnInit {
  entityValue: string;
  typeOfEntity: string;
  entityNameInput = '';
  onDeleteConfirmed: EventEmitter<string>;

  constructor(public modal: BsModalRef) {
    this.onDeleteConfirmed = new EventEmitter<string>();
  }

  ngOnInit() {}

  deleteEntity() {
    this.onDeleteConfirmed.emit();
    this.modal.hide();
  }
}
