import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ConversationLogFilter } from './types/ConversationLogFilter';

@Component({
  selector: 'app-conversations-messages-search-filter',
  templateUrl: './conversations-messages-search-filter.component.html',
  styleUrls: ['./conversations-messages-search-filter.component.scss']
})
export class ConversationsMessagesSearchFilterComponent implements OnInit {
  dateOfToday: Date = new Date();

  @Input()
  filter: ConversationLogFilter;

  @Output()
  filterChange: EventEmitter<void>;

  constructor() {
    this.filterChange = new EventEmitter<void>();
  }

  ngOnInit() {
    if (this.filter.conversationId) {
      this.emitFilter();
    }
  }

  onSortOrderChange() {
    this.emitFilter();
  }

  emitFilter() {
    this.filterChange.emit();
  }
}
