import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CorpsService, FlowTemplatesService, HierarchyElementsService } from 'src/app/services/firestore';
import { FlowTemplateModel, CorpModel, HierarchyElementModel } from 'src/app/models';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { getBreadcrumbItems, getSidebarItems } from 'src/app/pages/portal/hierarchy-element/utils';
import { HeaderService } from 'src/app/services/header.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddBotModalComponent } from 'src/app/components/modals/add-bot-modal/add-bot-modal.component';
import { Subscription, combineLatest } from 'rxjs';
@Component({
  selector: 'app-bot-select-template',
  templateUrl: './bot-select-template.component.html',
  styleUrls: ['./bot-select-template.component.scss'],
})
export class BotSelectTemplateComponent implements OnInit, OnDestroy {
  corpId: string;
  hierarchySystemName: string;
  availableCorpTemplatesGroups: FlowTemplateModel[] = [];
  availableGlobalTemplatesGroups: FlowTemplateModel[] = [];
  corp: CorpModel | null;
  hierarchyElement: HierarchyElementModel | null;
  loading: boolean;
  private dataSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private flowTemplateService: FlowTemplatesService,
    private breadcrumbService: BreadcrumbService,
    private hierarchyElementsService: HierarchyElementsService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(value => {
      this.corpId = value.corp;
      this.corpsService.getCorpById(this.corpId).subscribe(corp => {
        this.corp = corp;
        const hierarchyElementSystemName = value.hierarchyElementSystemName;
        this.hierarchySystemName = hierarchyElementSystemName;
        this.hierarchyElementsService
          .getHierarchyElement(`${this.corp?.id}-${hierarchyElementSystemName}`)
          .subscribe(hierarchyElment => {
            this.hierarchyElement = hierarchyElment;
            this.loadCorpTemplates();
          });
      });
    });
  }

  showAddBotModal() {
    if (!this.corp || !this.hierarchyElement) {
      return;
    }
    this.modalService.show(AddBotModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpId: this.corp.id,
        hierarchyElementId: this.hierarchyElement.id,
        hierarchyElementSystemName: this.hierarchyElement.systemName,
      },
    });
  }

  loadCorpTemplates() {
    this.dataSubscription = combineLatest([
      this.flowTemplateService.getCorpBotFlowBotTemplates(this.corpId),
      this.flowTemplateService.getGlobalBotFlowTemplates(),
    ]).subscribe(([corpTemplates, globalTemplates]) => {
      this.loading = false;
      this.availableGlobalTemplatesGroups = globalTemplates;
      this.availableCorpTemplatesGroups = corpTemplates;
      this.refreshUI();
    });
  }

  refreshUI() {
    if (this.corp && this.hierarchyElement) {
      this.setBreadcrumb(this.corp, this.hierarchyElement);
      this.setSidebarItems(this.corp.id, this.hierarchyElement);
    }
    this.headerService.setPageTitle(`${this.hierarchySystemName} Select Template`);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, 'Dashboard', 'dashboard'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement));
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
