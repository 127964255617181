import { AfterViewInit, Component, ComponentFactoryResolver, Injector } from '@angular/core';
import { VisualTemplateBase } from '../visual-template-base';

@Component({
  selector: 'app-visual-template-editor-button',
  templateUrl: './visual-template-editor-button.component.html',
  styleUrls: ['./visual-template-editor-button.component.scss']
})
export class VisualTemplateEditorButtonComponent extends VisualTemplateBase implements AfterViewInit {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public injector: Injector) {
    super(componentFactoryResolver, injector);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.data.params.type = this.data.params.type ?? 'imBack';
  }
}
