import { Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { NodeModel } from 'src/app/models/node';
import { Flow } from 'src/assets/vendor/flow-js/script';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import { InputValidationModel } from 'src/app/models/input-validation';
import { ApiQueryModel } from 'src/app/models/api-query';
import { BrainService } from 'src/app/services/brain.service';
import { combineLatest, Subscription } from 'rxjs';
import { BotModel, FlowTemplateModel } from 'src/app/models';
import { SpecTemplateModel } from 'src/app/models/spec_template';
import { ApiGatewayService } from 'src/app/services/api-gateway-service.service';
import { GlobalVariableType, GlobalVariable } from 'src/app/models/bot';
// tslint:disable-next-line:max-line-length
import { IGlobalVariablesPropagationPayload } from '../../bot/bot-general-info/_components/render-global-variables/render-global-variables.component';
import { uniqBy } from 'lodash';
import { FlowEditorEvents, FlowEventEditorTypes } from './events/flow-editor.events';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  ApiQueriesService,
  FlowTemplatesService,
  InputValidationsService,
  NlpService,
} from 'src/app/services/firestore';
// tslint:disable-next-line:max-line-length
import { SingleItemDeleteConfirmationModalComponent } from '../../modals/single-item-delete-confirmation-modal/single-item-delete-confirmation-modal.component';
// tslint:disable-next-line:max-line-length
import { MultipleItemDeleteConfirmationModalComponent } from '../../modals/multiple-items-delete-confirmation-modal/multiple-items-delete-confirmation-modal.component';
/*
WARNING
Please do not do any event emission on this file because it will not be propagated due to deep nesting of this component
Pass all of them through a service through RXJS
*/

const FLOW_LINES = {
  CONNECTIONS: 'Connections',
  VALIDATIONS: 'Validations',
  TEMPLATES: 'Templates',
};

@Component({
  selector: 'app-flow-editor',
  templateUrl: './flow-editor.component.html',
  styleUrls: ['./flow-editor.component.scss'],
})
export class FlowEditorComponent implements OnInit, OnDestroy {
  selectedNode?: NodeModel;
  flow: Flow;
  specs: string[];
  multipleSelectedNodes = new Set<string>();
  specsTemplates: { [key: string]: SpecTemplateModel } = {};
  nlpModel: NlpModel | null;
  apiQueries: ApiQueryModel[];
  validations: InputValidationModel[] = [];
  schema: object;
  hasChanges = false;
  loading: boolean;
  showVariables: boolean;
  private _nodes: NodeModel[];
  private _bot: BotModel;

  botVariableItems: GlobalVariable[] = [];
  apiVariableItems: GlobalVariable[] = [];
  userVariableItems: GlobalVariable[] = [];

  private _flowNodes: object[] = [];
  private crtDataSubscription: Subscription;
  private editorChangesSubscription: Subscription;

  get nodes(): NodeModel[] {
    return this._nodes;
  }

  @Input()
  set nodes(nodes: NodeModel[]) {
    // This method is called only when the list is initialised
    this._nodes = nodes;
    // Meta data for nodes
    setTimeout(() => {
      this.updateFlow();
    });
  }

  get bot() {
    return this._bot;
  }

  @Input()
  set bot(bot: BotModel) {
    this._bot = bot;
    this.renderGlobalVariablesSimpleListItem();
  }

  @Input()
  corpId: string;

  @Input()
  flowTemplate: FlowTemplateModel;

  @Input()
  source: 'bot-flow' | 'template-flow';

  @Input()
  canUserManage: boolean;

  /**
   * Allow to save changes only if are on the development env
   */
  @Input()
  allowSaveChanges: boolean;

  flowLinesSettings = [
    { label: FLOW_LINES.CONNECTIONS, selected: true },
    { label: FLOW_LINES.VALIDATIONS, selected: true },
    { label: FLOW_LINES.TEMPLATES, selected: true },
  ];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.code === 'KeyA') {
      this.selectAllNodes();
    }
    if (event.key === 'Escape') {
      this.multipleSelectedNodes.clear();
    }
  }

  constructor(
    private brainService: BrainService,
    private apiGatewayService: ApiGatewayService,
    private flowTemplatesService: FlowTemplatesService,
    private apiQueriesService: ApiQueriesService,
    private modalService: BsModalService,
    private nlpService: NlpService,
    private flowEditorEvents: FlowEditorEvents,
    private inputValidationsService: InputValidationsService,
  ) {}

  async ngOnInit() {
    this.editorChangesSubscription = this.flowEditorEvents.editorEvents$.subscribe(newEvent => {
      if (newEvent && newEvent.type === FlowEventEditorTypes.EDITOR_HAS_CHANGES) {
        this.hasChanges = newEvent.payload;
      }
    });

    this.initFlow();
    this.loading = true;
    this.schema = await this.apiGatewayService.schema(this.bot.apiGatewayServiceId);
    this.crtDataSubscription = combineLatest([
      this.flowTemplate
        ? this.flowTemplatesService.getFlowTemplatesApiQueries(this.flowTemplate.systemName)
        : this.apiQueriesService.getApiQueriesByBotId(this.bot.id),
      this.inputValidationsService.getInputValidationsByCorpId(this.corpId), // This needs to be moved to global for global templates
      this.nlpService.getNlpModel(this.bot.config.nlp.model),
      this.bot.config.googleSheetUrl
        ? this.brainService
            .exportTopicsNlg({
              config: {
                google_sheet_url: this.bot.config.googleSheetUrl,
                config_tab_name: 'Config',
              },
            })
            .toPromise()
            .catch(_e => Promise.reject())
            .then(r => {
              return Promise.resolve(r);
            })
        : Promise.resolve(),
    ]).subscribe(async ([apiQueries, validations, nlpModel, nlgTopics]) => {
      this.apiQueries = apiQueries;
      this.validations = validations;
      this.nlpModel = nlpModel;
      if (this.nlpModel?.intents && !this.nlpModel.intents.includes('input_unknown')) {
        this.nlpModel.intents.push('input_unknown');
      }
      if (
        this.nlpModel?.intents &&
        !this.nlpModel.intents.includes('start_over') &&
        !this.nlpModel.intents.includes('disable.handoff') &&
        !this.nlpModel.intents.includes('enable.handoff') &&
        !this.nlpModel.intents.includes('unreachable_email_address')
      ) {
        this.nlpModel?.intents.push('start_over');
        this.nlpModel?.intents.push('disable.handoff');
        this.nlpModel?.intents.push('enable.handoff');
        this.nlpModel?.intents.push('unreachable_email_address');
        this.nlpModel.intents = this.nlpModel.intents.sort();
      }
      const topicsData = {};
      if (nlgTopics) {
        nlgTopics?.data?.forEach(item => {
          const data = {
            template_id: item.template_id,
            templates: item.templates,
          };
          topicsData[data.template_id] = data;
        });
      }
      this.specs = Object.keys(topicsData).sort();
      this.specsTemplates = this.buildSpecTemplates(topicsData);
      this.loading = false;
    });
  }

  duplicateNode(node: NodeModel) {
    const copy = NodeModel.duplicate(node);
    this.flowEditorEvents.emitCreateNode(copy);
    this.updateFlow();
  }

  initFlow() {
    if (!this._flowNodes?.length) {
      return;
    }
    setTimeout(() => {
      this.flow = new Flow({
        containerId: 'flow-container',
        data: this._flowNodes,
        startX: 0,
        startY: 0,
        startScale: 0.5,
        onNodeReleaseUpdate: this.nodeReleaseUpdate,
      });
    }, 0);
  }

  nodeReleaseUpdate = elem => {
    if (!this.canUserManage || !elem || !elem.style) {
      return;
    }
    // translate3d(x, y, z): 3d, x, y, z
    const m = elem.style.transform.match(/(-?)\d+/g);
    const newPos = {
      x: parseInt(m[1], 10),
      y: parseInt(m[2], 10),
    };
    const nodeIndex = this._nodes.findIndex(x => x.id === elem.id);
    const node = this._nodes[nodeIndex];
    if (node.x === newPos.x && node.y === newPos.y) {
      return;
    }
    node.x = newPos.x;
    node.y = newPos.y;
    if (this.allowSaveChanges) {
      this.saveNode(node, false);
    }
  };

  updateFlow() {
    this._flowNodes = this.generateFlowNodes(this._nodes);

    // Set timeout to ensure dom is fully updated and we update on next event loop.
    setTimeout(() => {
      if (!this.flow) {
        this.initFlow();
      } else {
        this.flow.update({ data: this._flowNodes });
      }
    }, 0);
  }

  removeNode(node: NodeModel) {
    const modalRef = this.modalService.show(SingleItemDeleteConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.typeOfEntity = 'Node';
    modalRef.content.entityValue = node.name;
    modalRef.content.onDeleteConfirmed.subscribe(() => {
      this.selectedNode = undefined;
      this.deleteNode(node);
      this.updateFlow();
    });
  }

  private deleteNode(node: NodeModel) {
    /*
        Important - when removing one element from the list we should
        not modify the array reference
      */
    const nodeIndex = this._nodes.findIndex(n => n.id === node.id);
    if (nodeIndex > -1) {
      this._nodes.splice(nodeIndex, 1);
    }

    /*
       Removing the connections, children or parents of the deleted node, if necessary
     */
    this._nodes.forEach(n => {
      if (n.connections?.length) {
        n.connections = n.connections.filter(c => c.id !== node.id);
      }

      if (n.childNodes?.length) {
        n.childNodes = n.childNodes.filter(c => c.id !== node.id);
      }

      if (n.parentNodes?.length) {
        n.parentNodes = n.parentNodes.filter(p => p.id !== node.id);
      }
    });
    this.flowEditorEvents.emitDeleteNode(node);
  }

  toggleShowVariables() {
    this.selectedNode = undefined;
    this.renderGlobalVariablesSimpleListItem();
    this.showVariables = !this.showVariables;
  }

  addNode(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const node = NodeModel.generateDefault();
    node.name = `Node ${node.id.slice(-4)}`;
    node.botId = this.bot.id;
    NodeModel.generateSystemName(node);

    this.flowEditorEvents.emitCreateNode(node);
    this.updateFlow();
  }

  clickContainer(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!this.selectedNode) {
      return;
    }
    this.flowEditorEvents.emitUpdateNode(this.selectedNode);
    this.selectedNode = undefined;
  }

  setNode(event, node: NodeModel) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    NodeModel.generateMeta(node, this._nodes);
    NodeModel.generateAvailableData(node, this.schema, this.apiQueries);
    this.showVariables = false;

    const isAnyActionKeyPressed = event.shiftKey || event.ctrlKey || event.metaKey;
    if (!isAnyActionKeyPressed) {
      this.selectedNode = node;
      this.multipleSelectedNodes.clear();
      return;
    }

    this.selectedNode = undefined;
    if (this.multipleSelectedNodes.has(node.id)) {
      this.multipleSelectedNodes.delete(node.id);
      return;
    }

    this.multipleSelectedNodes.add(node.id);
  }

  selectAllNodes() {
    this.nodes.forEach(({ id }) => {
      this.multipleSelectedNodes.add(id);
    });
  }

  bulkDeleteMultipleSelectedNodes() {
    const nodesToDelete: NodeModel[] = [];
    this.multipleSelectedNodes.forEach(nodeId => {
      const node = this._nodes.find(({ id }) => id === nodeId);
      if (node) {
        nodesToDelete.push(node);
      }
    });
    const modalRef = this.modalService.show(MultipleItemDeleteConfirmationModalComponent, {
      ignoreBackdropClick: true,
    });
    modalRef.content.typeOfEntity = 'Nodes';
    modalRef.content.entityValues = nodesToDelete.map(({ name }) => name);
    modalRef.content.onDeleteConfirmed.subscribe(() => {
      nodesToDelete.forEach(node => {
        this.deleteNode(node);
      });
      this.multipleSelectedNodes.clear();
      this.updateFlow();
    });
  }

  center(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.flow.setPosition({ x: 0, y: 0 });
  }

  mouseEnter() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
  }

  mouseLeave() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'auto';
  }

  private renderGlobalVariablesSimpleListItem() {
    if (this.bot) {
      this.botVariableItems = this.filterVariableItems(GlobalVariableType.BOT);
      this.apiVariableItems = this.filterVariableItems(GlobalVariableType.API_DATA);
      this.userVariableItems = this.filterVariableItems(GlobalVariableType.USER_INPUT);
    }
  }

  handleGlobalVariableChanges({ variableType, globalVariables }: IGlobalVariablesPropagationPayload) {
    this.bot.globalVariables = uniqBy(
      [...this.bot.globalVariables.filter(({ type }) => type !== variableType), ...globalVariables],
      'slug',
    );
    this.flowEditorEvents.emitSaveGlobalVariables(this.bot.globalVariables);
    this.renderGlobalVariablesSimpleListItem();
  }

  private filterVariableItems(variableType: GlobalVariableType) {
    return this.bot.globalVariables.filter(({ type }) => type === (variableType as GlobalVariableType));
  }

  private isConnectionTypeTurnedOn(flowLinesType: string): boolean {
    const flowSetting = this.flowLinesSettings.find(({ label }) => label === flowLinesType);
    return !!flowSetting?.selected;
  }

  generateFlowNodes(nodes: NodeModel[]): object[] {
    const nodesCopy: NodeModel[] = JSON.parse(JSON.stringify(nodes));
    const isConnectionsFlowLinesTurnedOn = this.isConnectionTypeTurnedOn(FLOW_LINES.CONNECTIONS);
    const isConnectionsValidationsTurnedOn = this.isConnectionTypeTurnedOn(FLOW_LINES.VALIDATIONS);
    const isConnectionsTemplatesTurnedOn = this.isConnectionTypeTurnedOn(FLOW_LINES.TEMPLATES);
    return nodesCopy.map(n => {
      const node: NodeModel = Object.assign(Object.create(n), n);
      if (!isConnectionsFlowLinesTurnedOn) {
        node.connections = [];
      }
      if (node?.validations?.length && isConnectionsValidationsTurnedOn) {
        node.validations.forEach(validation => {
          if (validation.fallbackRetryNodeId) {
            node.connections?.push({
              id: validation.fallbackRetryNodeId,
              strokeColor: 'red',
              strokeWidth: '2',
              strokeDashArray: '8 15',
            });
          }
          if (validation.fallbackRedirectNodeId) {
            node.connections?.push({
              id: validation.fallbackRedirectNodeId,
              strokeColor: 'red',
              strokeWidth: '2',
              strokeDashArray: '15 15',
            });
          }
        });
      }
      if (isConnectionsTemplatesTurnedOn) {
        node?.templateNodeConnections?.forEach(templateConnection => {
          node.connections?.push({
            id: templateConnection.toLowerCase(),
            strokeColor: 'yellow',
            strokeWidth: '2',
            strokeDashArray: '30 15',
          });
        });
      }
      return node;
    });
  }

  toggleFlowLineSetting(connectionType: string) {
    const connectionIndex = this.flowLinesSettings.findIndex(({ label }) => label === connectionType);
    if (connectionIndex > -1) {
      this.flowLinesSettings[connectionIndex].selected = !this.flowLinesSettings[connectionIndex].selected;
    }
    this.updateFlow();
  }

  saveChanges$1() {
    if (this.selectedNode) {
      this.saveNode(this.selectedNode);
    }
    setTimeout(() => {
      this.flowEditorEvents.emitSaveChanges();
    }, 100);
  }

  saveNode(node: NodeModel, updateFlow = true) {
    NodeModel.generateSystemName(node);
    this.flowEditorEvents.emitUpdateNode(node);
    if (updateFlow) {
      this.updateFlow();
    }
  }

  buildSpecTemplates(topicsData: object) {
    const result = {};
    this.specs.forEach(template_id => {
      const data = topicsData[template_id];
      const templates = data.templates;

      result[template_id] = Object.keys(templates)
        .filter(k => k !== 'ref')
        .map(k => {
          return SpecTemplateModel.fromJSON(Object.assign(templates[k], { key: k }));
        });
    });
    return result;
  }

  hasTemplateChannel(node: NodeModel, channel: string): boolean {
    if (!node.templates?.length) {
      return false;
    }
    return node.templates.filter(t => t.channel === channel).length > 0;
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.editorChangesSubscription) {
      this.editorChangesSubscription.unsubscribe();
    }
  }
}
