import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { StateVariable } from '../../models/state_variables';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class StateVariablesService {
  private stateVariablesCollection: AngularFirestoreCollection<StateVariable>;

  constructor(private afs: AngularFirestore) {
    this.stateVariablesCollection = afs.collection<StateVariable>(COLLECTION_NAMES.STATE_VARIABLES);
  }

  getStateVariables(): Observable<StateVariable[]> {
    return this.stateVariablesCollection.valueChanges().pipe(
      map(stateVariables => {
        return stateVariables.map(sv => plainToClass(StateVariable, sv));
      }),
    );
  }
}
