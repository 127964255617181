import {
  Component,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { VisualTemplateEditorSuggestedActionsComponent } from '../suggestedActions/visual-template-editor-suggested-actions.component';
import { VisualTemplateEditorCardComponent } from '../card/visual-template-editor-card.component';
import { VisualTemplateBase } from '../visual-template-base';

@Component({
  selector: 'app-visual-template-editor-group',
  templateUrl: './visual-template-editor-group.component.html',
  styleUrls: ['./visual-template-editor-group.component.scss']
})
export class VisualTemplateEditorGroupComponent extends VisualTemplateBase implements OnInit {
  removeQuotesOf = ['type', 'disable_input'];
  public onClose: Subject<boolean>;

  @Input()
  public type = 'list';

  @Input()
  public disableInput = false;

  protected availableElements = [
    {
      name: 'suggested-actions',
      component: VisualTemplateEditorSuggestedActionsComponent
    },
    {
      name: 'card',
      component: VisualTemplateEditorCardComponent
    }
  ];

  @ViewChild('innerElements', { read: ViewContainerRef }) viewComponent;

  constructor(public componentFactoryResolver: ComponentFactoryResolver, public injector: Injector) {
    super(componentFactoryResolver, injector);
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }
}
