import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { ToastrService } from 'ngx-toastr';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { HierarchyElementModel, CorpHierarchyModel } from 'src/app/models';
import { HierarchyElementsService, CorpHierarchiesService, CorpsService } from 'src/app/services/firestore';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';

@Component({
  selector: 'app-hierarchy-element-settings',
  templateUrl: './hierarchy-element-settings.component.html',
  styleUrls: ['./hierarchy-element-settings.component.scss'],
})
export class HeirarchyElementSettingsComponent implements OnInit, OnDestroy {
  private crtDataSubscription: Subscription;
  private paramMapSubscription: Subscription;
  loading: boolean;
  corp: CorpModel;
  corpHierarchy: CorpHierarchyModel;
  hierarchyElement: HierarchyElementModel;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private hierarchyElementsService: HierarchyElementsService,
    private corpHierarchiesService: CorpHierarchiesService,
    private authService: AuthService,
    private corpsService: CorpsService,
    private toaster: ToastrService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      results1 => {
        const params = results1[0];
        const user = results1[1];
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        if (!hierarchyElementSystemName || !corpId || !user) {
          return;
        }
        const fullHierarchyElementSystemName = `${corpId}-${hierarchyElementSystemName}`;

        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.hierarchyElementsService.getHierarchyElement(fullHierarchyElementSystemName),
          this.corpHierarchiesService.getCorpHierarchies(corpId),
        ]).subscribe(([corp, hierarchyElement, corpHierarchies]) => {
          if (!corp || !hierarchyElement || !corpHierarchies) {
            return;
          }

          const corpHierarchy = corpHierarchies.find(({ systemName }) => systemName === hierarchyElement.hierarchyName);
          if (!corpHierarchy) {
            this.toaster.warning(`Hierarchy Doesn't Exist`);
            return;
          }

          this.loading = true;
          this.corp = corp;
          this.corpHierarchy = corpHierarchy;
          this.hierarchyElement = hierarchyElement;

          this.refreshUI();
        });
      },
    );
  }

  refreshUI() {
    this.headerService.setPageTitle(`${this.hierarchyElement.label} Settings`);
    this.setBreadcrumb(this.corp, this.hierarchyElement);
    this.setSidebarItems(this.corp.id, this.hierarchyElement);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, 'Settings', 'settings'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement));
  }

  canDeleteHierarchy(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_DELETE_HIERARCHY);
  }

  canEditHierarchySettings(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_EDIT_HIERACHY_SETTINGS);
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
