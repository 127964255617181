import { Component, Input, OnInit } from '@angular/core';
import { MessageSentEvent } from '../conversation-message/_types/MessageSentEvent';
import { AttachmentLayout } from '../conversation-message/_types/AttachmentLayout';

@Component({
  selector: 'app-conversation-message-attachments',
  templateUrl: './conversation-message-attachments.component.html',
  styleUrls: ['./conversation-message-attachments.component.scss']
})
export class ConversationMessageAttachmentsComponent implements OnInit {
  attachmentLayoutList = AttachmentLayout.LIST;
  attachmentLayoutCompactList = AttachmentLayout.COMPACT_LIST;

  @Input()
  messageSent: MessageSentEvent;

  constructor() {}

  ngOnInit() {}
}
