import { Injectable } from '@angular/core';
import { ApiQueryModel } from 'src/app/models/api-query';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class ApiQueriesService {
  private apiQueriesCollection: AngularFirestoreCollection<ApiQueryModel>;
  private apiQueriesCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<ApiQueryModel>;

  constructor(private afs: AngularFirestore) {
    this.apiQueriesCollection = afs.collection<ApiQueryModel>(COLLECTION_NAMES.API_QUERIES);
    this.apiQueriesCollectionQuery = (ref: QueryFn) => afs.collection<ApiQueryModel>(COLLECTION_NAMES.API_QUERIES, ref);
  }

  private removeVersionFromBotId(botId: string | undefined): string {
    if (!botId) {
      return '';
    }
    const botIdSplit = botId.split('_');
    botIdSplit.pop();
    return botIdSplit.join('_');
  }

  async addApiQuery(apiQueryModel: ApiQueryModel, throwErrorOnDuplicate = true): Promise<void> {
    const { systemName, id, botId } = apiQueryModel;
    const data = await this.getApiQueryBySystemName(systemName);

    if (data && throwErrorOnDuplicate) {
      throw new Error(`ApiQuery already exists: ${systemName}`);
    }
    return this.apiQueriesCollection
      .doc(id)
      .set(Object.assign({}, { ...apiQueryModel, botId: this.removeVersionFromBotId(botId) }));
  }

  getApiQueriesByBotId(botId: string): Observable<ApiQueryModel[]> {
    return this.apiQueriesCollectionQuery(ref => ref.where('botId', '==', this.removeVersionFromBotId(botId)))
      .valueChanges({ idField: 'id' })
      .pipe(map(results => results.sort((a, b) => (a.name > b.name ? 1 : -1))));
  }

  async getApiQueryBySystemName(systemName: string): Promise<ApiQueryModel | null> {
    const apiQuery = await this.apiQueriesCollectionQuery(ref => ref.where('systemName', '==', systemName))
      .valueChanges({ idField: 'id' })
      .pipe(take(1))
      .toPromise();
    return apiQuery[0];
  }

  updateApiQuery(apiQuery: ApiQueryModel): Promise<void> {
    ApiQueryModel.generateUpdatedAt(apiQuery);
    return this.apiQueriesCollection.doc(apiQuery.id).update(apiQuery);
  }

  getApiQueryById(apiQueryId: string): Promise<ApiQueryModel | null> {
    return this.apiQueriesCollection
      .doc(apiQueryId)
      .valueChanges()
      .pipe(
        map(bot => {
          return plainToClass(ApiQueryModel, bot);
        }),
      )
      .pipe(take(1))
      .toPromise();
  }

  removeApiQuery(apiQueryId: string) {
    return this.apiQueriesCollection.doc(apiQueryId).delete();
  }
}
