import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems } from '../utils';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { CorpsService } from 'src/app/services/firestore';
import { AddNodeKpiModalComponent } from 'src/app/components/modals/add-node-kpi-modal/add-node-kpi-modal.component';
import { HLCorp } from 'src/app/models/conversations';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';

@Component({
  selector: 'app-corp-settings',
  templateUrl: './corp-settings.component.html',
  styleUrls: ['./corp-settings.component.scss'],
})
export class CorpSettingsComponent implements OnInit, OnDestroy {
  loading = false;

  private crtCorpSubscription: Subscription;
  private paramMapSubscription: Subscription;
  private tagsSubscription: Subscription;

  corp: CorpModel;

  hlCorp: HLCorp;
  corpTags: string[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private authService: AuthService,
    private modalService: BsModalService,
    private humanInLoopService: HumanInLoopService,
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      results1 => {
        const params = results1[0];
        const user = results1[1];
        const corpId = params.get('corp');
        if (!corpId || !user) {
          return;
        }
        this.crtCorpSubscription = combineLatest([this.corpsService.getCorpById(corpId)]).subscribe(([corp]) => {
          if (!corp) {
            return;
          }
          this.corp = corp;
          this.refreshUI();
        });

        this.tagsSubscription = this.humanInLoopService.getCorpTags(corpId).subscribe(doc => {
          if (doc) {
            this.hlCorp = doc;
            if (this.hlCorp) {
              this.corpTags = this.hlCorp.conversation_tags;
            }
          }
        });
      },
    );
  }

  refreshUI() {
    this.headerService.setPageTitle(`${this.corp.label} Settings`);
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
  }

  updateTags(tags) {
    if (this.corp) {
      this.hlCorp.conversation_tags = tags;
      this.humanInLoopService.saveCorpTags(this.corp.id, this.hlCorp);
    }
  }

  private setBreadcrumb(corp: CorpModel) {
    this.breadcrumbService.set([
      {
        label: corp.label,
        icon: corp.logo,
        route: `portal/corps/${corp.id}`,
        testId: 'bread-crumb-corp',
      },
      {
        label: 'Settings',
        route: `portal/corps/${corp.id}/settings`,
      },
    ]);
  }

  private setSidebarItems(corpId: string) {
    this.sidebarService.set(getSidebarItems(corpId));
  }

  canEditCorp(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_EDIT_CORP_SETTINGS);
  }

  showAddKpiModal() {
    this.modalService.show(AddNodeKpiModalComponent, {
      initialState: {
        corpId: this.corp.id,
      },
    });
  }

  ngOnDestroy() {
    if (this.crtCorpSubscription) {
      this.crtCorpSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.tagsSubscription) {
      this.tagsSubscription.unsubscribe();
    }
  }
}
