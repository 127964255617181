import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-graphql-docs',
  templateUrl: './graphql-docs.component.html',
  styleUrls: ['./graphql-docs.component.scss']
})
export class GraphQLDocsComponent implements OnInit, OnChanges {
  @Input()
  schema: object;
  @Input()
  hideTypes: boolean;
  selected: object;
  selectedHistory: object[];

  ngOnInit() {
    this.selectedHistory = [];
    this.setType(this.schema);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedHistory = [];
    this.setType(this.schema);
  }

  selectType(type) {
    this.hideTypes = type.name === "Types";
    if (type['type'] == null) {
      this.setType(type);
      return;
    }
    if (this.schema['children']['types']) {
      this.selected = this.schema['children']['types']['children'][type['type']];
      this.selectedHistory.push(this.selected);
    }
  }

  setType(type) {
    this.selected = type;
    this.selectedHistory.push(this.selected);
  }

  back() {
    if (this.selectedHistory.length < 2) {
      return;
    }
    this.selectedHistory.pop()
    this.selectType(this.selectedHistory.pop());
  }
}
