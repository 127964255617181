import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ClientEnvironmentModel, StageModel } from 'src/app/models/client-environment';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { ToastrService } from 'ngx-toastr';
import Sortable from 'sortablejs';

@Component({
  selector: 'app-environment-management-modal',
  templateUrl: './environment-management-modal.component.html',
  styleUrls: ['./environment-management-modal.component.scss']
})
export class EnvironmentManagementModalComponent implements OnInit {
  clientEnvironment: ClientEnvironmentModel;
  newEnvironmentName: string;
  loading: boolean;

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private toaster: ToastrService,
    private clientEnvironmentService: ClientEnvironmentService
  ) {
    const { clientEnvironment } = options.initialState as any;
    this.clientEnvironment = clientEnvironment;
    this.newEnvironmentName = "";
    this.loading = false;
  }

  ngOnInit(): void {
    Sortable.create(document.getElementById("draggable-items"), {
      draggable: ".is-draggable",
      onEnd: e => this.handleDragEnd(e)
    });
  }

  addNewEnvironment(): void {
    if (!this.isValidName(this.newEnvironmentName)) {
      this.toaster.error("An environment with this name already exists.");
      return;
    }

    const currentEnv = JSON.parse(JSON.stringify(this.clientEnvironment)); // Break reference to avoid any side effects
    const updatedEnv = this.clientEnvironmentService.appendNewStage(this.newEnvironmentName, currentEnv);
    this.clientEnvironment = updatedEnv;
    this.newEnvironmentName = "";
  }

  async saveClientEnvironment(): Promise<void> {
    try {
      await this.clientEnvironmentService.updateClientEnvironment(this.clientEnvironment);
      this.toaster.success("Environments Updated.");
      this.modal.hide();
    } catch (error) {
      this.toaster.error("Error, please try again.");
    }
  }

  deleteEnvironment(stage: StageModel): void {
    const tempEnv = JSON.parse(JSON.stringify(this.clientEnvironment));
    const filteredStages = this.clientEnvironment.stages.filter(x => x.systemName !== stage.systemName);
    tempEnv.stages = filteredStages;
    this.clientEnvironment = tempEnv;
  }

  private isValidName(name: string): boolean {
    const result = this.clientEnvironment.stages.filter(env => env.name.toLowerCase() === name.toLowerCase());
    return !result.length;
  }

  private handleDragEnd(e): void {
    // Brute force method to get new order of stages
    // based on user selection. Optimize later.
    const draggableList = e.target;
    const children: HTMLCollection = draggableList.children;

    // First we get the new order of envs by system name
    // Then we map the new stages order based on that.
    let newStagesOrder: string[] = [];
    Array.from(children).map(childElement => {
      const stageSystemName = childElement.getAttribute("data-stage-system-name");
      if (stageSystemName) newStagesOrder.push(stageSystemName);
    });

    let stages: StageModel[] = [];
    newStagesOrder.map(stageSystemName => {
      const stage: StageModel = this.clientEnvironment.stages.filter(stage => stage.systemName === stageSystemName)[0];
      stages.push(stage);
    });

    const tempEnv = JSON.parse(JSON.stringify(this.clientEnvironment));
    tempEnv.stages = stages;

    this.clientEnvironment = tempEnv;
  }

}
