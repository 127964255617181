import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { HeaderService } from 'src/app/services/header.service';
import { PermissionModel } from '../../../models/permission';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { getSidebarItems } from '../utils';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit, OnDestroy {
  loading: boolean;
  corpsId: string[];

  private permissionsSubscription: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private toaster: ToastrService,
  ) {}

  async ngOnInit() {
    this.headerService.setPageTitle(`Analytics`);
    this.setBreadcrumb();
    this.setSidebarItems();

    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }

    this.loading = true;
    this.permissionsSubscription = this.permissionsService.getPermissionsBy(user.uid).subscribe(
      (permissions: PermissionModel[]) => {
        const corpsId = permissions
          .map(permission => permission.corpId)
          .filter((value, index, self) => {
            return value && self.indexOf(value) === index;
          });
        this.corpsId = corpsId;
        this.loading = false;
      },
      error => {
        this.toaster.error(error);
        this.loading = false;
      },
    );
  }

  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Analytics',
        route: '/portal/analytics',
      },
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }

  ngOnDestroy(): void {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }
}
