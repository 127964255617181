import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FlowTemplateModel, InputValidationModel } from 'src/app/models';
import { classToPlain, plainToClass } from 'class-transformer';
import { NodeModel, NodeConnectionModel, NodeValidationModel, NodeApiQueryModel } from 'src/app/models/node';
import { TemplateModel } from 'src/app/models/template';
import { EventTriggersModel, BotModel } from 'src/app/models/bot';
import { ApiQueriesService } from './api-queries.service';
import { ApiQueryModel } from 'src/app/models/api-query';
import { v4 as uuidv4 } from 'uuid';
import { FlowTemplateType } from 'src/app/models/flow-template';
// TODO Source me
const FLOW_TEMPLATES = 'flow_templates';
const NODES_COLLECTION_NAME = 'nodes';
const INPUT_VALIDATIONS_COLLECTION_NAME = 'input_validations';
const API_QUERIES_COLLECTION_NAME = 'api_queries';
const uuidV4Regex = new RegExp(/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi);

type NodeInfo = {
  oldNode: NodeModel;
  newNode: NodeModel | undefined;
  deps: string[];
};

// basically apiQueries and inputValidations
interface INodeRelatedIdsMapping {
  oldId: string;
  newId: string;
}

@Injectable({
  providedIn: 'root',
})
export class FlowTemplatesService {
  private flowTemplatesCollection: AngularFirestoreCollection<FlowTemplateModel>;
  private flowTemplatesCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<FlowTemplateModel>;

  constructor(private afs: AngularFirestore, private apiQueriesService: ApiQueriesService) {
    this.flowTemplatesCollection = afs.collection<FlowTemplateModel>(FLOW_TEMPLATES);
    this.flowTemplatesCollectionQuery = (ref: QueryFn) => afs.collection<FlowTemplateModel>(FLOW_TEMPLATES, ref);
  }

  async addFlowTemplate(
    flowTemplate: FlowTemplateModel,
    nodes: NodeModel[],
    validations: InputValidationModel[],
  ): Promise<void> {
    const { label, systemName } = flowTemplate;
    const result = await this.flowTemplatesCollection.doc(systemName).ref.get();
    if (result.exists) {
      throw new Error(`"${label}" already exists`);
    } else {
      await this.afs
        .collection(FLOW_TEMPLATES)
        .doc(systemName)
        .set({ ...flowTemplate });
      await this.saveFlowTemplateNodes(systemName, nodes);
      await this.saveFlowTemplatesApiQueries(systemName, nodes);
      await this.saveFlowTemplatesInputValidations(systemName, validations);
    }
  }

  async saveFlowTemplatesApiQueries(flowTemplateId: string, nodes: NodeModel[]): Promise<void> {
    await Promise.all(
      nodes
        .filter(({ apiQueries }) => apiQueries && apiQueries.length > 0)
        .flatMap(({ apiQueries = [] }) => apiQueries)
        .map(async ({ apiQueryId }) => {
          const apiQuery = await this.apiQueriesService.getApiQueryById(apiQueryId);
          if (!apiQuery) {
            return;
          }
          const { botId, corpId, ...apiQueryDataToSave } = apiQuery;
          return this.flowTemplatesCollection
            .doc(flowTemplateId)
            .collection(API_QUERIES_COLLECTION_NAME)
            .doc(apiQueryId)
            .set({ ...apiQueryDataToSave, flowTemplateId });
        }),
    );
  }

  getFlowTemplatesApiQueries(flowSystemName: string): Observable<ApiQueryModel[]> {
    return this.flowTemplatesCollection
      .doc(flowSystemName)
      .collection(API_QUERIES_COLLECTION_NAME)
      .valueChanges()
      .pipe(
        map(apiQueryModel => {
          return plainToClass(ApiQueryModel, apiQueryModel);
        }),
      );
  }

  async addFlowTemplatesApiQuery(apiQueryModel: ApiQueryModel): Promise<void> {
    const data = await this.getApiQueryBySystemName(`${apiQueryModel.flowTemplateId}`, apiQueryModel.systemName);
    if (data) {
      throw new Error(`ApiQuery already exists: ${apiQueryModel.systemName}`);
    }

    return this.flowTemplatesCollection
      .doc(apiQueryModel.flowTemplateId)
      .collection(API_QUERIES_COLLECTION_NAME)
      .doc(apiQueryModel.id)
      .set(Object.assign({}, apiQueryModel));
  }

  async updateFlowTemplatesApiQuery(apiQueryModel: ApiQueryModel): Promise<void> {
    ApiQueryModel.generateUpdatedAt(apiQueryModel);
    return this.flowTemplatesCollection
      .doc(apiQueryModel.flowTemplateId)
      .collection(API_QUERIES_COLLECTION_NAME)
      .doc(apiQueryModel.id)
      .update(apiQueryModel);
  }

  async getApiQueryBySystemName(flowSystemName: string, apiQuerySystemName: string): Promise<ApiQueryModel | null> {
    const apiQuery = await this.flowTemplatesCollection
      .doc(flowSystemName)
      .collection<ApiQueryModel>(API_QUERIES_COLLECTION_NAME, ref => ref.where('systemName', '==', apiQuerySystemName))
      .valueChanges({ idField: 'id' })
      .pipe(take(1))
      .toPromise();
    return apiQuery[0];
  }

  removeApiQuery(flowSystemName: string, apiQueryId: string): Promise<void> {
    return this.flowTemplatesCollection
      .doc(flowSystemName)
      .collection(API_QUERIES_COLLECTION_NAME)
      .doc(apiQueryId)
      .delete();
  }

  // TODO this can be greatly improved based on the diff - changes
  async saveFlowTemplateNodes(flowSystemName: string, nodes: NodeModel[]): Promise<void> {
    await this.removeFlowTemplateNodes(flowSystemName);
    await Promise.all(
      nodes.map(async node => {
        const flowNodesCollection = this.flowTemplatesCollection
          .doc(flowSystemName)
          .collection(NODES_COLLECTION_NAME)
          .doc(node.id);
        const clonedNode: NodeModel = Object.assign({}, node) as NodeModel;
        NodeModel.removeExcluded(clonedNode);
        return flowNodesCollection.set(NodeModel.toPlain({ ...clonedNode }));
      }),
    );
  }

  async saveFlowTemplatesInputValidations(
    flowSystemName: string,
    inputValidations: InputValidationModel[],
  ): Promise<void> {
    await this.removeFlowTemplateInputValidations(flowSystemName);
    await Promise.all(
      inputValidations.map(async inputValidation => {
        const flowTemplateInputValidationCollection = this.flowTemplatesCollection
          .doc(flowSystemName)
          .collection(INPUT_VALIDATIONS_COLLECTION_NAME)
          .doc(inputValidation.id);
        return flowTemplateInputValidationCollection.set({ ...classToPlain(inputValidation) });
      }),
    );
  }

  async updateFlowTemplate(flowTemplate: FlowTemplateModel): Promise<void> {
    await this.afs
      .collection(FLOW_TEMPLATES)
      .doc(flowTemplate.systemName)
      .set({ ...flowTemplate });
  }

  getCorpFlowTemplates(corpId: string): Observable<FlowTemplateModel[]> {
    return this.flowTemplatesCollectionQuery(ref => ref.where('corpId', '==', corpId))
      .valueChanges()
      .pipe(
        map(flowTemplates => {
          return flowTemplates.map(flowTemplate => plainToClass(FlowTemplateModel, flowTemplate));
        }),
      );
  }

  getGlobalFlowTemplates(): Observable<FlowTemplateModel[]> {
    return this.flowTemplatesCollectionQuery(ref => ref.where('global', '==', true))
      .valueChanges()
      .pipe(
        map(flowTemplates => {
          return flowTemplates.map(flowTemplate => plainToClass(FlowTemplateModel, flowTemplate));
        }),
      );
  }

  getCorpBotFlowBotTemplates(corpId: string): Observable<FlowTemplateModel[]> {
    return this.flowTemplatesCollectionQuery(ref =>
      ref.where('corpId', '==', corpId).where('templateType', '==', FlowTemplateType.BOT_TEMPLATE),
    )
      .valueChanges()
      .pipe(
        map(flowTemplates => {
          return flowTemplates.map(flowTemplate => plainToClass(FlowTemplateModel, flowTemplate));
        }),
      );
  }

  getGlobalBotFlowTemplates(): Observable<FlowTemplateModel[]> {
    return this.flowTemplatesCollectionQuery(ref =>
      ref.where('global', '==', true).where('templateType', '==', FlowTemplateType.BOT_TEMPLATE),
    )
      .valueChanges()
      .pipe(
        map(flowTemplates => {
          return flowTemplates.map(flowTemplate => plainToClass(FlowTemplateModel, flowTemplate));
        }),
      );
  }

  getFlowTemplateBySystemName(systemName: string) {
    return this.flowTemplatesCollectionQuery(ref => ref.where('systemName', '==', systemName))
      .valueChanges()
      .pipe(
        map(flowTemplates => {
          return flowTemplates.map(flowTemplate => plainToClass(FlowTemplateModel, flowTemplate));
        }),
      );
  }
  getFlowTemplatesNodes(flowSystemName: string): Observable<NodeModel[]> {
    return this.flowTemplatesCollection
      .doc(flowSystemName)
      .collection(NODES_COLLECTION_NAME)
      .valueChanges()
      .pipe(
        map(nodeModel => {
          return plainToClass(NodeModel, nodeModel);
        }),
      );
  }

  getFlowTemplateInputValidations(flowSystemName: string): Observable<InputValidationModel[]> {
    return this.flowTemplatesCollection
      .doc(flowSystemName)
      .collection(INPUT_VALIDATIONS_COLLECTION_NAME)
      .valueChanges()
      .pipe(
        map(inputValidation => {
          return plainToClass(InputValidationModel, inputValidation);
        }),
      );
  }

  getFlowTemplateById(flowSystemName: string): Observable<FlowTemplateModel | null> {
    return this.flowTemplatesCollection
      .doc(flowSystemName)
      .valueChanges()
      .pipe(
        map(flowTemplate => {
          return plainToClass(FlowTemplateModel, flowTemplate);
        }),
      );
  }

  async removeFlowTemplateNodes(flowSystemName: string): Promise<void> {
    const nodes = await this.getFlowTemplatesNodes(flowSystemName).pipe(take(1)).toPromise();

    await Promise.all(
      nodes.map(node =>
        this.flowTemplatesCollection.doc(flowSystemName).collection(NODES_COLLECTION_NAME).doc(node.id).delete(),
      ),
    );
  }

  async removeFlowTemplateInputValidations(flowSystemName: string): Promise<void> {
    const inputValidations = await this.getFlowTemplateInputValidations(flowSystemName).pipe(take(1)).toPromise();

    await Promise.all(
      inputValidations.map(inputValidation =>
        this.flowTemplatesCollection
          .doc(flowSystemName)
          .collection(INPUT_VALIDATIONS_COLLECTION_NAME)
          .doc(inputValidation.id)
          .delete(),
      ),
    );
  }

  async removeFlowTemplate(flowSystemName: string) {
    await this.removeFlowTemplateNodes(flowSystemName);
    await this.removeFlowTemplateInputValidations(flowSystemName);

    return this.flowTemplatesCollection.doc(flowSystemName).delete();
  }

  async giveAllNodesNewIdsAndPropagateTheNewIdToAllUsages(
    nodes: NodeModel[],
    flowApiQueries: ApiQueryModel[],
    flowInputValidations: InputValidationModel[],
    flowTemplateToLink?: FlowTemplateModel,
    bot?: BotModel,
  ) {
    // Temporarily Disable Node Propagation
    flowTemplateToLink = undefined;

    let { depsCounts, nodeDeps } = this.generateNodeDependency(nodes);
    depsCounts = depsCounts; // TS hatch

    // sort nodes in asc order based on dependencies count
    const items = Object.entries(depsCounts);
    items.sort((a, b) => {
      const x = a[1];
      const y = b[1];
      return x < y ? -1 : x > y ? 1 : 0;
    });

    // create new nodes from template nodes
    items.forEach(item => {
      const nodeId = item[0];
      const node = this.createNewNode(nodeId, nodeDeps);
      if (!node) {
        return;
      }
      const nodeInfo = nodeDeps[nodeId];
      nodeDeps[nodeId] = { ...nodeInfo, newNode: node };
    });

    // update connections for each node
    items.forEach(item => {
      const nodeId = item[0];
      const nodeInfo = nodeDeps[nodeId];
      if (nodeInfo && nodeInfo.newNode) {
        nodeDeps = this.updateNodeReferences(nodeInfo.newNode, nodeInfo.oldNode, nodeDeps);
      }
    });

    // TODO template syncing too

    if (bot) {
      // update bot event triggers
      const eventTriggers: EventTriggersModel[] = [];
      if (bot.eventTriggers) {
        bot.eventTriggers.forEach(e => {
          const oldNodeId = e.eventTriggerNodeId;
          const oldNodeInfo = nodeDeps[oldNodeId];
          if (oldNodeInfo && oldNodeInfo.newNode) {
            e.eventTriggerNodeId = oldNodeInfo.newNode.id;
            e.eventTriggerNodeName = oldNodeInfo.newNode.name;
          }
          eventTriggers.push(e);
        });
        bot.eventTriggers = eventTriggers;
      }

      // update intro node, if available
      if (bot.initNodeId) {
        const oldNodeInfo = nodeDeps[bot.initNodeId];
        if (oldNodeInfo && oldNodeInfo.newNode) {
          bot.initNodeId = oldNodeInfo.newNode.id;
        }
      }
    }

    const nodesToReturn$1 = Object.values(nodeDeps)
      .filter(nodeInfo => !!nodeInfo)
      .map(nodeInfo => {
        const node = nodeInfo.newNode as NodeModel;
        if (flowTemplateToLink) {
          node.flowTemplateId = flowTemplateToLink.systemName;
          node.flowTemplateLastTouchedHash = flowTemplateToLink.lastTouchedHash;
          node.flowTemplateNodeId = nodeInfo.oldNode.id;
        }
        if (bot) {
          node.botId = bot.id;
        }
        return node;
      });
    const {
      nodes: nodesToReturn$2,
      nodeRelations: apiQueriesToReturn,
    } = this.giveAllNodesAndItsRelationNewAndCorrespondingIds(
      nodesToReturn$1,
      flowApiQueries,
      'apiQueries',
      'apiQueryId',
    );
    const {
      nodes: nodesToReturn,
      nodeRelations: inputValidationsToReturn,
    } = this.giveAllNodesAndItsRelationNewAndCorrespondingIds(
      nodesToReturn$2,
      flowInputValidations,
      'validations',
      'validationId',
    );
    return { nodes: nodesToReturn, apiQueries: apiQueriesToReturn, inputValidations: inputValidationsToReturn, bot };
  }

  private generateNodeRelatedIdsMapping(nodeRelation: Array<{ id: string }>): INodeRelatedIdsMapping[] {
    return nodeRelation.reduce((mappings, { id }) => {
      return [
        ...mappings,
        {
          oldId: id,
          newId: uuidv4(),
        },
      ];
    }, []);
  }

  private giveAllNodesAndItsRelationNewAndCorrespondingIds<T extends { id: string }>(
    nodes: NodeModel[],
    inputNodeRelation: T[],
    nodekey: keyof NodeModel,
    nodeRelationIdkey: string,
  ): {
    nodes: NodeModel[];
    nodeRelations: T[];
  } {
    const nodeRelationMapping = this.generateNodeRelatedIdsMapping(inputNodeRelation);
    const newNodes = nodes.map(node => {
      const nodeRelation = node[nodekey];
      if (!nodeRelation) {
        return node;
      }

      const replacedRelations = this.replaceRelatedIdsWithNewlyGeneratedOnes<NodeApiQueryModel>(
        nodeRelationMapping,
        nodeRelation,
        nodeRelationIdkey,
      );

      return { ...node, [nodekey]: replacedRelations };
    });

    const nodeRelations = this.replaceRelatedIdsWithNewlyGeneratedOnes<T>(nodeRelationMapping, inputNodeRelation, 'id');

    return { nodes: newNodes, nodeRelations };
  }

  private replaceRelatedIdsWithNewlyGeneratedOnes<T>(
    apiQueryIdsMapping: INodeRelatedIdsMapping[],
    relatedModel: Array<T>,
    fieldToReplace: string,
  ): T[] {
    return relatedModel.map(model => {
      const replacement = apiQueryIdsMapping.find(({ oldId }) => oldId === model[fieldToReplace]);
      if (!replacement) {
        return model;
      }
      return { ...model, [fieldToReplace]: replacement.newId };
    });
  }

  private generateNodeDependency(
    nodes: NodeModel[],
  ): { nodeDeps: Record<string, NodeInfo>; depsCounts: Record<string, number> } {
    const nodeDeps: Record<string, NodeInfo> = {};
    const depsCounts: Record<string, number> = {};

    const increaseDepsCount = (nodeId: string) => {
      if (!depsCounts[nodeId]) {
        depsCounts[nodeId] = 0;
      }
      depsCounts[nodeId] = depsCounts[nodeId] + 1;
    };

    // create a hashtable of the dependencies
    nodes.forEach(node => {
      if (!depsCounts[node.id]) {
        depsCounts[node.id] = 0;
      }
      const deps: string[] = [];
      if (node.connections) {
        node.connections.forEach(connection => {
          if (connection.id) {
            deps.push(connection.id);
            increaseDepsCount(connection.id);
          }
        });
      }

      if (node.validations) {
        node.validations.forEach(validation => {
          if (validation.fallbackRedirectNodeId) {
            deps.push(validation.fallbackRedirectNodeId);
            increaseDepsCount(validation.fallbackRedirectNodeId);
          }
          if (validation.fallbackRetryNodeId) {
            deps.push(validation.fallbackRetryNodeId);
            increaseDepsCount(validation.fallbackRetryNodeId);
          }
          if (validation.storableConfirmNodeId) {
            deps.push(validation.storableConfirmNodeId);
            increaseDepsCount(validation.storableConfirmNodeId);
          }
        });
      }

      if (node.templates) {
        node.templates.forEach(template$1 => {
          if (template$1.rawTemplate) {
            const foundUuids = template$1.rawTemplate.match(uuidV4Regex);
            if (foundUuids) {
              foundUuids.forEach(nodeId => {
                deps.push(nodeId);
                increaseDepsCount(nodeId);
              });
            }
          }
        });
      }

      nodeDeps[node.id] = { newNode: undefined, oldNode: node, deps };
    });
    return { nodeDeps, depsCounts };
  }

  private updateNodeReferences(createdNode: NodeModel, oldNode: NodeModel, nodeDeps: Record<string, NodeInfo>) {
    // check for oldNode connections and set all nodes that depend on it
    const connections: NodeConnectionModel[] = [];
    const validations: NodeValidationModel[] = [];
    const templates: TemplateModel[] = [];
    // update node connections
    if (oldNode.connections) {
      oldNode.connections.forEach(connection => {
        const connectionNodeInfo = nodeDeps[connection.id];
        if (connectionNodeInfo.newNode) {
          connection.id = connectionNodeInfo.newNode.id;
          connections.push(connection);
        }
      });
      createdNode.connections = connections;
    }

    if (oldNode.templates) {
      oldNode.templates.forEach(template$1 => {
        if (template$1.rawTemplate) {
          const foundUuids = template$1.rawTemplate.match(uuidV4Regex);
          if (foundUuids) {
            foundUuids.forEach(nodeIdToReplace => {
              const templateNodeInfo = nodeDeps[nodeIdToReplace.toLowerCase()];
              if (templateNodeInfo && templateNodeInfo.newNode) {
                const replacementNodeId = templateNodeInfo.newNode.id;
                const nodeIdToReplaceRegex = new RegExp(nodeIdToReplace, 'gi');
                template$1.rawTemplate = template$1.rawTemplate.replace(
                  nodeIdToReplaceRegex,
                  replacementNodeId.toUpperCase(),
                );
              }
            });
            TemplateModel.setEncodeBlob(template$1, template$1.rawTemplate);
          }
        }
        templates.push(template$1);
      });
      createdNode.templates = templates;
    }
    // update validations
    if (oldNode.validations) {
      oldNode.validations.forEach(validation => {
        if (validation.fallbackRedirectNodeId) {
          const fallbackRedirectNodeInfo = nodeDeps[validation.fallbackRedirectNodeId];
          if (fallbackRedirectNodeInfo && fallbackRedirectNodeInfo.newNode) {
            validation.fallbackRedirectNodeId = fallbackRedirectNodeInfo.newNode.id;
          }
        }
        if (validation.fallbackRetryNodeId) {
          const fallbackRetryNodeInfo = nodeDeps[validation.fallbackRetryNodeId];
          if (fallbackRetryNodeInfo && fallbackRetryNodeInfo.newNode) {
            validation.fallbackRetryNodeId = fallbackRetryNodeInfo.newNode.id;
          }
        }
        if (validation.storableConfirmNodeId) {
          const storableConfirmNodeInfo = nodeDeps[validation.storableConfirmNodeId];
          if (storableConfirmNodeInfo && storableConfirmNodeInfo.newNode) {
            validation.storableConfirmNodeId = storableConfirmNodeInfo.newNode.id;
          }
        }
        validations.push(validation);
      });

      createdNode.validations = validations;
    }

    const nodeInfo = nodeDeps[oldNode.id];
    nodeDeps[oldNode.id] = { ...nodeInfo, newNode: createdNode };

    return nodeDeps;
  }

  createNewNode(currentNodeId: string, nodes: { [key: string]: NodeInfo }) {
    // TODO There is a problem of when an exported node is referencing a node that is not exported
    const nodeInfo = nodes[currentNodeId];
    if (!nodeInfo) {
      return;
    }
    const oldNode = nodeInfo.oldNode;
    const node = NodeModel.duplicate(oldNode);
    NodeModel.generateId(node);
    node.name = oldNode.name;
    NodeModel.generateSystemName(node);
    const node$1 = JSON.parse(JSON.stringify(node)) as NodeModel;
    node$1.createdAt = node.createdAt;
    node$1.updatedAt = node.updatedAt;
    return node$1;
  }
}
