import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UploadCampaignService } from '../../../../../../services/upload.campaign.service';
import { ShowRecallCampaignsUsers } from '../../settings/recall-campaigns-users';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';

@Component({
  selector: 'app-add-upload-campaign-modal',
  templateUrl: './add-recall-campaign-modal.component.html',
  styleUrls: ['./add-recall-campaign-modal.component.scss'],
})
export class AddRecallCampaignModalComponent implements OnInit {
  loading: boolean;
  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private uploadCampaign: UploadCampaignService,
    private domSanitizer: DomSanitizer,
  ) {
    if (!options.initialState) {
      return;
    }

    const { userEmail, dealershipId, result, userName } = options.initialState as any;
    this.dealershipId = dealershipId;
    this.email = userEmail;
    this.userName = userName;
    this.fileName = '';
    this.errorMessage = '';
    this.successMessage = '';
    this.campaignName = '';
    this.result = result;
    this.errorLinesSettings = new ShowRecallCampaignsUsers(domSanitizer).get();
  }
  public modeLabel = 'Validate CSV';
  public fileName: string;
  public email: string;
  public userName: string;
  public errorMessage: string;
  public successMessage: string;
  public campaignName: string;
  public fileContent: string | undefined;
  public dealershipId: string;
  public fileId: string;
  public result: Subject<string>;
  public errorLinesSettings: any;
  public lineErrors: any[] = [];
  public batchSize = '50';
  public startDate: Date;

  public minDate: Date;

  async ngOnInit() {
    this.minDate = moment().subtract(0, 'days').toDate();
  }

  validateInput(): boolean {
    this.errorMessage = '';
    this.successMessage = '';
    if (!this.campaignName) {
      this.errorMessage = 'Please provide a campaign name.';
      return false;
    }
    if (!this.email) {
      this.errorMessage = 'Please provide a valid email to send errors to.';
      return false;
    }

    if (!this.startDate) {
      this.errorMessage = 'Please select a start date';
      return false;
    }

    const regexMaxConversations = new RegExp('[0-9]+$');
    const isValidMaxConversations = regexMaxConversations.test(this.batchSize);
    if (!isValidMaxConversations) {
      this.errorMessage = 'Please provide a valid number for Max.Conversations per Day';
      return false;
    }

    const regexEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    const isValidEmail = regexEmail.test(this.email);
    console.log(isValidEmail);
    if (!isValidEmail) {
      this.errorMessage = 'Please provide a valid email to send errors to.';
      return false;
    }
    if (!this.fileName) {
      this.errorMessage = 'Please select a file to upload.';
      return false;
    }

    return true;
  }
  async validateSubmitButton() {
    if (!this.validateInput()) {
      return;
    }

    if (!this.fileContent) {
      this.errorMessage = 'File is empty';
      return false;
    }

    if (this.modeLabel === 'Validate CSV') {
      const validationResult = await this.uploadCampaign.validateInput(
        this.campaignName,
        this.email,
        this.fileName,
        this.fileContent,
        this.dealershipId,
      );
      if (validationResult.success) {
        this.successMessage = 'Data has been correctly validated and can be submitted';
        this.modeLabel = 'Submit CSV';
        this.fileId = validationResult.fileId;
        this.lineErrors = [];
        this.errorMessage = '';
      } else {
        if (validationResult.errorMessage) {
          this.errorMessage = validationResult.errorMessage;
        } else {
          if (validationResult.numberOfErrors === 1) {
            this.errorMessage = `There is one line with problem(s) on the file.`;
          } else {
            this.errorMessage = `There are ${validationResult.numberOfErrors} lines with problems on the file.`;
          }

          this.lineErrors = validationResult.errorLines;
          this.lineErrors = this.lineErrors.length > 5 ? this.lineErrors.slice(0, 5) : this.lineErrors;
        }
        const elements = document.getElementsByClassName('modal-dialog');
        if (elements.length > 0) {
          // @ts-ignore
          elements[0].style.minWidth = '900px';
          // @ts-ignore
          elements[0].style.topBorder = '0px';
        }
      }
    } else {
      const uploadResult = await this.uploadCampaign.uploadData(
        this.fileId,
        this.dealershipId,
        this.startDate.toISOString().split('T')[0],
        parseInt(this.batchSize, 10),
        this.userName,
        this.campaignName,
      );
      if (!uploadResult) {
        this.modal.hide();
        this.result.next(`File ${this.fileName} has been uploaded successfully.`);
      } else {
        this.errorMessage = uploadResult;
      }
    }
  }
  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.fileName = file.name;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        this.fileContent = fileReader.result?.toString();
      };
      this.modeLabel = 'Validate CSV';
      this.errorMessage = '';
      this.successMessage = '';
      this.lineErrors = [];
      const elements = document.getElementsByClassName('modal-dialog');
      if (elements.length > 0) {
        // @ts-ignore
        elements[0].style.minWidth = '500px';
        // @ts-ignore
        elements[0].style.topBorder = '0px';
      }
    }
  }
}
