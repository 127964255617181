import { TimeInterval } from 'src/app/components/analytics/_types/TimeInterval';

export class AnalyticsCardInterval1D extends TimeInterval {
  constructor() {
    super('1d');
  }

  getPastDate(): Date {
    const date = new Date(Date.now());
    date.setDate(date.getDate() - 1);
    return date;
  }

  getExplanation(): string {
    return 'Since yesterday';
  }
}
