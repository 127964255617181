import { Component } from '@angular/core';
import { NodeModel } from 'src/app/models/node';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BrainService } from 'src/app/services/brain.service';
import { ToastrService } from 'ngx-toastr';
import { SpecTemplateModel } from 'src/app/models/spec_template';
import { NodesService } from 'src/app/services/firestore';

@Component({
  selector: 'app-edit-spec-template-modal',
  templateUrl: './edit-spec-template-modal.component.html',
  styleUrls: ['./edit-spec-template-modal.component.scss'],
})
export class EditSpecTemplateModalComponent {
  loading: boolean;
  node: NodeModel;
  spec: string;
  specTemplates: SpecTemplateModel[] = [];

  contents: object = {};

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private nodesService: NodesService,
    private brainService: BrainService,
    private toaster: ToastrService,
  ) {
    const { node, specTemplates } = options.initialState as any;
    this.node = node;
    this.specTemplates = specTemplates;
    this.loading = false;
    this.specTemplates.forEach(t => {
      this.contents[t.key] = t.content;
    });
  }

  get title() {
    return `Edit Spec Content Templates - ${this.node.spec}`;
  }

  compileContent(content) {
    return this.brainService
      .compileTemplate({
        source: content,
        data: {},
      })
      .toPromise();
  }

  async validateTemplate(content) {
    try {
      const result = await this.compileContent(content);
      return !result.error;
    } catch (error) {
      return false;
    }
  }

  async saveSpecTemplates() {
    const specTemplates: { [key: string]: SpecTemplateModel } = {};
    this.specTemplates.forEach(t => {
      t.content = this.contents[t.key];
      specTemplates[t.key] = t;
    });
    const validatedTemplates = await Promise.all(
      Object.entries(specTemplates).map(async (kv, i) => {
        const content = kv[1].content;
        const valid = await this.validateTemplate(content);
        const index = i + 1;
        return { valid, index, content };
      }),
    );

    const invalidTemplates = validatedTemplates.filter(e => {
      return !e.valid;
    });

    if (invalidTemplates.length > 0) {
      const firstError = invalidTemplates[0];
      this.toaster.error(`Invalid Template ${firstError.index}: ${firstError.content}`);
      return;
    }
    this.node.specTemplates = specTemplates;
    try {
      await this.nodesService.updateNode(this.node); // :eyes for flow templates
      this.toaster.success('Saved sucessfully.');
      this.modal.hide();
    } catch (err) {
      this.toaster.error('Could not save content. Try again.');
    }
  }

  getTemplateHint(template: SpecTemplateModel | undefined) {
    if (template) {
      if (template.conditions) {
        return Object.keys(template.conditions)
          .map(k => {
            let v = '';
            if (template.conditions) {
              v = template.conditions[k];
            }
            const s = `<span><strong>${k}</strong>: ${v}</span>`;
            return s;
          })
          .join(', &nbsp;&nbsp;');
      }
      return 'All Trims';
    }
    return 'All Trims';
  }
}
