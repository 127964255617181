import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { RoleModel } from 'src/app/models';
import { RolesService } from 'src/app/services/firestore';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { ManageRoleModel } from '../_shared/manage-role-model.class';
import { getPermissionsToRemove, getPermissionsToAdd } from '../utils';
import { SelectPermissionValue } from '../types';
import { Permissions } from 'src/app/utils/permissions/permissions';

@Component({
  selector: 'app-edit-role-modal',
  templateUrl: './edit-role-modal.component.html',
  styleUrls: ['./edit-role-modal.component.scss'],
})
export class EditRoleModalComponent extends ManageRoleModel implements OnInit {
  role: RoleModel;
  canWriteRoles: boolean;
  loading: boolean;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private rolesService: RolesService,
    public modal: BsModalRef,
    private toaster: ToastrService,
    private authService: AuthService,
    options: ModalOptions,
  ) {
    super();
    this.role = new RoleModel();

    if (!options.initialState) {
      return;
    }
    const { role, canWriteRoles } = options.initialState as any;
    this.role = role;
    this.canWriteRoles = canWriteRoles;

    this.selectedPermissions = this.role.permissions.map(this.mapPermissionStringToSelectOption);
  }

  async ngOnInit() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }

    this.role.lastTouchedBy = user.uid;
  }

  newPermissionAdded(permission: Permissions) {
    this.role.permissions = getPermissionsToAdd(this.role.permissions, permission);
  }

  permissionRemoved({ value }: SelectPermissionValue) {
    this.role.permissions = getPermissionsToRemove(this.role.permissions, value);
  }

  async save() {
    if (this.role.permissions.length === 0) {
      this.toaster.info('You need at least a permission to save role');
      return;
    }
    this.actionProgress.start();
    this.loading = true;
    try {
      await this.rolesService.updateRole(this.role);
      this.modal.hide();
      this.toaster.success('Role Saved Successfully');
    } catch (error) {
      this.toaster.error(error);
    }
    this.loading = false;
    this.actionProgress.complete();
  }
}
