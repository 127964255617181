import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FlowTemplateModel, BotModel, CorpModel } from 'src/app/models';
import {
  FlowTemplatesService,
  ApiQueriesService,
  BotsService,
  InputValidationsService,
  NodesService,
} from 'src/app/services/firestore';
import { take } from 'rxjs/operators';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { NodeModel } from 'src/app/models/node';

@Component({
  selector: 'app-import-flow-template-modal',
  templateUrl: './import-flow-template-modal.component.html',
  styleUrls: ['./import-flow-template-modal.component.scss'],
})
export class ImportFlowTemplateModalComponent implements OnInit {
  flowTemplate: FlowTemplateModel;
  bots: BotModel[];
  destinationBotId: string;
  destinationCorpId: string;
  loading: boolean;
  isGlobal: boolean;
  userId: string;
  corps: CorpModel[];
  importStrategy = 'UNLINK';
  nodesPrefix: string;
  importNames: string[] = [];
  checkingIfFlowWasPreviouslyImportedToBot = false;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private flowTemplatesService: FlowTemplatesService,
    private botsService: BotsService,
    private nodesService: NodesService,
    private inputValidationsService: InputValidationsService,
    private apiQueryService: ApiQueriesService,
    public modal: BsModalRef,
    private toaster: ToastrService,
    options: ModalOptions,
  ) {
    this.flowTemplate = new FlowTemplateModel();

    if (!options.initialState) {
      return;
    }
    const { flowTemplate, bots, corps, isGlobal, userId } = options.initialState as any;
    this.flowTemplate = flowTemplate;
    this.isGlobal = isGlobal;
    this.corps = corps;
    this.userId = userId;
    this.bots = bots;
  }

  async ngOnInit() {}

  async getBotsInSelectedCorp() {
    this.bots = [];
    this.bots = await this.botsService.getUserAllowedBotsInCorps(this.userId, this.destinationCorpId);
  }

  async checkIfFlowWasPreviouslyImportedToBot() {
    this.checkingIfFlowWasPreviouslyImportedToBot = true;
    const importNames = await this.botsService.getFlowTemplateImportNames(
      this.destinationBotId,
      this.flowTemplate.systemName,
    );
    this.checkingIfFlowWasPreviouslyImportedToBot = false;
    this.importNames = importNames ?? [];
  }

  async importTemplate() {
    if (!this.importStrategy) {
      this.toaster.error('Please Select Import Strategy');
      return;
    }

    if (!this.destinationBotId) {
      this.toaster.error('Please Select Destination Assistant');
      return;
    }

    if (this.importNames.length > 0 && !this.nodesPrefix) {
      this.toaster.error('Nodes Prefix Is Required');
      return;
    }

    if (this.nodesPrefix && this.importNames.includes(this.nodesPrefix)) {
      this.toaster.error('Prefix Has Been Used');
      return;
    }

    this.actionProgress.start();

    const nodes = await this.flowTemplatesService
      .getFlowTemplatesNodes(this.flowTemplate.systemName)
      .pipe(take(1))
      .toPromise();

    const flowApiQueries = await this.flowTemplatesService
      .getFlowTemplatesApiQueries(this.flowTemplate.systemName)
      .pipe(take(1))
      .toPromise();

    const flowInputValidations = await this.flowTemplatesService
      .getFlowTemplateInputValidations(this.flowTemplate.systemName)
      .pipe(take(1))
      .toPromise();

    const {
      nodes: nodesToCreate,
      apiQueries: apiQueriesToCreate,
      inputValidations: inputValidationsToCreate,
    } = await this.flowTemplatesService.giveAllNodesNewIdsAndPropagateTheNewIdToAllUsages(
      nodes,
      flowApiQueries,
      flowInputValidations,
      this.importStrategy === 'LINK' ? this.flowTemplate : undefined,
    );
    if (this.nodesPrefix) {
      nodesToCreate.forEach(node => {
        node.name = `${node.name} - ${this.nodesPrefix}`;
        NodeModel.generateSystemName(node);
      });
    }

    await this.botsService.addFlowTemplateImportNameToBot(
      this.destinationBotId,
      this.flowTemplate.systemName,
      this.nodesPrefix ?? 'default',
    );

    try {
      await Promise.all(
        nodesToCreate.map(node =>
          this.nodesService.addNodeAndPrefixNodeNameUntilItDoesntExist({ ...node, botId: this.destinationBotId }),
        ),
      );
      await Promise.all(
        apiQueriesToCreate.map(apiQuery =>
          this.apiQueryService.addApiQuery({ ...apiQuery, botId: this.destinationBotId }, false),
        ),
      );
      await Promise.all(
        inputValidationsToCreate.map(inputValidation =>
          this.inputValidationsService.addInputValidation(
            { ...inputValidation, corpId: this.destinationCorpId },
            false,
          ),
        ),
      );
      this.toaster.success('Template imported successfully');
    } catch (error) {
      this.toaster.error(error);
    }
    this.actionProgress.complete();
    this.modal.hide();
  }
}
