import { jsonToHandlebars } from './../../../utils/handlebars/handlebars.utils';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SimpleEditorTab } from './_types/SimpleEditorTab';
import { SimpleEditorLog } from './_types/SimpleEditorLog';
import { SimpleEditorTheme } from './_types/SimpleEditorTheme';

@Component({
  selector: 'app-simple-editor',
  templateUrl: './simple-editor.component.html',
  styleUrls: ['./simple-editor.component.scss']
})
export class SimpleEditorComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  tabs: SimpleEditorTab[];

  @Input()
  canSave = true;

  @Input()
  canRename = false;

  @Input()
  showVisualTemplateEditor = false;

  activeTab: SimpleEditorTab;

  data: string;

  @Output()
  save: EventEmitter<SimpleEditorTab>;

  @Output()
  saveAll: EventEmitter<SimpleEditorTab[]>;

  @Output()
  renameFile: EventEmitter<SimpleEditorTab>;

  @Output()
  transpileJsonToHandlebars: EventEmitter<any>;

  dropdownThemeShow = false;

  dropdownModeShow = false;

  themes = [
    {
      label: 'Light',
      value: 'vs-light',
      containerClass: 'simple-editor--light'
    },
    {
      label: 'Dark',
      value: 'vs-dark',
      containerClass: 'simple-editor--dark'
    }
  ];
  theme = this.themes[0];
  editorOptions = {
    theme: 'vs-light',
    language: 'json',
    fontFamily: 'Source Code Pro'
  };

  constructor() {
    this.renameFile = new EventEmitter<SimpleEditorTab>();
    this.save = new EventEmitter<SimpleEditorTab>();
    this.saveAll = new EventEmitter<SimpleEditorTab[]>();
    this.transpileJsonToHandlebars = new EventEmitter<any>();
  }

  ngOnInit() {
    this.onInitTab(this.tabs[0]);
  }

  onEditorInit(event) {
    monaco.languages.register({ id: 'chatito' });
    monaco.languages.setMonarchTokensProvider('chatito', {
      tokenizer: {
        root: [
          [/~\[.*?\]/, 'variable'],
          [/%\[.*?\]/, 'keyword'],
          [/@\[.*?\]/, 'regexp'],
          [/\*\[.*?\]/, 'type'],
          [/import/, 'constant'],
          [/('|").*?('|")/, 'string'],
          [/^#.*$/, 'comment'],
          [/[{}()\[\]]/, '@brackets']
        ]
      }
    });
    this.updateEditorModelOptions();
  }

  onInitTab(tab: SimpleEditorTab) {
    this.editorOptions = {
      ...this.editorOptions,
      language: tab.language
    };
    this.activeTab = tab;
  }

  onChangeTab(tab: SimpleEditorTab) {
    this.editorOptions = {
      ...this.editorOptions,
      language: tab.language
    };
    this.activeTab = tab;
    this.updateEditorModelOptions();
  }

  updateEditorModelOptions() {
    if (!monaco || monaco == null || monaco == undefined) {
      return;
    }
    // @ts-ignore: Typings are wrong, throws error
    monaco.editor.getModels()[0].updateOptions(this.editorOptions);
  }

  processDropDownThemeClick() {
    this.dropdownThemeShow = !this.dropdownThemeShow;
  }

  processDropDownThemeItemClick(theme: SimpleEditorTheme) {
    this.processDropDownThemeClick();
    this.theme = theme;
    this.editorOptions = {
      ...this.editorOptions,
      theme: this.theme.value
    };
    this.updateEditorModelOptions();
  }

  addLog(log: SimpleEditorLog) {
    this.activeTab.logs.push(log);
  }

  processRenameFile() {
    this.renameFile.emit(this.activeTab);
  }

  processSaveClick() {
    this.save.emit(this.activeTab);
    this.saveAll.emit(this.tabs);
  }

  processVisualData(value: any) {
    this.transpileJsonToHandlebars.emit(value);
  }
}