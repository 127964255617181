import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CorpHierarchyModel } from 'src/app/models';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { CorpHierarchiesService } from 'src/app/services/firestore';

@Component({
  selector: 'app-edit-corp-hierarchy-modal',
  templateUrl: './edit-corp-hierarchy-modal.component.html',
  styleUrls: ['./edit-corp-hierarchy-modal.component.scss'],
})
export class EditCorpHierarchyModalComponent implements OnInit {
  corpHierarchy: CorpHierarchyModel;
  corpId: string;
  loading: boolean;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private corpHierarchiesService: CorpHierarchiesService,
    public modal: BsModalRef,
    private toaster: ToastrService,
    private authService: AuthService,
    options: ModalOptions,
  ) {
    this.corpHierarchy = new CorpHierarchyModel();

    if (!options.initialState) {
      return;
    }
    const { corpHierarchy, corpId } = options.initialState as any;
    this.corpHierarchy = corpHierarchy;
    this.corpId = corpId;
  }

  async ngOnInit() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }

    this.corpHierarchy.lastTouchedBy = user.uid;
  }

  async save() {
    this.actionProgress.start();
    this.loading = true;
    try {
      await this.corpHierarchiesService.updateCorpHierarchy(this.corpId, this.corpHierarchy);
      this.modal.hide();
      this.toaster.success('Corp Hierarchy Saved');
    } catch (error) {
      this.toaster.error(error);
    }
    this.loading = false;
    this.actionProgress.complete();
  }
}
