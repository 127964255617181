import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NodeModel } from 'src/app/models/node';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TemplateChannels } from 'src/app/models/template';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-hl-select-node-modal',
  templateUrl: './hl-select-node-modal.component.html',
  styleUrls: ['./hl-select-node-modal.component.scss'],
})
export class HlSelectNodeModalComponent implements OnInit {
  allNodes: NodeModel[] = [];
  header = '';
  searchResult: NodeModel[] = [];
  search = '';
  searchMode = false;
  currentNode: NodeModel;
  selectedNode: EventEmitter<{ node: NodeModel; channel: string }>;
  searchTextChanged: Subject<string> = new Subject<string>();
  isLoading = false;
  constructor(public modal: BsModalRef, options: ModalOptions, private toaster: ToastrService) {
    const { nodes, fullName, vehicle } = options.initialState as any;
    this.allNodes = nodes;
    this.header = vehicle !== undefined && vehicle.trim() != '' ? ' - ' + vehicle : '';
    this.header = fullName !== undefined ? fullName + this.header : '';
    this.selectedNode = new EventEmitter<{ node: NodeModel; channel: string }>();
    this.searchTextChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe(query => {
      this.searchNodes(query);
    });
  }

  ngOnInit() {}

  useNode(node: NodeModel) {
    this.currentNode = node;
  }

  doSearchNodes(query) {
    this.searchTextChanged.next(this.search);
  }

  searchNodes(searchText) {
    this.searchMode = searchText.trim().length > 0;

    if (this.searchMode) {
      const searchByName = this.allNodes.filter(node => {
        return node.name.toLowerCase().includes(searchText.toLowerCase());
      });
      this.searchResult = searchByName;
    } else {
      this.searchResult = [];
    }
  }

  done() {
    if (this.currentNode) {
      // use web as channel for setNode for now
      this.isLoading = true;
      this.selectedNode.emit({ node: this.currentNode, channel: 'web' });
    } else {
      this.toaster.error('Select a node and a channel to continue');
    }
  }
}
