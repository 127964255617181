import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { HierarchyElementModel, CorpHierarchyModel } from 'src/app/models';

@Component({
  selector: 'app-add-hierarchy-element-modal',
  templateUrl: './add-hierarchy-element-modal.component.html',
  styleUrls: ['./add-hierarchy-element-modal.component.scss']
})
export class AddHierarchyElementModalComponent implements OnInit {
  hierarchyElement: HierarchyElementModel;
  corpHierarchy: CorpHierarchyModel;
  parentSystemName: string;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    this.hierarchyElement = new HierarchyElementModel();

    if (!options.initialState) {
      return;
    }
    const { corpHierarchy, parentSystemName } = options.initialState as any;
    this.corpHierarchy = corpHierarchy;
    this.parentSystemName = parentSystemName;
  }

  ngOnInit() {}
}
