import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { ApiQueryModel } from 'src/app/models/api-query';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { BotModel } from 'src/app/models/bot';
import { getSidebarItems as corpGetSidebarItems } from '../../pages/portal/corp/utils';
import { getSidebarItems as portalGetSidebarItems } from '../../pages/portal/utils';
import { CorpsService, FlowTemplatesService } from 'src/app/services/firestore';
import { FlowTemplateModel } from 'src/app/models';

@Component({
  selector: 'app-edit-flow-template-api-query',
  templateUrl: './edit-flow-template-api-query.component.html',
  styleUrls: ['./edit-flow-template-api-query.component.scss'],
})
export class EditFlowTemplateApiQueryComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  private isGlobal: boolean;

  permissionToWriteApiQueries = Permissions.CAN_WRITE_FLOW_TEMPLATE_API_QUERIES;
  bot: BotModel;
  apiQuery: ApiQueryModel;

  private corp: CorpModel;
  private flowTemplate: FlowTemplateModel;

  constructor(
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private flowTemplatesService: FlowTemplatesService,
    private router: Router,
    private toaster: ToastrService,
  ) {}

  async ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap]).subscribe(([params]) => {
      const corpId = params.get('corp');
      const flowTemplateId = params.get('flowTemplateId');
      const apiQuerySystemName = params.get('apiQuery');

      if (!flowTemplateId || !apiQuerySystemName) {
        return;
      }

      if (!corpId) {
        this.isGlobal = true;
      }

      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(`${corpId}`),
        this.flowTemplatesService.getFlowTemplateById(flowTemplateId),
        this.flowTemplatesService.getApiQueryBySystemName(flowTemplateId, apiQuerySystemName),
      ]).subscribe(
        async ([corp, flowTemplate, apiQuery]) => {
          if (!apiQuery || !flowTemplate) {
            return;
          }

          if (corp) {
            this.corp = corp;
          }
          this.flowTemplate = flowTemplate;

          const bot = flowTemplate.getBotConfig;
          if (!bot) {
            return;
          }
          this.bot = bot;

          if (!this.bot.apiGatewayServiceId) {
            this.toaster.info(
              `Sorry! ${this.bot.label} doesn't have an associated API ID.`, // TODO raise an error code here
            );
            this.router.navigate(['../'], { relativeTo: this.route });
            return;
          }

          this.apiQuery = apiQuery;
          this.refreshUI();
        },
        error => {
          this.toaster.error(error);
        },
      );
    });
  }

  async updateApiQuery(apiQuery: ApiQueryModel) {
    try {
      await this.flowTemplatesService.updateFlowTemplatesApiQuery(apiQuery);
      this.toaster.success('Saved API Query');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private refreshUI() {
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
    this.headerService.setPageTitle(`${this.flowTemplate.label} API Queries`);
  }

  private setSidebarItems(corpId: string) {
    if (this.isGlobal) {
      this.sidebarService.set(portalGetSidebarItems());
    } else {
      this.sidebarService.set(corpGetSidebarItems(corpId));
    }
  }

  private setBreadcrumb(corp: CorpModel) {
    if (this.isGlobal) {
      this.breadcrumbService.set([
        {
          label: 'Global Flow Templates',
          route: '/portal/global-flow-templates',
        },
      ]);
    } else {
      this.breadcrumbService.set([
        {
          label: corp.label,
          icon: corp.logo,
          route: `corps/${corp.id}`,
          testId: 'bread-crumb-corp',
        },
        {
          label: 'Flow Templates',
          route: `corps/${corp.id}/flow-templates`,
        },
      ]);
    }
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
