export class SpecTemplateModel {
  key: string;
  content: string;
  conditions: object | null;
  formattedConditions: object | null;

  static fromJSON(json: object) {
    const model = new SpecTemplateModel();
    model.key = json['key'];
    model.conditions = json['conditions'];
    model.formattedConditions = json['formatted_conditions'];
    if (!model.formattedConditions) {
      model.formattedConditions = null;
    }
    if (!model.conditions) {
      model.conditions = null;
    }
    model.content = json['content'];
    return model;
  }
}
