import { DomSanitizer } from '@angular/platform-browser';

export class ShowRecallCampaignsUsers {
  constructor(private domSanitizer: DomSanitizer) {}

  formatError(value) {
    if (value === 'Missing Field') {
      return this.domSanitizer.bypassSecurityTrustHtml(
        `<div style="background-color:red; color:white; text-align: center; border-radius: 10px">${value}</div>`,
      );
    }
    return value;
  }
  get(): any {
    return {
      noDataMessage: 'No records found.',
      hideSubHeader: true,
      attr: {
        class: 'table',
      },
      pager: {
        display: true,
        perPage: 25,
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
      },
      columns: {
        first_name: {
          title: 'First Name',
          filter: true,
          type: 'html',
          valuePrepareFunction: value => this.formatError(value),
        },
        last_name: {
          title: 'Last Name',
          filter: true,
          type: 'html',
          valuePrepareFunction: value => this.formatError(value),
        },
        email: {
          title: 'Email',
          filter: false,
          type: 'html',
          valuePrepareFunction: value => this.formatError(value),
        },
        vin: {
          title: 'VIN',
          filter: false,
          type: 'html',
          valuePrepareFunction: value => this.formatError(value),
        },
        mmy: {
          title: 'YMM',
          filter: false,
          type: 'html',
          valuePrepareFunction: value => this.formatError(value),
        },
        phone: {
          title: 'Phone',
          filter: false,
          type: 'html',
          valuePrepareFunction: value => this.formatError(value),
        },
      },
    };
  }
}
