import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { classToPlain, plainToClass } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore';
import { UserModel } from 'src/app/models';
import { COLLECTION_NAMES } from './constants';
import { RolesService } from './roles.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private usersCollection: AngularFirestoreCollection<UserModel>;
  private usersCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<UserModel>;
  private usersWithPrivilege: any = {};

  constructor(private afs: AngularFirestore, private rolesService: RolesService) {
    this.usersCollection = afs.collection<UserModel>(COLLECTION_NAMES.USERS);
    this.usersCollectionQuery = (ref: QueryFn) => afs.collection<UserModel>(COLLECTION_NAMES.USERS, ref);
  }

  async addUser(user: UserModel): Promise<void> {
    const userId = user.id; // avoiding error prone scenarios
    return this.usersCollection
      .doc(userId)
      .ref.get()
      .then(result => {
        if (result.exists) {
          throw new Error(`User "${userId}" already exists`);
        } else {
          const plainUser = classToPlain(user);
          return this.usersCollection.doc(user.id).set(plainUser as UserModel);
        }
      });
  }

  CanAccessAllCompanies(userId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.getUserById(userId).subscribe(userFromFirebase => {
        if (userFromFirebase?.role) {
          this.rolesService.getRoleById(userFromFirebase?.role).subscribe(roleFromFirebase => {
            this.usersWithPrivilege[userId] =
              roleFromFirebase?.permissions.find(x => x.toString() === 'ACCESS_ALL_COMPANIES') !== undefined;
            resolve(this.usersWithPrivilege[userId]);
          });
        } else {
          resolve(false);
        }
      });
    });
  }

  isUserAbleAccessAllCompanies(userId: string): boolean {
    return this.usersWithPrivilege[userId] === true;
  }

  updateUser(user: UserModel): Promise<void> {
    return this.usersCollection.doc(user.id).update({ ...user });
  }

  getUserById(id: string): Observable<UserModel | null> {
    return this.usersCollection
      .doc(id)
      .valueChanges()
      .pipe(
        map(user => {
          return plainToClass(UserModel, user);
        }),
        catchError(error => {
          console.error(`GetUserById exception: ${error}`);
          return throwError(error);
        }),
      );
  }

  getUsers(): Observable<UserModel[]> {
    return this.usersCollection.valueChanges().pipe(
      map(corps => corps.map(corp => plainToClass(UserModel, corp))),
      catchError(error => {
        console.error(`GetUsers exception: ${error}`);
        return throwError(error);
      }),
    );
  }

  removeUser(userId: string) {
    return this.usersCollection.doc(userId).delete();
  }

  getUsersByRole(role: string): Observable<UserModel[]> {
    const usersCollection = this.usersCollectionQuery(ref => ref.where('role', '==', role));
    return usersCollection.valueChanges().pipe(
      map(users => {
        return users.map(user => plainToClass(UserModel, user));
      }),
    );
  }
}
