import { Component, Input } from '@angular/core';
import { ChartSettings } from '../_type/ChartSettings';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { IChartData, IFetchChartDataParameters } from '../types';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-chart-top-intents',
  templateUrl: './chart-top-intents.component.html',
  styleUrls: ['./chart-top-intents.component.scss']
})
export class ChartTopIntentsComponent {
  @Input()
  corpId: string;

  @Input()
  hierarchyElementId: string;

  @Input()
  botId: string;

  @Input()
  awsRegion: AwsRegionEnum;

  chartData: IChartData = {
    data: [],
    labels: []
  };

  chartSettings: ChartSettings = {
    surtitle: 'Overview',
    title: `Top 5 Intents`,
    timeIntervals: ['1d', '5d', '7d', '1M', '6M', '1y', 'YTD', 'MAX'],
    selectedTimeIntervalIndex: 1,
    type: 'doughnut',
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#172b4d', '#5e72e4', '#f4f5f7', '#11cdef', '#2dce89', '#f5365c', '#fb6340']
        }
      ]
    },
    options: {
      // @ts-ignore
      showCustomLabels: true
    }
  };

  loading: boolean;

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private toaster: ToastrService) {}

  async fetchChartData({ selectedTimeFrame }: IFetchChartDataParameters): Promise<void> {
    this.loading = true;
    try {
      this.chartData = await this.apiGatewayAnalyticsService.getTopIntents(this.botId, selectedTimeFrame);
    } catch (error) {
      this.toaster.error(error);
    } finally {
      this.loading = false;
    }
  }
}
