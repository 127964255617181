import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerSettingsModel } from 'src/app/models/CustomerSettingsModel';

type SharedChannelsPayload = {
  botId: string;
  conversationId: string;
  message: string;
};

type ComputedBodyAttributes = {
  dealershipCode: string;
  corporateName: string;
};

type SendEmailPayload = {
  channel: 'email';
  fromEmail: { address: string | null; name: string | null } | null;
  subject: string;
  toEmail: string | null;
};

type SendSMSPayload = {
  channel: 'sms';
  fromNumber: string;
  toNumber: string;
};

export type SendMessagePayload = SharedChannelsPayload & (SendEmailPayload | SendSMSPayload);

type SharedChannelBody = SendMessagePayload & ComputedBodyAttributes;
type SendEmailBody = SharedChannelBody & SendEmailPayload;

const createEmailBody = (args): SharedChannelBody => {
  const { fromEmail: orgFromEmail } = args;
  const fromEmail = {
    address: orgFromEmail ? orgFromEmail.address : null,
    name: orgFromEmail ? orgFromEmail.name : null,
  };

  return { ...args, fromEmail };
};

const createSMSBody = args => {
  const { message, ...rest } = args;
  return { body: message, ...rest };
};
@Injectable({
  providedIn: 'root',
})
export class BachService {
  private bachSendMessageUrl: string;
  private bachSetDoNotContactUrl: string;
  private bachSetStopSeriesUrl: string;
  private bachSetVehicleInactive: string;
  private bachSetPreferredChannel: string;
  private bachGetUserByConversationIdUrl: string;
  private bachUpdateCustomerSettingsUrl: string;

  constructor(private http: HttpClient) {
    this.bachSendMessageUrl = environment.bach.url + 'send-message';
    this.bachSetDoNotContactUrl = environment.bach.url + 'set-do-not-contact';
    this.bachSetStopSeriesUrl = environment.bach.url + 'set-stop-current-series';
    this.bachSetVehicleInactive = environment.bach.url + 'set-vehicle-inactive';
    this.bachGetUserByConversationIdUrl = environment.bach.url + 'get-user-by-conversation';
    this.bachUpdateCustomerSettingsUrl = environment.bach.url + 'update-customer-settings';
    this.bachSetPreferredChannel = environment.bach.url + 'set-preferred-channel';
  }

  async sendConversationMessage(args: SendMessagePayload) {
    const { botId, channel } = args;
    const botComponents = botId.split('-');
    let dealershipCode = botComponents[0] + '-' + botComponents[1];
    if (botComponents.length === 4) {
      dealershipCode += '-' + botComponents[2];
    }
    if (botComponents.length === 5) {
      dealershipCode += '-' + botComponents[3];
    }

    const corporateName = botComponents[0];

    const computedAttributes: ComputedBodyAttributes = { corporateName, dealershipCode };

    const body =
      channel === 'email'
        ? createEmailBody({ ...args, ...computedAttributes })
        : createSMSBody({ ...args, ...computedAttributes } as SharedChannelBody);

    const result = await this.http
      .post(this.bachSendMessageUrl, body)
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(error => {
        console.log(error);
      });
    return result;
  }

  async setDoNotContact(botId: string, conversationId: string, doNotContact: boolean, isSales: boolean) {
    const result = await this.getUserByConversationId(conversationId);
    const body = {
      dealershipCode: botId,
      userId: result?.user['_id'],
      email: result?.user['email.primary'],
      value: doNotContact,
      isSales,
    };
    const response = await this.http
      .post(this.bachSetDoNotContactUrl, JSON.stringify(body))
      .toPromise()
      .then(data => {
        return { status: 'success', body, data };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async setVehicleInactive(conversationId: string, inactive: boolean) {
    const body = { conversationId, value: inactive };
    const response = await this.http
      .post(this.bachSetVehicleInactive, JSON.stringify(body))
      .toPromise()
      .then(() => {
        return { status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async setPreferredChannel(conversationId: string, channel: string) {
    const body = { conversationId, value: channel };
    const response = await this.http
      .post(this.bachSetPreferredChannel, JSON.stringify(body))
      .toPromise()
      .then(() => {
        return { status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async stopCurrentSeries(conversationId: string, value: boolean) {
    const body = {
      conversationId,
      value,
    };
    const response = await this.http
      .post(this.bachSetStopSeriesUrl, JSON.stringify(body))
      .toPromise()
      .then(() => {
        return { status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async getUserByConversationId(conversationId: string) {
    const body = {
      conversationId,
    };
    const response = await this.http
      .post(this.bachGetUserByConversationIdUrl, JSON.stringify(body))
      .toPromise()
      .then(user => {
        return { user, status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async updateCustomerSettings(userId: string, customerSettings: CustomerSettingsModel) {
    const body = { userId, ...customerSettings };
    const response = await this.http
      .post(this.bachUpdateCustomerSettingsUrl, JSON.stringify(body))
      .toPromise()
      .then(user => {
        return { user, status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }
}
