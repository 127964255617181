export function getFilter(useIntentsConstraint?: boolean) {
  const filter: any = {
    bool: {
      should: [],
    },
  };

  let shouldConditionsCount = 2;
  filter.bool.should.push(
    {
      exists: {
        field: 'messageReceivedEvent.context.conversationId.keyword',
      },
    },
    {
      exists: {
        field: 'messageReceivedEvent.context.timestamp',
      },
    },
  );

  if (useIntentsConstraint) {
    filter.bool.should.push({
      exists: {
        field: 'brainResponseEvent.brainQueryResponse.data.user_data.intentsHistoryPathString.keyword',
      },
    });
    shouldConditionsCount++;
  }

  if (this.botId) {
    filter.bool.should.push({
      term: {
        'messageReceivedEvent.context.platformPayload.vendorId.keyword': this.botId,
      },
    });
    shouldConditionsCount++;
  }

  if (this.corpId) {
    filter.bool.should.push({
      term: {
        'messageReceivedEvent.context.platformPayload.corpId.keyword': this.corpId,
      },
    });
    shouldConditionsCount++;
  }

  if (this.hierarchyElementId) {
    filter.bool.should.push({
      term: {
        'messageReceivedEvent.context.platformPayload.hierarchyElementId': this.hierarchyElementId,
      },
    });
    shouldConditionsCount++;
  }

  if (shouldConditionsCount > 0) {
    filter.bool.minimum_should_match = shouldConditionsCount;
  }

  return filter;
}
