import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/firestore';
import { UserModel } from 'src/app/models';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: UserModel | null;

  private userSubscription: Subscription;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private usersService: UsersService,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.headerService.setPageTitle('Profile');
    this.setBreadcrumb();
    this.setSidebarItems();
    this.getCurrentUser();
  }

  navigateToHome() {
    this.router.navigate(['/portal']);
  }

  private async getCurrentUser() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }
    this.userSubscription = this.usersService.getUserById(user.uid).subscribe(
      userModel => {
        this.user = userModel;
      },
      error => {
        this.toaster.error(error);
      }
    );
  }

  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Profile',
        route: '/portal/profile'
      }
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set([
      {
        path: `/portal/dashboard`,
        title: 'Dashboard',
        type: 'link',
        icontype: 'ni-world-2 text-primary'
      } // , TODO: this will be enabled when will fix the analytics on root level
      // {
      //   path: `/portal/analytics`,
      //   title: 'Analytics',
      //   type: 'link',
      //   icontype: 'ni-chart-bar-32 text-danger'
      // }
    ]);
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
