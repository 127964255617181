export type StageModel = {
  systemName: BotEnvironment;
  name: string;
};

export type ClientEnvironmentModel = {
  stages: StageModel[];
  selectedStage: StageModel;
};

export enum BotEnvironment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production'
}
