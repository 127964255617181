import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionModel } from 'src/app/models/permission';
import { PermissionsService } from 'src/app/services/firestore';

export function getHomeRoute(authService: AuthService, permissionsService: PermissionsService): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const user = await authService.currentUser;
    if (user == null) {
      reject();
      return;
    }
    permissionsService
      .getPermissionsBy(user.uid)
      .pipe(take(1))
      .subscribe((permissions: PermissionModel[] | null) => {
        if (!permissions || !permissions.length) {
          reject();
          return;
        }
        let commonCorpId: string | undefined = permissions[0].corpId;
        let commonHierarchyElementSystemName: string | null | undefined = permissions[0].hierarchyElementSystemName;
        let commonBotCode: string | null | undefined = permissions[0].botCode;
        permissions.slice(1).forEach(permission => {
          if (permission.corpId !== commonCorpId) {
            commonCorpId = undefined;
          }
          if (permission.hierarchyElementSystemName !== commonHierarchyElementSystemName) {
            commonHierarchyElementSystemName = undefined;
          }
          if (permission.botCode !== commonBotCode) {
            commonBotCode = undefined;
          }
        });
        // if (!commonCorpId) {
        //   resolve('portal/dashboard');
        // }
        if (!commonHierarchyElementSystemName) {
          resolve(`portal/corps/${commonCorpId}/dashboard`);
        }
        // if (!commonBotCode) {
        //   resolve(`portal/corps/${commonCorpId}/hierarchy-el/${commonHierarchyElementSystemName}/dashboard`);
        // }
        // resolve(
        //   `portal/corps/${commonCorpId}/hierarchy-el/${commonHierarchyElementSystemName}/bots/${commonBotCode}/dashboard`
        // );
        resolve('portal/dashboard');
      });
  });
}
