import { Component, OnDestroy, OnInit } from '@angular/core';
import { CorpModel } from 'src/app/models/corp';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddCorpModalComponent } from 'src/app/components/modals/add-corp-modal/add-corp-modal.component';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from '../../../../services/sidebar.service';
import { CorpsService, HierarchyElementsService, UsersService } from 'src/app/services/firestore';
import { getSidebarItems } from 'src/app/pages/admin/utils';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-corps-admin',
  templateUrl: './corps-admin.component.html',
  styleUrls: ['./corps-admin.component.scss'],
})
export class CorpsAdminComponent implements OnInit, OnDestroy {
  loading: boolean;
  corps: CorpModel[];
  dataSubscription: Subscription;
  userId: string;
  hasAllCorps: boolean;

  constructor(
    private corpsService: CorpsService,
    private hierarchyElementsService: HierarchyElementsService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private sidebarService: SidebarService,
    private usersService: UsersService,
    private authService: AuthService,
  ) {
    this.loading = false;
  }

  async ngOnInit() {
    this.userId = (await this.authService.currentUser)?.uid || '';
    this.hasAllCorps = this.userId.length > 0 && (await this.usersService.CanAccessAllCompanies(this.userId));
    this.setBreadcrumb();
    this.refreshCorps();
    this.headerService.setPageTitle(`Corporations`);
  }

  private refreshCorps() {
    this.loading = true;
    this.dataSubscription = this.corpsService.getCorps().subscribe(
      (corps: CorpModel[]) => {
        this.corps = corps.filter(x => this.hasAllCorps || x.inactive !== true);

        this.setSidebarItems();
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.toaster.error(error);
      },
    );
  }

  openAddCorpModal() {
    this.modalService.show(AddCorpModalComponent, { ignoreBackdropClick: true });
  }

  setBreadcrumb() {
    this.breadcrumbService.set([]);
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  confirmAndDeleteCorp(corp: CorpModel) {
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.title = 'Delete Corp';
    modalRef.content.message = `Are you sure you want to delete corp ${corp.label}?`;
    modalRef.content.confirm.subscribe(async () => {
      if (await this.hierarchyElementsService.hasChildren(corp.id)) {
        this.toaster.error(`${corp.label} has children elements. Please delete its children before proceeding.`);
        return;
      }
      this.deleteCorp(corp);
    });
  }

  private async deleteCorp(corp: CorpModel) {
    try {
      await this.corpsService.removeCorp(corp.id);
      this.toaster.success('Corp deleted');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }
}
