import { Component, Input, OnInit } from '@angular/core';
import { MessageEvent } from './_types/MessageEvent';

@Component({
  selector: 'app-conversation-message',
  templateUrl: './conversation-message.component.html',
  styleUrls: ['./conversation-message.component.scss']
})
export class ConversationMessageComponent implements OnInit {
  @Input()
  message: MessageEvent;

  constructor() {}

  ngOnInit() {}
}
