import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { ApiQueryModel } from 'src/app/models/api-query';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { BotModel } from 'src/app/models/bot';
import { getBreadcrumbItems, getSidebarItems } from '../utils';
import { HierarchyElementModel } from 'src/app/models/hierarchy-element';
import { HierarchyElementsService } from 'src/app/services/firestore/hierarchy-elements.service';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { ApiQueriesService, BotsService, CorpsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-bot-api-query',
  templateUrl: './bot-api-query.component.html',
  styleUrls: ['./bot-api-query.component.scss'],
})
export class BotApiQueryComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  permissionToWriteApiQueries = Permissions.CAN_WRITE_BOT_API_QUERIES;

  corp: CorpModel;
  bot: BotModel;
  hierarchyElement: HierarchyElementModel;
  apiQuery: ApiQueryModel;

  constructor(
    private route: ActivatedRoute,
    private botsService: BotsService,
    private corpsService: CorpsService,
    private apiQueriesService: ApiQueriesService,
    private breadcrumbService: BreadcrumbService,
    private hierarchyElementsService: HierarchyElementsService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private router: Router,
    private authService: AuthService,
    private toaster: ToastrService,
    private clientEnvironmentService: ClientEnvironmentService,
  ) {}

  async ngOnInit() {
    this.paramMapSubscription = combineLatest([
      this.route.paramMap,
      this.authService.currentUser,
      this.clientEnvironmentService.items$,
    ]).subscribe(([params, user, envs]) => {
      const corpId = params.get('corp');
      const apiQuerySystemName = params.get('apiQuery');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      const botCode = params.get('bot');
      if (!corpId || !user || !apiQuerySystemName || !hierarchyElementSystemName || !botCode || !envs) {
        return;
      }

      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.apiQueriesService.getApiQueryBySystemName(apiQuerySystemName),
        this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
        this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
      ]).subscribe(
        async ([corp, apiQuery, bot, hierarchyElement]) => {
          if (!corp || !apiQuery || !bot || !hierarchyElement) {
            return;
          }

          this.corp = corp;
          this.bot = bot;
          this.hierarchyElement = hierarchyElement;

          if (!this.bot.apiGatewayServiceId) {
            this.toaster.info(
              `Sorry! ${this.bot.label} doesn't have an associated API ID. Please contact the administrator`,
            );
            this.router.navigate(['../'], { relativeTo: this.route });
            return;
          }

          this.apiQuery = apiQuery;

          this.refreshUI();
        },
        error => {
          this.toaster.error(error);
        },
      );
    });
  }

  async updateApiQuery(apiQuery: ApiQueryModel) {
    try {
      await this.apiQueriesService.updateApiQuery(apiQuery);
      this.toaster.success('Saved API Query');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private refreshUI() {
    this.headerService.setPageTitle(`${this.bot.label} API Queries`);
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'API Queries', 'api_queries'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
