import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models';
import { NodeModel } from '../models/node';

export interface IShopBotValidateScriptRequest {
  script: string;
}

export interface IShopBotValidateScriptResponse {
  isValid: boolean;
  stackTrace: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShopBotService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.shopbot.url;
  }
  validateScript(request: IShopBotValidateScriptRequest): Observable<IShopBotValidateScriptResponse> {
    const url = `${this.baseUrl}/javascript/validate`;
    return this.http.post<IShopBotValidateScriptResponse>(url, request);
  }
}
