import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { BotsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-bot-preview',
  templateUrl: './bot-preview.component.html',
  styleUrls: ['./bot-preview.component.scss'],
})
export class BotPreviewComponent implements OnInit, OnDestroy {
  corpId: string;
  hierarchySystemName: string;
  botCode: string;
  botEnv: string;
  backgroundUrl: string;
  botRootElementId: string;
  private paramMapSubscription: Subscription;
  private botsSubscription: Subscription;

  constructor(private route: ActivatedRoute, private botsService: BotsService) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.params, this.route.queryParams]).subscribe(
      ([params, queryParams]) => {
        const { corp, hierarchySystemName, bot: botCode } = params;

        if (!corp || !hierarchySystemName || !botCode) {
          return;
        }

        this.corpId = corp;
        this.hierarchySystemName = hierarchySystemName;
        this.botCode = botCode;

        if (queryParams?.background) {
          this.backgroundUrl = queryParams.background;
        }

        if (queryParams?.environment) {
          this.botEnv = queryParams.environment;
        }

        if (!this.botEnv) {
          return;
        }

        const botId = `${this.corpId}-${this.hierarchySystemName}-${this.botCode}_${this.botEnv}`;
        this.botsSubscription = this.botsService.getBotById(botId).subscribe(bot => {
          if (!bot) {
            return;
          }
          this.appendWebchatScript(bot.rootElementId, bot.id);
          this.setPageBackgroundImage();
        });
      },
    );
  }

  appendWebchatScript(rootElementId, botId) {
    const body = document.getElementsByTagName('body')[0];
    if (!body || !rootElementId || !botId) {
      return;
    }
    this.botRootElementId = rootElementId;

    const scriptCode = document.createElement('script');
    scriptCode.type = 'text/javascript';
    scriptCode.id = 'carlabs-bot-script';
    scriptCode.setAttribute('data-bot-id', botId);
    scriptCode.src = environment.botWebChatUrl;
    body.append(scriptCode);
  }

  setPageBackgroundImage() {
    const body = document.getElementsByTagName('body')[0];
    if (!this.backgroundUrl || !body) {
      return;
    }
    body.style.background = `url(${this.backgroundUrl}) no-repeat top left fixed`;
    body.style.backgroundSize = '2000px';
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.botsSubscription) {
      this.botsSubscription.unsubscribe();
    }
  }
}
