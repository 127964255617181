export const environment = {
  production: false,
  updateCampaign: {
    urlValidate: 'https://o4jtl12unc.execute-api.us-east-1.amazonaws.com/prod/validate',
    urlMoveToProcess: 'https://o4jtl12unc.execute-api.us-east-1.amazonaws.com/prod/process',
    getConversations: 'https://o4jtl12unc.execute-api.us-east-1.amazonaws.com/prod/dealership',
  },
  botConfig: {
    url: 'https://m9mf18b9v9.execute-api.us-east-1.amazonaws.com/dev/bot-configuration/',
    latestVersion: 4,
  },
  bach: {
    url: 'https://t8xi18jf31.execute-api.us-east-1.amazonaws.com/dev/',
  },
  // credentials for dev database
  firebase: {
    apiKey: 'AIzaSyAmwelCnYp0gOVOeHYL7BpIeDKKInRbMCA',
    authDomain: 'brain-ui-v1-dev.firebaseapp.com',
    databaseURL: 'https://brain-ui-v1-dev.firebaseio.com',
    projectId: 'brain-ui-v1-dev',
    storageBucket: 'brain-ui-v1-dev.appspot.com',
    messagingSenderId: '177764226367',
    appId: '1:177764226367:web:0894bc9cb5ab0c575fef8a',
    measurementId: 'G-9GR7LVPN4P',
  },

  es: {
    'us-east-1': {
      domain: 'https://logs.dev-es.car-labs.com/',
      accessKeyId: 'AKIATZ3SGK5WRBLWYDFG',
      secretAccessKey: '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',
    },
    'ap-south-1': {
      domain: 'https://logs.dev-es.car-labs.com/',
      accessKeyId: 'AKIATZ3SGK5WRBLWYDFG',
      secretAccessKey: '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',
    },
  },
  brain: {
    url: 'https://api.brain-dev.car-labs.com',
  },
  apiGatewayService: {
    url: 'https://api-dev.data.car-labs.com',
  },
  apiGatewayAnalytics: {
    url: 'https://ax6z2w9cqe.execute-api.us-east-1.amazonaws.com/',
  },
  botWebChatUrl: 'https://webchat.dev.car-labs.com/static/js/carlabs-bundle.js',
  nlpDojoUrl: 'https://nlp-dojo.dev.car-labs.com',
  nlpDojoS3Url: 'https://cl-nlp-dojo-dev.s3.amazonaws.com',
  jaiminhoEmailService: {
    url: 'https://g54z63ifcb.execute-api.us-east-1.amazonaws.com/dev/message',
  },
  shopbot: {
    url: 'https://shopbot.dev.car-labs.com',
  },
};
