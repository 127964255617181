export class CustomerSettingsModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  sms: string;
  inactive: boolean;
  channel: string;
  doNotContact: boolean;
  stopCurrentSeries: boolean;
}
