import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import {
  FlowTemplatesService,
  HierarchyElementsService,
  ApiQueriesService,
  VersionService,
  PermissionsService,
  CorpsService,
  BotsService,
  InputValidationsService,
  NodesService,
} from 'src/app/services/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowTemplateModel, BotModel, HierarchyElementModel, CorpModel, InputValidationModel } from 'src/app/models';
import { NodeModel } from 'src/app/models/node';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { Observable, of, from } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddFacebookPageModalComponent } from 'src/app/components/modals/add-facebook-page-modal/add-facebook-page-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
// tslint:disable-next-line: max-line-length
import { AddBotEventTriggerModalComponent } from 'src/app/components/modals/add-bot-event-trigger-modal/add-bot-event-trigger-modal.component';
import { sluggify } from 'src/app/utils/strings/string.utils';
import { flatMap, map, take } from 'rxjs/operators';
import { PermissionModel } from 'src/app/models/permission';
import { UploadService } from 'src/app/services/upload.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { HeaderService } from 'src/app/services/header.service';
import { getBreadcrumbItems, getSidebarItems } from 'src/app/pages/portal/hierarchy-element/utils';
import { GlobalVariable, GlobalVariableType } from 'src/app/models/bot';
import { TabsetComponent } from 'ngx-bootstrap/tabs/tabset.component';
import { CountryEnum } from '../../../models/countries/CountryEnum';
import { BotEnvironment } from 'src/app/models/client-environment';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { ApiQueryModel } from 'src/app/models/api-query';

@Component({
  selector: 'app-bot-use-template',
  templateUrl: './bot-use-template.component.html',
  styleUrls: ['./bot-use-template.component.scss'],
})
export class BotUseTemplateComponent implements OnInit {
  botTemplateId: string;
  corpId: string;
  hierarchyElementSystemName: string;
  botTemplate: FlowTemplateModel;
  isAdmin: boolean;

  loading: boolean;
  botAdded: EventEmitter<BotModel>;

  nodes: NodeModel[];
  avatarFile: File | null;
  nlpModels: NlpModel[] = [];
  countries: CountryEnum[] = CountryEnum.values();

  @Output()
  saved: EventEmitter<void>;

  @Input()
  bot: BotModel;

  @ViewChild('tab')
  tab: TabsetComponent;

  corp: CorpModel | null;
  hierarchySystemName: string;
  hierarchyElement: HierarchyElementModel | null;

  botVariables: GlobalVariable[] = [];
  apiQueries: ApiQueryModel[] = [];
  inputValidations: InputValidationModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private nlpDojoService: NlpDojoService,
    private permissionsService: PermissionsService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private authService: AuthService,
    private apiQueryService: ApiQueriesService,
    private uploadService: UploadService,
    private inputValidationsService: InputValidationsService,
    private nodesService: NodesService,
    private flowTemplatesService: FlowTemplatesService,
    private breadcrumbService: BreadcrumbService,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private hierarchyElementsService: HierarchyElementsService,
    private sidebarService: SidebarService,
    private router: Router,
    private headerService: HeaderService,
    private versionService: VersionService,
  ) {
    this.isAdmin = this.getIsAdmin();
  }

  ngOnInit() {
    this.nlpDojoService.getModels('default').subscribe(nlpModels => {
      this.nlpModels = nlpModels;
    });
    this.route.params.subscribe(value => {
      this.botTemplateId = value.templateId;
      this.corpId = value.corp;
      this.hierarchyElementSystemName = value.corp + '-' + value.hierarchyElementSystemName;
      this.maybeLoadBotTemplate();
    });
  }

  maybeLoadBotTemplate() {
    this.route.params.subscribe(value => {
      this.corpId = value.corp;
      this.corpsService.getCorpById(this.corpId).subscribe(corp => {
        this.corp = corp;
        const hierarchyElementSystemName = value.hierarchyElementSystemName;
        this.hierarchySystemName = hierarchyElementSystemName;
        this.hierarchyElementsService
          .getHierarchyElement(`${this.corp?.id}-${hierarchyElementSystemName}`)
          .subscribe(hierarchyElment => {
            this.hierarchyElement = hierarchyElment;
          });

        // load bot template and populate fields from bot template
        this.flowTemplatesService.getFlowTemplateBySystemName(this.botTemplateId).subscribe(async template => {
          if (template.length > 0) {
            this.botTemplate = template[0];

            const botConfig = this.botTemplate.getBotConfig;
            if (!botConfig) {
              return;
            }
            // because the botmodel object is built from json
            // typescript doesn't recognized the computed `id` function
            // we have to inject the id function
            Object.defineProperty(botConfig, 'id', {
              get() {
                return this.clientEnvironment
                  ? `${this.hierarchyElementSystemName}-${this.code}_${this.clientEnvironment}`
                  : `${this.hierarchyElementSystemName}-${this.code}`;
              },
              set(v) {
                // ignore, id is read-only
              },
            });

            this.bot = botConfig as BotModel;
            const currentUser = await this.authService.currentUser;
            if (!currentUser) {
              return;
            }
            // override custom field
            this.bot.createdBy = currentUser.uid;
            this.bot.corpId = this.corpId;
            this.bot.hierarchyElementSystemName = `${this.corp?.id}-${hierarchyElementSystemName}`;
            this.botVariables = [];
            if (this.bot.globalVariables && this.bot.globalVariables.length > 0) {
              this.bot.globalVariables.forEach(gv => {
                if (gv.type === GlobalVariableType.BOT) {
                  this.botVariables.push({ ...gv });
                }
              });
            }
            // reset bot name
            this.bot.label = '';
            this.bot.code = '';

            this.refreshUI();

            this.nodes = await this.flowTemplatesService
              .getFlowTemplatesNodes(this.botTemplate.systemName)
              .pipe(take(1))
              .toPromise();

            this.apiQueries = await this.flowTemplatesService
              .getFlowTemplatesApiQueries(this.botTemplate.systemName)
              .pipe(take(1))
              .toPromise();

            this.inputValidations = await this.flowTemplatesService
              .getFlowTemplateInputValidations(this.botTemplate.systemName)
              .pipe(take(1))
              .toPromise();
          }
        });
      });
    });
  }

  refreshUI() {
    if (this.corp && this.hierarchyElement) {
      this.setBreadcrumb(this.corp, this.hierarchyElement);
      this.setSidebarItems(this.corp.id, this.hierarchyElement);
    }
    this.headerService.setPageTitle(`${this.hierarchySystemName} Select Template`);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, 'Dashboard', 'dashboard'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement));
  }

  get botTemplateLabel(): string {
    if (this.botTemplate) {
      return this.botTemplate.label;
    }
    return '';
  }

  get hasGlobalVariables() {
    return this.botVariables.length > 0;
  }

  addEventTrigger() {
    if (!this.bot) {
      return;
    }
    if (!this.bot.eventTriggers) {
      this.bot.eventTriggers = [];
    }

    const modalRef = this.modalService.show(AddBotEventTriggerModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        bot: this.bot,
        nodes: this.nodes,
      },
    });

    modalRef.content.onClose.subscribe(result => {
      modalRef.hide();
      // const { eventTriggerInput, eventTriggerNode, eventTriggerType, eventTriggerId } = result.newEventTrigger;
      const {
        eventTriggerId,
        eventTriggerNodeId,
        eventTriggerNodeName,
        eventTriggerInput,
        eventTriggerType,
      } = result.newEventTrigger;

      this.bot.eventTriggers.push({
        eventTriggerId,
        eventTriggerInput,
        eventTriggerNodeId,
        eventTriggerNodeName,
        eventTriggerType,
      });
    });
  }

  removeBotTrigger(id: string) {
    if (!this.bot?.config) {
      return;
    }
    this.bot.eventTriggers = this.bot.eventTriggers.filter(trigger => trigger.eventTriggerId !== id);
  }

  addFacebookPageConfig() {
    if (!this.bot) {
      return;
    }
    if (!this.bot.facebookPageIds) {
      this.bot.facebookPageIds = [];
      this.bot.facebookPageConfigs = [];
    }

    const modalRef = this.modalService.show(AddFacebookPageModalComponent, { ignoreBackdropClick: true });
    modalRef.content.onClose.subscribe(result => {
      modalRef.hide();
      this.bot.facebookPageIds.push(result.pageId);
      this.bot.facebookPageConfigs.push({
        appId: result.appId,
        appSecret: result.appSecret,
        pageId: result.pageId,
        pageAccessToken: result.pageAccessToken,
        passThreadControlTargetAppId: result.passThreadControlTargetAppId,
      });
    });
  }

  removeFacebookPageConfig(id: string) {
    if (!this.bot?.config) {
      return;
    }
    this.bot.facebookPageIds = this.bot.facebookPageIds.filter(pageId => pageId !== id);
    this.bot.facebookPageConfigs = this.bot.facebookPageConfigs.filter(pageConfig => pageConfig.pageId !== id);
  }

  async save() {
    await this.addBot();
  }

  /**
   *  Add Bot to Config Store
   *  Takes the form submission and creates a single bot config.
   *  TODO: Extend to ENV versions when approach is decided.
   */

  private async addBot() {
    if (this.loading) {
      return;
    }
    if (!this.bot) {
      this.loading = false;
      return;
    }

    // validate bot name
    if (!this.bot.label || this.bot.label.trim().length === 0) {
      this.loading = false;
      this.toaster.error('Enter bot name');
      this.tab.tabs[0].active = true;
      return;
    }

    const code = sluggify(this.bot.label);
    this.bot.code = code;
    this.bot.config.type = 'live';
    this.bot.clientEnvironment = BotEnvironment.Development;

    const allVariables = this.bot.globalVariables.map(gv => {
      if (gv.type === GlobalVariableType.BOT) {
        // get value from the updated bot variables
        const updatedVariable = this.botVariables.filter(v => v.id === gv.id);
        if (updatedVariable.length > 0) {
          gv.defaultValue = updatedVariable[0].defaultValue;
        }
      }
      return gv;
    });
    this.bot.globalVariables = allVariables;

    this.loading = true;
    const uploadObservable: Observable<void | null> = this.getUploadObservable();
    uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.bot) {
            return of();
          }

          return from([
            await this.botsService.addBot(this.bot),
            await this.versionService.setInitialVersion(this.bot, this.nodes),
            await this.saveNodesThenSaveBot(this.nodes),
          ]);
        }),
      )
      .subscribe(
        async () => {
          await this.addPermission();
          this.toaster.success('Assistant created');
          const botCode = this.bot.code;
          this.loading = false;
          this.navigateToBot(botCode);
        },
        error => {
          this.loading = false;
          // should re-initialise for the next possible 'Save' action
          this.bot.code = '';
          this.bot.label = '';
          this.toaster.error(error);
        },
      );
  }

  /**
   * Save all nodes from bot template as new nodes for the new bot
   * @param nodes Nodes from bot template to be imported for the new bot
   */
  async saveNodesThenSaveBot(nodes: NodeModel[]): Promise<void> {
    const {
      nodes: nodesToCreate,
      apiQueries: apiQueriesToCreate,
      inputValidations: inputValidationsToCreate,
      bot,
    } = await this.flowTemplatesService.giveAllNodesNewIdsAndPropagateTheNewIdToAllUsages(
      nodes,
      this.apiQueries,
      this.inputValidations,
      this.botTemplate,
      this.bot,
    );

    await Promise.all(nodesToCreate.map(node => this.nodesService.addNode(node)));
    await Promise.all(
      apiQueriesToCreate.map(apiQuery => this.apiQueryService.addApiQuery({ ...apiQuery, botId: this.bot.id }, false)),
    );
    await Promise.all(
      inputValidationsToCreate.map(inputValidation =>
        this.inputValidationsService.addInputValidation({ ...inputValidation, corpId: this.bot.corpId }, false),
      ),
    );
    await this.botsService.updateBot(bot as BotModel);
  }

  navigateToBot(botCode: string) {
    this.router.navigate(['../../../bots', botCode], { relativeTo: this.route });
  }

  private async addPermission() {
    const permission = new PermissionModel();
    permission.userId = this.bot.createdBy;
    permission.hierarchyElementSystemName = this.bot.hierarchyElementSystemName;
    permission.corpId = this.bot.corpId;
    permission.botCode = this.bot.code;
    await this.permissionsService.addPermission(permission);
  }

  private getUploadObservable() {
    return this.avatarFile
      ? this.uploadService.upload('bots/' + this.bot.id + '/avatar', this.avatarFile).pipe(
          map(downloadUrl => {
            if (!this.bot) {
              return;
            }
            this.bot.styles.avatarImageUrl = downloadUrl;
          }),
        )
      : of(null);
  }

  private getIsAdmin(): boolean {
    return this.authService.hasPermissionSync(Permissions.IS_ADMIN);
  }
}
