import { Component, OnInit } from '@angular/core';
import { BreadcrumbNodeVM } from './_types/BreadcrumbNodeVM';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BreadcrumbRootVM } from './_types/BreadcrumbRootVM';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  nodes: BreadcrumbNodeVM[];
  root: BreadcrumbRootVM;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {
    this.nodes = [];
  }

  ngOnInit() {
    this.breadcrumbService.nodes$.subscribe(nodes => (this.nodes = nodes));
    this.breadcrumbService.root$.subscribe(root => (this.root = root));
  }

  routeToRoot() {
    this.routeToLevel(0);
  }

  routeToLevel(level: number) {
    if (level === 0) {
      this.router.navigate([this.root ? this.root.route : '.']);
      return;
    }
    const route = this.nodes[level - 1].route;
    this.router.navigate([route]);
  }
}
