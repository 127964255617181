import { Component, OnInit, ViewChild } from '@angular/core';
import { UserModel, RoleModel } from 'src/app/models';
import { UsersService } from 'src/app/services/firestore';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent implements OnInit {
  user: UserModel;
  loading: boolean;
  canWriteUsers: boolean;
  roles: RoleModel[];

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(private usersService: UsersService, public modal: BsModalRef, private toaster: ToastrService) {
    this.user = new UserModel();
    this.loading = false;
  }

  ngOnInit() {}

  async save() {
    this.actionProgress.start();
    this.loading = true;
    try {
      await this.usersService.updateUser(this.user);
      this.modal.hide();
      this.toaster.success('User updated');
    } catch (error) {
      this.toaster.error(error);
    }
    this.actionProgress.start();
    this.loading = false;
  }
}
