import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, Action, DocumentSnapshot } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';

@Injectable({
  providedIn: 'root',
})
export class NlpService {
  private nlpModelCollection: AngularFirestoreCollection<NlpModel>;

  constructor(private afs: AngularFirestore) {
    this.nlpModelCollection = afs.collection('nlp').doc('default').collection<NlpModel>('models');
  }

  nlpModelOnChange(nlpModelId: string): Observable<Action<DocumentSnapshot<unknown>>> {
    return this.nlpModelCollection.doc(nlpModelId).snapshotChanges();
  }

  getNlpModel(id: string): Observable<NlpModel | null> {
    return this.nlpModelCollection
      .doc(id)
      .valueChanges()
      .pipe(
        map(nlpModel => {
          return nlpModel as NlpModel;
        }),
      );
  }
}
