import { Component, OnDestroy, OnInit } from '@angular/core';
import { BotModel } from 'src/app/models/bot';
import { combineLatest, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HierarchyElementModel } from 'src/app/models';
import { BotsService, HierarchyElementsService } from 'src/app/services/firestore';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';

@Component({
  selector: 'app-bot-admin',
  templateUrl: './bot-admin.component.html',
  styleUrls: ['./bot-admin.component.scss'],
})
export class BotAdminComponent implements OnInit, OnDestroy {
  corpId: string;
  hierarchyElement: HierarchyElementModel | null;
  bot: BotModel | null;
  loading: boolean;

  private paramMapSubscription: Subscription;
  private dataSubscription: Subscription;
  private clientEnvironmentService: ClientEnvironmentService;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private botsService: BotsService,
    private hierarchyElementsService: HierarchyElementsService,
    private toaster: ToastrService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.clientEnvironmentService.items$]).subscribe(
      ([params, envs]) => {
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        const botCode = params.get('bot');

        if (!corpId || !hierarchyElementSystemName || !botCode || !envs) {
          return;
        }
        this.loading = true;
        this.dataSubscription = combineLatest([
          this.hierarchyElementsService.getHierarchyElement(hierarchyElementSystemName),
          this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
        ]).subscribe(
          ([hierarchyElement, bot]) => {
            this.loading = false;
            this.hierarchyElement = hierarchyElement;
            this.bot = bot;
            this.corpId = corpId;
            this.refreshUI();
          },
          error => {
            this.toaster.error(error);
            this.loading = false;
          },
        );
      },
    );
  }

  navigateToParentScreen() {
    this.router.navigate([this.getParentUrl()]);
  }

  private getParentUrl() {
    // tslint:disable-next-line:max-line-length
    return `admin/corps/${this.corpId}/hierarchies/${this.hierarchyElement?.hierarchyName}/hierarchy-el/${this.hierarchyElement?.systemNameForUrl}/bots`;
  }

  refreshUI() {
    if (this.corpId && this.hierarchyElement && this.bot) {
      this.setBreadcrumb(this.corpId, this.hierarchyElement, this.bot);
      this.headerService.setPageTitle(`${this.bot.label} Admin`);
    }
  }

  setBreadcrumb(corpId: string, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set([
      {
        label: 'Corporations',
        route: `admin/corps`,
      },
      {
        label: hierarchyElement.label,
        icon: hierarchyElement.logo,
        route: this.getParentUrl(),
      },
    ]);
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  save() {
    if (!this.bot) {
      return;
    }
    this.botsService
      .updateBot(this.bot)
      .then(() => {
        this.loading = false;
        this.refreshUI();
        this.toaster.success('Assistant updated');
      })
      .catch(error => {
        this.loading = false;
        this.toaster.error(error);
      });
  }
}
