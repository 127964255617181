import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CorpHierarchyModel, CorpModel, HierarchyElementModel, UserModel } from 'src/app/models';
import { UserAssociation } from './_types/UserAssociation';
import { PermissionModel } from 'src/app/models/permission';
import uniq from 'lodash/fp/uniq';
import get from 'lodash/fp/get';
import { BotModel } from 'src/app/models/bot';
import { combineLatest, Observable } from 'rxjs';
import {
  BotsService,
  CorpHierarchiesService,
  HierarchyElementsService,
  PermissionsService,
  UsersService,
} from 'src/app/services/firestore';
import { take } from 'rxjs/operators';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { AuthService } from '../../../../../services/auth.service';

interface IUserAssociationGrouping {
  title: string;
  inDepthTitle: string;
  singleTitle: string;
  isBot: boolean;
  childrenName: string;
  associations: UserAssociation[];
}

@Component({
  selector: 'app-manage-user-associations-modal',
  templateUrl: './manage-user-associations-modal.component.html',
  styleUrls: ['./manage-user-associations-modal.component.scss'],
})
export class ManageUserAssociationsComponent implements OnInit {
  user: UserModel;
  corps: CorpModel[] = [];
  canWriteUsers: boolean;
  allBots: BotModel[] = [];
  loading: boolean;
  permissions: PermissionModel[] = [];
  currentCorp: CorpModel | null;
  private corpHierarchiesBag: { [key: string]: CorpHierarchyModel[] };
  userId: string;
  hasAllCorps: boolean;

  userAssociationsGroupings: IUserAssociationGrouping[] = [];

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private corpHierarchiesService: CorpHierarchiesService,
    private botsService: BotsService,
    private hierarchyElementsService: HierarchyElementsService,
    public modal: BsModalRef,
    private toaster: ToastrService,
    private usersService: UsersService,
    options: ModalOptions,
  ) {
    this.user = new UserModel();
    this.loading = false;
    if (!options.initialState) {
      return;
    }
    const { user, corps, canWriteUsers } = options.initialState as any;
    this.user = user;
    this.corps = corps;
    this.canWriteUsers = canWriteUsers;
  }

  async ngOnInit() {
    this.loading = true;
    const allCorpIds = this.corps.map(({ id }) => id);
    this.userId = (await this.authService.currentUser)?.uid || '';
    this.hasAllCorps = this.userId.length > 0 && (await this.usersService.CanAccessAllCompanies(this.userId));

    const corpHierarchiesRequest = allCorpIds.map(corpId => this.corpHierarchiesService.getCorpHierarchies(corpId));
    try {
      let [permissions, ...corpHierarchies] = await combineLatest([
        this.permissionsService.getPermissionsBy(this.user.id),
        ...corpHierarchiesRequest,
      ])
        .pipe(take(1))
        .toPromise();

      permissions = permissions as PermissionModel[];
      corpHierarchies = corpHierarchies as CorpHierarchyModel[][];
      this.permissions = permissions.filter(permission => !get('countryCode', permission));

      this.corpHierarchiesBag = corpHierarchies.reduce((corpHierarchiesBag, corpHierarchies$1, index) => {
        corpHierarchiesBag[allCorpIds[index]] = corpHierarchies$1;
        return corpHierarchiesBag;
      }, {});

      const corpIdsThatIAmAssociatedWith = uniq(
        this.permissions
          .filter(permission => this.hasAllCorps || !(permission.botCode || permission.hierarchyElementSystemName))
          .map(permission => permission.corpId),
      );

      this.userAssociationsGroupings = [
        {
          title: 'Corporations',
          inDepthTitle: 'Corporations',
          singleTitle: 'Corporation',
          isBot: false,
          childrenName: 'Children',
          associations: this.corps.map(
            corp =>
              new UserAssociation(corpIdsThatIAmAssociatedWith, {
                corp,
                corpHierarchies: this.corpHierarchiesBag[corp.id],
              }),
          ),
        },
      ];
    } catch (error) {
      this.toaster.error(error);
    }
    this.loading = false;
  }

  // TODO split up this gigantic method
  async showChildren(userAssociation: UserAssociation, userAssociationsGroupingIndex: number) {
    const { corp } = userAssociation;
    const corpHierarchies = this.corpHierarchiesBag[userAssociation.corp.id];
    const parent = userAssociation.systemName;

    const corpHierarchy = corpHierarchies[userAssociationsGroupingIndex];

    let title = 'Assistants';
    let singleTitle = 'Assistant';
    let isBot = true;
    let childrenName = '';
    if (corpHierarchy) {
      title = corpHierarchy.label;
      singleTitle = corpHierarchy.singular;
      isBot = false;
      childrenName = 'Assistants';
      const nextCorpHierarchy = corpHierarchies[userAssociationsGroupingIndex + 1];
      if (nextCorpHierarchy) {
        childrenName = nextCorpHierarchy.label;
      }
    }

    this.loading = true;

    const associationsElementSubscription: Observable<Array<BotModel | HierarchyElementModel>> = isBot
      ? this.botsService.getBotsByHierarchyElement(parent)
      : this.hierarchyElementsService.getHierarchyChildrenElements(parent);

    const associationsElement = await associationsElementSubscription.pipe(take(1)).toPromise();

    this.loading = false;

    const idsThatIAmAssociatedWith = isBot
      ? this.getBotsIdsIAmAssociatedWith(corp.id, parent)
      : this.getHierarchyElementIdsIAmAssociatedWith(corp.id);

    const userAssociationsGroupings = [...this.userAssociationsGroupings];

    const userAssociationsGroupingsToShow = userAssociationsGroupings.slice(0, userAssociationsGroupingIndex + 1);

    this.processAssociationIsSelected(userAssociation, userAssociationsGroupingIndex);

    const inDepthTitle = this.getInDepthTitle(userAssociationsGroupingsToShow);

    this.userAssociationsGroupings = [
      ...userAssociationsGroupingsToShow,
      {
        inDepthTitle: `${title} ${inDepthTitle}`,
        title,
        singleTitle,
        isBot,
        childrenName,
        associations: associationsElement.map(
          associationsElement$1 =>
            new UserAssociation(idsThatIAmAssociatedWith, {
              corp,
              corpHierarchies,
              bot: isBot ? (associationsElement$1 as BotModel) : null,
              hierarchyElement: isBot ? null : (associationsElement$1 as HierarchyElementModel),
              childrenName,
            }),
        ),
      },
    ];
  }

  private processAssociationIsSelected(userAssociation: UserAssociation, userAssociationsGroupingIndex: number): void {
    this.userAssociationsGroupings[userAssociationsGroupingIndex].associations.forEach(association => {
      association.isSelected = false;
    });

    userAssociation.isSelected = true;
  }

  private getInDepthTitle(userAssociationsGroupingsToShow: IUserAssociationGrouping[]): string {
    const depthTitle = userAssociationsGroupingsToShow
      .map(({ associations }) => {
        const selectedAssociation = associations.find(({ isSelected }) => isSelected);
        if (!selectedAssociation) {
          return '';
        }
        return selectedAssociation.label;
      })
      .join(' | ');

    return depthTitle;
  }

  private getHierarchyElementIdsIAmAssociatedWith(corpId: string): string[] {
    return uniq(
      this.permissions.filter(
        permission => this.hasAllCorps || (permission.corpId === corpId && permission.hierarchyElementSystemName),
      ),
    ).map(({ hierarchyElementSystemName }) => `${hierarchyElementSystemName}`);
  }

  private getBotsIdsIAmAssociatedWith(corpId: string, hierarchyElementSystemName: string): string[] {
    return uniq(
      this.permissions.filter(
        permission =>
          this.hasAllCorps ||
          (permission.corpId === corpId &&
            permission.hierarchyElementSystemName === hierarchyElementSystemName &&
            permission.botCode),
      ),
    ).map(({ botCode }) => `${botCode}`);
  }

  async toggleAssociation(userAssociation: UserAssociation) {
    if (!this.canWriteUsers) {
      return false;
    }
    userAssociation.toogleAssociation();
    const permission = new PermissionModel();
    permission.userId = this.user.id;
    permission.corpId = userAssociation.corp.id;
    if (userAssociation.hierarchyElement) {
      permission.hierarchyElementSystemName = userAssociation.hierarchyElement.systemName;
    }
    if (userAssociation.bot) {
      permission.hierarchyElementSystemName = userAssociation.bot.hierarchyElementSystemName;
      permission.botCode = userAssociation.bot.code;
    }

    this.actionProgress.start();
    if (userAssociation.isMember) {
      await this.addAssociation(permission);
    } else {
      await this.removeAssociation(permission);
    }
    this.actionProgress.complete();
  }

  private async addAssociation(permissionModel: PermissionModel): Promise<void> {
    // TODO: Add up the line
    try {
      await this.permissionsService.addPermission(permissionModel);
      this.toastSuccess();
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private async removeAssociation(permissionModel: PermissionModel): Promise<void> {
    // TODO: Remove down the line
    try {
      // await this.permissionsService.deleteAllUserCorpPermission(this.user.id, userAssociation.corp.id);
      await this.permissionsService.removePermission(permissionModel.id);
      this.toastSuccess();
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private toastSuccess() {
    this.toaster.success('User Association Updated Successfully');
  }
}
