import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';
import { HlCustomerSettingsModalComponent } from '../../../human-in-the-loop/hl-customer-settings-modal/hl-customer-settings-modal.component';
import { UserModel } from 'src/app/models';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BachService } from 'src/app/services/bach.service';
import { BotConfigService } from 'src/app/services/bot-config.service';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerSettingsModel } from 'src/app/models/CustomerSettingsModel';

@Component({
  selector: 'app-user-recent-conversations',
  templateUrl: './recent-conversations.component.html',
  styleUrls: ['./recent-conversations.component.scss'],
})
export class RecentConversationsComponent implements OnInit, OnChanges {
  constructor(
    private apiGatewayAnalyticsService: ApiGatewayAnalyticsService,
    public humanInLoopService: HumanInLoopService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
    private authService: AuthService,
    private toaster: ToastrService,
    private bachService: BachService,
    private botConfigService: BotConfigService,
    private router: Router,
    private domSanitizer: DomSanitizer,
  ) {
    this.currentRoute = this.router.url;
  }

  bot: any;
  reportDetails: any;
  displayDetails: any;
  paginationId: number;
  hasMore: boolean;
  isLoading: boolean;
  currentUser: UserModel;
  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();
  @Input() reportType = 'T';
  @Input() showReport = false;
  selectedSettingsSubscription: Subscription;
  selectedConversationId: string;
  latestVersion = false;
  currentRoute = '';
  settings = {
    noDataMessage: 'No records found.',
    hideSubHeader: true,
    attr: {
      class: 'table',
    },
    pager: {
      display: true,
      perPage: 25,
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      createdAt: {
        title: 'Date',
        filter: false,
        valuePrepareFunction: value => {
          return new Date(value).toLocaleString();
        },
      },
      fullName: {
        title: 'Customer Name',
        filter: false,
        type: 'html',
        valuePrepareFunction: (value, row) => {
          if (row.click) {
            return this.domSanitizer.bypassSecurityTrustHtml(
              `<div style="color: blue;"><strong>${value}</strong></div>`,
            );
          } else {
            return this.domSanitizer.bypassSecurityTrustHtml(`<div><strong>${value}</strong></div>`);
          }
        },
      },
      email: {
        title: 'Email',
        filter: false,
      },
      phone: {
        title: 'Telephone',
        filter: false,
        valuePrepareFunction: value => {
          return this.formatPhoneNumber(value);
        },
      },
      vehicle: {
        title: 'Vehicle',
        filter: false,
      },
    },
  };

  onSearch(query: string = '') {
    if (!query || query === '') {
      this.displayDetails.reset();
    } else {
      this.displayDetails.setFilter(
        [
          // fields we want to include in the search
          {
            field: 'fullName',
            search: query,
          },
          {
            field: 'email',
            search: query,
          },
          {
            field: 'phone',
            search: query,
          },
          {
            field: 'vehicle',
            search: query,
          },
        ],
        false,
      );
    }
  }

  async ngOnInit() {
    this.isLoading = true;
    const user = await this.authService.getCurrentUserProfile();
    if (!user) {
      throw new Error('No user profile found');
    }
    this.currentUser = user;
    if (this.showReport) {
      await this.getReportDetails();
    } else {
      this.reportDetails = [];
    }
    this.isLoading = false;
    if (this.botId) {
      this.bot = await this.botConfigService.getBotConfigById(this.botId);
      this.latestVersion = true;
    }
    this.settings.columns.createdAt.title = this.reportType === 'C' ? 'Date' : 'Engagement Date';
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
    this.settings.columns.createdAt.title = this.reportType === 'C' ? 'Date' : 'Engagement Date';
  }

  formatPhoneNumber(value: string) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match1) {
      return '(' + match1[1] + ') ' + match1[2] + '-' + match1[3];
    }

    const match2 = cleaned.match(/^(\d{3})(\d{4})$/);

    if (match2) {
      return match2[1] + '-' + match2[2];
    }

    const match3 = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

    if (match3) {
      return '(' + match3[2] + ') ' + match3[3] + '-' + match3[4];
    }

    return value;
  }

  saveSessionParameters(event) {
    try {
      const storage = window.localStorage;
      if (storage) {
        const value = {
          startDate: this.startDate,
          endDate: this.endDate,
          reportType: this.reportType,
          // conversationId: event.data.conversation_id,
          filters: [],
          sortby: '',
        };
        storage.setItem('dashboardParameters', JSON.stringify(value));
      }
    } catch (err) {
      console.error('Failed storing messages', err);
    }
  }

  async getReportDetails() {
    this.hasMore = false;
    try {
      const response: AnalyticsDashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
        this.botId,
        this.startDate,
        this.endDate,
        this.reportType,
      );

      this.reportDetails = [];
      if (response.tables.report.details != null) {
        let result = await Promise.all(
          response.tables.report.details.map(async details => {
            const id = 'db_conversation_id';
            const last_updated = 'db_last_updated';
            const fullName = 'full_name';
            const email = 'email';
            const vehicle = 'vehicle';
            const phone = 'phone';
            const click = 'first_engagement_occurred';
            const name: string = details[fullName];
            return {
              email: details[email] !== '' ? details[email] : 'N/A',
              fullName: name && name.trim() !== '' ? name : 'N/A',
              phone: details[phone] ? details[phone] : 'N/A',
              vehicle: details[vehicle] ? details[vehicle] : 'N/A',
              createdAt: new Date(details[last_updated]).getTime(),
              conversation_id: details[id],
              click: details[click],
            };
          }),
        );
        result = result.sort((a, b) => b.createdAt - a.createdAt);
        this.reportDetails = result;
      }
      this.displayDetails = new LocalDataSource(this.reportDetails);
    } catch (error) {
      console.log(error);
      this.toaster.error(error);
    }
  }

  async selectRow(event) {
    if (event.data.click === true) {
      this.saveSessionParameters(event);
      const path = this.currentRoute.replace('dashboard', 'human-in-the-loop/' + event.data.conversation_id);
      this.router.navigateByUrl(path);
    } else {
      await this.selectCustomer(event);
    }
  }

  async selectCustomer(event) {
    if (!event.data.conversation_id) {
      return;
    }

    let fullName = '';
    let email = '';
    let vehicle = '';

    if (event.data) {
      fullName = event.data.fullName ? event.data.fullName : '';
      email = event.data.email ? event.data.email : '';
      vehicle = event.data.vehicle ? event.data.vehicle : '';
    }

    if (!this.currentUser) {
      const user = await this.authService.getCurrentUserProfile();
      if (user) {
        this.currentUser = user;
      }
    }
    if (!this.currentUser) {
      return;
    }
    const userByConvo = await this.bachService.getUserByConversationId(event.data.conversation_id);
    if (userByConvo) {
      const modalRef = this.modalService.show(HlCustomerSettingsModalComponent, {
        initialState: {
          conversation_id: event.data.conversation_id,
          user: userByConvo.user,
          bot: this.bot,
        },
      });

      if (this.selectedSettingsSubscription) {
        this.selectedSettingsSubscription.unsubscribe();
      }

      this.selectedSettingsSubscription = modalRef.content.selectedSettings.subscribe(
        ({ newCustomerSettings, originalCustomerSettings }) => {
          this.updateCustomerSettings(
            userByConvo.user['_id'],
            newCustomerSettings,
            originalCustomerSettings,
            modalRef,
            email,
            event.data.conversation_id,
          );
        },
      );
    }
  }

  async updateCustomerSettings(
    userId: string,
    newCustomerSettings: CustomerSettingsModel,
    originalCustomerSettings: CustomerSettingsModel,
    modalRef: BsModalRef,
    email: string,
    conversationId: string,
  ) {
    if (conversationId) {
      const botId = this.botId;
      const corpId = this.botId.split('-')[0];
      const botConfig = await this.botConfigService.getBotConfigById(botId);

      try {
        let wait = false;
        if (newCustomerSettings.doNotContact != originalCustomerSettings.doNotContact && email) {
          const key = 'code';
          const result = await this.bachService.setDoNotContact(
            botConfig[key],
            conversationId,
            newCustomerSettings.doNotContact,
            botConfig['botType'] === 'SALES',
          );
          this.toasterService.success(
            newCustomerSettings.doNotContact
              ? `Customer record set to "Do Not Contact".`
              : `Customer "Do Not Contact" setting removed.`,
          );
          if (!newCustomerSettings.stopCurrentSeries && !originalCustomerSettings.stopCurrentSeries) {
            newCustomerSettings.stopCurrentSeries = true;
          }
          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            newCustomerSettings.doNotContact ? 'DNC Customer' : 'DNC setting removed.',
            this.currentUser.fullName,
          );

          wait = true;
        }

        if (newCustomerSettings.stopCurrentSeries && !originalCustomerSettings.stopCurrentSeries) {
          if (wait) {
            setTimeout(() => {
              console.log('Waiting for the DNC update to finish.');
            }, 500);
          }
          await this.bachService.stopCurrentSeries(conversationId, newCustomerSettings.stopCurrentSeries);
          this.toasterService.success('The selected conversation was stopped.');
          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            'Stopped Current Series',
            this.currentUser.fullName,
          );
        }

        if (newCustomerSettings.inactive !== originalCustomerSettings.inactive) {
          if (wait) {
            setTimeout(() => {
              console.log('Waiting for the previous update to finish.');
            }, 500);
          }
          await this.bachService.setVehicleInactive(conversationId, newCustomerSettings.inactive);
          if (newCustomerSettings.inactive) this.toasterService.success('The selected vehicle was updated - inactive.');
          else this.toasterService.success('The selected vehicle was updated - active.');

          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            newCustomerSettings.inactive ? 'Customer sold vehicle.' : 'Customer vehicle reinstated.',
            this.currentUser.fullName,
          );

          wait = true;
        }

        if (
          newCustomerSettings.firstName !== originalCustomerSettings.firstName ||
          newCustomerSettings.lastName !== originalCustomerSettings.lastName ||
          newCustomerSettings.sms !== originalCustomerSettings.sms ||
          newCustomerSettings.phone !== originalCustomerSettings.phone ||
          newCustomerSettings.email !== originalCustomerSettings.email ||
          newCustomerSettings.channel !== originalCustomerSettings.channel
        ) {
          if (wait) {
            setTimeout(() => {
              console.log('Waiting for the previous update to finish.');
            }, 500);
          }
          await this.bachService.updateCustomerSettings(userId, newCustomerSettings);

          if (newCustomerSettings.firstName !== originalCustomerSettings.firstName)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `First name changed from ${originalCustomerSettings.firstName} to ${newCustomerSettings.firstName} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.lastName !== originalCustomerSettings.lastName)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `Last name changed from ${originalCustomerSettings.lastName} to ${newCustomerSettings.lastName} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.email !== originalCustomerSettings.email)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `SMS changed from ${originalCustomerSettings.email} to ${newCustomerSettings.email} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.sms !== originalCustomerSettings.sms)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `SMS changed from ${originalCustomerSettings.sms} to ${newCustomerSettings.sms} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.phone !== originalCustomerSettings.phone)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `Phone changed from ${originalCustomerSettings.phone} to ${newCustomerSettings.phone} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.channel !== originalCustomerSettings.channel)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `Preferred conctact changed from ${originalCustomerSettings.channel} to ${newCustomerSettings.channel} .`,
              this.currentUser.fullName,
            );
        }

        await this.humanInLoopService.touchConversation(corpId, conversationId);

        modalRef.hide();
        return this.toasterService.success('Customer records were updated successfully.');
      } catch (error) {
        modalRef.hide();
        return this.toasterService.error('Could update customer records.');
      }
    }

    this.toasterService.error('Something went wrong... please try again later.');
  }
}
