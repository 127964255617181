import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { HumanInLoopService } from '../../../services/firestore/human-in-loop.service';
import { CustomerSettingsModel } from '../../../models/CustomerSettingsModel';
import { ToastrService } from 'ngx-toastr';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-hl-customer-settings-modal',
  templateUrl: './hl-customer-settings-modal.component.html',
  styleUrls: ['./hl-customer-settings-modal.component.scss'],
})
export class HlCustomerSettingsModalComponent implements OnInit {
  customer: CustomerSettingsModel = new CustomerSettingsModel();
  originalCustomer: CustomerSettingsModel;
  conversation_id: string;
  vehicle: any;
  campaign: any;
  channels = ['EMAIL', 'SMS'];
  user: any;
  stopSeriesDisabled: boolean = true;
  errors: string = '';
  botChannel: string;
  bot: any;
  header1 = '';
  header2 = '';
  internalUser = false;
  isLoading = false;
  selectedSettings: EventEmitter<{
    newCustomerSettings: CustomerSettingsModel;
    originalCustomerSettings: CustomerSettingsModel;
  }>;

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private toaster: ToastrService,
    public humanInLoopService: HumanInLoopService,
    private authService: AuthService,
  ) {
    const { conversation_id, user, bot } = options.initialState as any;
    this.selectedSettings = new EventEmitter<{
      newCustomerSettings: CustomerSettingsModel;
      originalCustomerSettings: CustomerSettingsModel;
    }>();
    this.bot = bot;
    this.user = user;
    this.conversation_id = conversation_id;

    if (this.user) {
      this.campaign = this.user.campaignProgresses.find(c => c.conversationId === this.conversation_id);
      this.vehicle = this.user.vehicles
        ? this.user.vehicles.find(v => this.campaign && this.campaign.vehicleId === v.vin)
        : undefined;

      this.customer.firstName = get(this.user, 'firstName', '');
      this.customer.lastName = get(this.user, 'lastName', '');
      this.customer.email = get(this.user, 'email.primary', '');
      this.customer.phone = get(this.user, 'phone', '');
      this.customer.sms = get(this.user, 'sms', '');
      this.customer.inactive =
        this.vehicle && this.vehicle.inactive !== null ? get(this.vehicle, 'inactive', false) : false;
      this.customer.channel = get(this.user, 'preferredContact', 'EMAIL');
      this.customer.doNotContact = get(this.user, 'doNotContact.ever', false);
      this.stopSeriesDisabled = get(this.campaign.phase, 'step', '') === 'COMPLETED';
      this.customer.stopCurrentSeries = false;
      this.originalCustomer = JSON.parse(JSON.stringify(this.customer));
      let vehicle = '';

      if (this.vehicle && bot.botType !== 'SALES') {
        vehicle = `${this.vehicle.year || ''} ${this.vehicle.make || ''} ${this.vehicle.model || ''}`;
        vehicle = `${vehicle} - ${this.vehicle.vin}`;
      }

      this.header1 = vehicle ? vehicle.trim() : '';
      this.header2 = '';
    }

    this.conversation_id = conversation_id;
  }

  ngOnInit() {
    this.authService.currentUser.then(currentUser => {
      if (currentUser && currentUser.email) {
        this.internalUser =
          currentUser.email.toLowerCase().indexOf('@carlabs.') > 0 ||
          currentUser.email.toLowerCase().indexOf('@impel.') > 0;
        if (this.internalUser) {
          this.header2 = this.header1 ? `Conversation Id: ${this.conversation_id}` : '';
          this.header1 = this.header1 || `Conversation Id: ${this.conversation_id}`;
        }
      }
    });
  }

  setChannel(e: any) {
    this.customer.channel = e && e.target && e.target.value ? e.target.value : this.customer.channel;
  }

  save() {
    this.errors = '';

    if (!this.customer.firstName || this.customer.firstName.trim().length < 2) {
      this.errors = 'First name must be at least 2 characters.';
      return false;
    }

    if (!this.customer.lastName || this.customer.lastName.trim().length < 2) {
      this.errors = 'First name must be at least 2 characters.';
      return false;
    }

    if (JSON.stringify(this.customer) !== JSON.stringify(this.originalCustomer)) {
      this.isLoading = true;
      this.selectedSettings.emit({
        newCustomerSettings: this.customer,
        originalCustomerSettings: this.originalCustomer,
      });
    } else {
      this.toaster.error('No changes found.');
    }
  }
}
