import { Component, ComponentFactoryResolver, Injector } from '@angular/core';
import { VisualTemplateBase } from '../visual-template-base';
import { VisualTemplateEditorButtonComponent } from '../button/visual-template-editor-button.component';

@Component({
  selector: 'app-visual-template-editor-suggested-actions',
  templateUrl: './visual-template-editor-suggested-actions.component.html',
  styleUrls: ['./visual-template-editor-suggested-actions.component.scss']
})
export class VisualTemplateEditorSuggestedActionsComponent extends VisualTemplateBase {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public injector: Injector) {
    super(componentFactoryResolver, injector);
  }

  protected availableElements = [
    {
      name: 'button',
      component: VisualTemplateEditorButtonComponent
    }
  ];
}
