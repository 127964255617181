import { Timestamp } from 'firebase-tools';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

export class InputValidationModel {
  id: string;
  name: string;
  systemName: string;
  type: string;
  value: string;
  exampleData: string;
  corpId: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  static generateDefault(): InputValidationModel {
    const inputValidation = new InputValidationModel();
    InputValidationModel.generateId(inputValidation);
    inputValidation.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    inputValidation.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    inputValidation.type = '';
    inputValidation.exampleData = '';
    inputValidation.value = '';
    return inputValidation;
  }

  static generateId(inputValidation: InputValidationModel): string {
    inputValidation.id = uuidv4();
    return inputValidation.id;
  }

  static generateUpdatedAt(inputValidation: InputValidationModel): string {
    inputValidation.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return inputValidation.updatedAt;
  }

  static generateSystemName(inputValidation: InputValidationModel): string {
    inputValidation.systemName = inputValidation.name
      .toLowerCase()
      .trim()
      .replace(' ', '-');
    return inputValidation.systemName;
  }
}
