import { Component, Input, OnInit } from '@angular/core';
import { SimpleCardItem } from './_types/SimpleCardItem';
import { Router } from '@angular/router';
import { SimpleCardItemButton } from './_types/SimpleCardItemButton';

@Component({
  selector: 'app-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.scss']
})
export class SimpleCardComponent implements OnInit {
  @Input()
  cardItem: SimpleCardItem;

  constructor(private router: Router) {}

  ngOnInit() {}

  executeButtonActions(button: SimpleCardItemButton) {
    if (button.eventEmitter) {
      button.eventEmitter.emit(this.cardItem);
    }
    if (button.link) {
      this.router.navigate(button.link);
    }
  }
}
