import { BotModel } from './bot';

export class VersionModel {
  versionNumber: number;
  versionDescription: string;
  createdAt: string;
  isCurrent: boolean;
  isLatest: boolean;
  createdBy: {
    userName?: string;
    userId?: string;
  };
  bot: BotModel;
  versionHistory: number[] = [];

  static getLatest(versions: VersionModel[]): VersionModel | undefined {
    return versions.find(({ isLatest }) => isLatest);
  }

  static getCurrent(versions: VersionModel[]): VersionModel | undefined {
    return versions.find(({ isCurrent }) => isCurrent);
  }
}
