import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { getHomeRoute } from 'src/app/utils/routing/routing';
import { ToastrService } from 'ngx-toastr';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { Subscription } from 'rxjs';
import { PermissionsService, UsersService } from 'src/app/services/firestore';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  mode = 'login';

  loginForm = this.fb.group({
    email: [null, [Validators.required]],
    password: [null, [Validators.required]],
  });

  loading = false;

  userSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private permissionsService: PermissionsService,
    private usersService: UsersService,
    private toaster: ToastrService,
    private fb: FormBuilder,
  ) {}

  //
  // Control Block for Login type routing
  // Password Reset is not wired in the service and method is disabled but ready.
  //
  formSubmit() {
    switch (this.mode) {
      case 'login':
        return this.login();
      case 'passwordReset':
        return this.passwordReset();
    }
  }

  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService
        .login(this.loginForm.value.email, this.loginForm.value.password)
        .then(authenticationResponse => {
          this.userSubscription = this.usersService.getUserById(authenticationResponse.user.uid).subscribe(
            async user => {
              try {
                const isAdmin = await this.authService.hasPermission(Permissions.IS_ADMIN);
                const homeRoute =
                  user && isAdmin ? '/admin' : await getHomeRoute(this.authService, this.permissionsService);

                this.router.navigate([homeRoute]);
              } catch (e) {
                this.router.navigate(['/portal/unauthorized']);
              }
            },
            err => {
              this.loading = false;
              this.toaster.error(err);
            },
          );
        })
        .catch(err => {
          this.loading = false;
          this.toaster.error(err);
        });
    }
  }

  passwordReset() {
    // const email = this.resetForm.value.email;
    // this.authService..passwordReset(email).then(res => {
    //   if (res === true) {
    //     this.toaster.info('A reset email has been sent.');
    //   } else {
    //     this.toaster.error('There was an error sending you a reset email, please confirm email is correct');
    //   }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
