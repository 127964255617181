import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { classToPlain } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientEnvironmentModel } from 'src/app/models/client-environment';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private environmentCollection: AngularFirestoreCollection<ClientEnvironmentModel>;

  constructor(private afs: AngularFirestore) {
    this.environmentCollection = afs.collection<ClientEnvironmentModel>(COLLECTION_NAMES.ENVIRONMENTS);
  }

  listCorpEnvironments(corpId: string) {
    return this.environmentCollection.doc(corpId).ref.get();
  }

  setInitialEnvironment(corpId: string, initialEnvironment: ClientEnvironmentModel) {
    return this.environmentCollection
      .doc(corpId)
      .ref.get()
      .then(result => {
        if (result.exists) {
          throw new Error(`Initial environment for corp ID "${corpId}" already exists`);
        } else {
          return this.environmentCollection
            .doc(corpId)
            .set(Object.assign({}, classToPlain(initialEnvironment) as ClientEnvironmentModel));
        }
      });
  }

  getEnvironments(corpId: string): Observable<any> {
    return this.environmentCollection
      .doc(corpId)
      .valueChanges()
      .pipe(map((item: any) => item));
  }

  updateClientEnvironment(corpId: string, environment: ClientEnvironmentModel): Promise<void> {
    return this.environmentCollection.doc(corpId).update(environment);
  }
}
