import { MappingNode } from './MappingNode';

export class DataExportFilter {
  dateRange: Date[] | null;
  sortByTimeOrder: 'asc' | 'desc';
  includedFields: string[];
  includedFieldToNodeMap: Map<string, MappingNode>;
  excludedFields: string[];
  messagesCount: number;

  constructor() {
    this.dateRange = [new Date(), new Date()];
    this.sortByTimeOrder = 'desc';
    this.includedFields = [];
    this.includedFieldToNodeMap = new Map<string, MappingNode>();
    this.excludedFields = [];
    this.messagesCount = 0;
  }

  /**
   * Here we are considering that the included fields array is ascending sorted
   */
  public getRootsOfIncludedFields(): string[] {
    const rootsOfFields: string[] = [];
    this.includedFields.forEach(field => {
      const rootFieldIndex = rootsOfFields.findIndex(rootField => {
        return field.concat('.').startsWith(rootField.concat('.'));
      });

      if (rootFieldIndex < 0) {
        rootsOfFields.push(field);
      }
    });

    return rootsOfFields;
  }

  /**
   * Here we are considering that the excluded fields array is descending sorted
   */
  public getLeavesOfExcludedFields(): string[] {
    const leavesOfFields: string[] = [];
    this.excludedFields.forEach(field => {
      const leafFieldIndex = leavesOfFields.findIndex(leafField => {
        return leafField.concat('.').startsWith(field.concat('.'));
      });

      if (leafFieldIndex < 0) {
        leavesOfFields.push(field);
      }
    });

    return leavesOfFields;
  }
}
