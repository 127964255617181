import { Component } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ApiQueryModel } from 'src/app/models/api-query';

@Component({
  selector: 'app-add-api-query-modal',
  templateUrl: './add-api-query-modal.component.html',
  styleUrls: ['./add-api-query-modal.component.scss']
})
export class AddApiQueryModalComponent {
  apiQuery: ApiQueryModel;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    if (!options.initialState) {
      return;
    }
    const { corpId, botId, flowTemplateId } = options.initialState as any;
    this.apiQuery = ApiQueryModel.generateDefault();
    if (flowTemplateId) {
      this.apiQuery.flowTemplateId = flowTemplateId;
    } else {
      this.apiQuery.botId = botId;
      this.apiQuery.corpId = corpId;
    }
  }
}
