import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { UserModel } from 'src/app/models';
@Component({
  selector: 'app-hl-conversation-assign-user-modal',
  templateUrl: './hl-conversation-assign-user-modal.component.html',
  styleUrls: ['./hl-conversation-assign-user-modal.component.scss'],
})
export class HlConversationAssignUserModalComponent implements OnInit {
  allUser: UserModel[] = [];
  userId = '';
  assignedUsers: UserModel[] = [];

  save: EventEmitter<UserModel>;
  removeUser: EventEmitter<UserModel>;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    const { users, assignedUsers } = options.initialState as any;
    if (assignedUsers) {
      this.assignedUsers = assignedUsers;
    }
    let userIds: string[] = [];
    if (this.assignedUsers) {
      userIds = this.assignedUsers.map(u => u.id);
    }

    if (users) {
      const filteredUsers = users.filter(u => {
        return userIds.includes(u.id) === false;
      });
      this.allUser = filteredUsers;
      this.allUser = this.allUser.map(u => {
        return Object.assign({ disabled: userIds.includes(u.id) }, u);
      });
    }
    this.save = new EventEmitter<UserModel>();
    this.removeUser = new EventEmitter<UserModel>();
  }

  ngOnInit() {}

  assignUser() {
    if (this.userId) {
      const user = this.allUser.filter(u => u.id === this.userId)[0];
      this.save.emit(user);
    }
  }

  removeFromConversation(index) {
    const user = this.assignedUsers[index];
    if (confirm(`Are you sure you want to remove ${user.firstName}?`)) {
      this.removeUser.emit(user);
    }
  }
}
