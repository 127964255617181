import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Sequence } from '../../models/sequence/Sequence';
import { SequenceType } from '../../models/sequence/SequenceType';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class SequenceService {
  private sequences: AngularFirestoreCollection<Sequence>;

  constructor(private afs: AngularFirestore) {
    this.sequences = afs.collection<Sequence>(COLLECTION_NAMES.SEQUENCES);
  }

  /**
   * This sequence returns the next unique ID.
   * Using within a transaction it guarantees that the ID is the next one;
   * If between reading and updating the ID, from another source someone updates the ID,
   * this transaction will be repeated automatically ensuring the next unique ID.
   *
   * This method is designed to be used getting a unique ID for any entity.
   */
  getNextSequenceId(sequenceTypeParam?: SequenceType): Promise<number> {
    /*
      if the sequenceDoc type is not provided will be used the default one 'general'
     */
    const sequenceType = sequenceTypeParam ? sequenceTypeParam : SequenceType.GENERAL;
    const sequenceDoc = this.sequences.doc(sequenceType.toString());

    return this.afs.firestore.runTransaction(transaction => {
      return transaction.get(sequenceDoc.ref).then(countDoc => {
        if (!countDoc || !countDoc.exists) {
          throw new Error('Sequence does not exists!');
        }

        const data = countDoc.data();
        const count = data?.count ?? 0;

        const newCount = count + 1;
        transaction.update(sequenceDoc.ref, { count: newCount });

        return newCount;
      });
    });
  }
}
