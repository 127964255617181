import { Timestamp } from 'firebase-tools';
import firebase from 'firebase';

export class NlpDatasetFile {
  id: string;
  name: string;
  system_name: string;
  file_valid?: boolean;
  file_name: string;
  blob: string;
  archived: boolean;
  archived_at: string;
  created_at: Timestamp;
  updated_at: Timestamp;

  static generateDefault(): NlpDatasetFile {
    const nlpDatasetFile = new NlpDatasetFile();
    nlpDatasetFile.created_at = firebase.firestore.FieldValue.serverTimestamp();
    nlpDatasetFile.updated_at = firebase.firestore.FieldValue.serverTimestamp();
    nlpDatasetFile.blob = '';
    return nlpDatasetFile;
  }

  static generateFileName(nlpDatasetFile: NlpDatasetFile): string {
    const fileName = nlpDatasetFile.name.toLowerCase().trim().replace(/\s+/g, '-');
    nlpDatasetFile.file_name = `${fileName}.chatito`;
    return nlpDatasetFile.file_name;
  }

  static setEncodeBlob(nlpDatasetFile: NlpDatasetFile, value: string) {
    nlpDatasetFile.blob = window.btoa(value);
  }

  static getDecodedBlob(blob: string): string {
    return window.atob(blob);
  }
}
