import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { BotModel } from 'src/app/models/bot';
import { VersionModel } from 'src/app/models/version';
import { NodeModel } from 'src/app/models/node';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { plainToClass, classToPlain } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import firebase from 'firebase';
import { BotEnvironment } from '../../models/client-environment';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { BotsService } from './bots.service';
import { NodesService } from './nodes.service';
import { COLLECTION_NAMES } from './constants';

const VERSION_PREFIX = 'version_';
const DESIRED_VERSION_LENGTH = 5;

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private versions: BehaviorSubject<VersionModel[]>;
  versions$: Observable<VersionModel[]>;
  private botsCollection: AngularFirestoreCollection<BotModel>;
  private versionsCollection: (botId: string) => AngularFirestoreCollection<VersionModel>;

  constructor(
    private botsService: BotsService,
    private nodesService: NodesService,
    afs: AngularFirestore,
    private authService: AuthService,
  ) {
    this.botsCollection = afs.collection<BotModel>(COLLECTION_NAMES.BOTS);
    this.versionsCollection = (botId: string) =>
      this.botsCollection.doc(botId).collection<VersionModel>(COLLECTION_NAMES.VERSIONS);

    this.versions = new BehaviorSubject<VersionModel[]>([]);
    this.versions$ = this.versions.asObservable();
  }

  async setInitialVersion(bot: BotModel, nodes: NodeModel[]) {
    if (!bot) {
      return;
    }

    const version: VersionModel = {
      versionNumber: 1,
      versionDescription: 'Initial deployment.',
      createdAt: new Date().toISOString(),
      createdBy: await this.getCreatedByUser(),
      isCurrent: true,
      isLatest: true,
      bot,
      versionHistory: [],
    };

    await this.addVersion(bot.id, version, nodes);
  }

  async addNewVersion(bot: BotModel, changeDescription: string) {
    if (!bot || bot.clientEnvironment !== 'development') {
      return;
    }

    const versions = await this.getBotVersions(bot.id);

    const latestVersion = VersionModel.getLatest(versions);
    const currentVersion = VersionModel.getCurrent(versions);

    if (!latestVersion) {
      return;
    }

    const versionHistory = currentVersion && currentVersion.versionHistory ? currentVersion.versionHistory : [];

    if (currentVersion) {
      versionHistory.push(currentVersion.versionNumber);
    }
    const newVersion: VersionModel = {
      isLatest: true,
      isCurrent: true,
      versionNumber: latestVersion.versionNumber + 1,
      versionDescription: changeDescription,
      createdAt: new Date().toISOString(),
      createdBy: await this.getCreatedByUser(),
      bot,
      versionHistory,
    };

    this.addVersion(bot.id, newVersion, await this.getLatestNodesSnapshot(bot.id));
    if (currentVersion) {
      currentVersion.isCurrent = false;
      this.updateVersion(bot.id, currentVersion, { isCurrent: false });
    }
    latestVersion.isLatest = false;
    this.updateVersion(bot.id, latestVersion, { isLatest: false });

    this.versions.next([...versions, newVersion]);
  }

  async deployVersionToStage(
    versionSelectedForDeployment: VersionModel,
    originBot: BotModel,
    targetStage: BotEnvironment,
    deploymentDescription: string,
  ) {
    versionSelectedForDeployment.versionDescription = deploymentDescription;
    versionSelectedForDeployment.createdAt = new Date().toISOString();

    if (originBot.clientEnvironment === targetStage) {
      await this.handleSameStageDeployment(originBot, versionSelectedForDeployment, targetStage)
        .catch(error => {
          return Promise.reject(error);
        })
        .then(() => {
          return Promise.resolve();
        });
    } else {
      await this.handleDifferentStageDeployment(versionSelectedForDeployment, targetStage)
        .catch(error => {
          return Promise.reject(error);
        })
        .then(() => {
          return Promise.resolve();
        });
    }
  }

  private async handleDifferentStageDeployment(
    versionSelectedForDeployment: VersionModel,
    targetStage: BotEnvironment,
  ) {
    const originBot = versionSelectedForDeployment.bot;
    const targetBot = plainToClass(BotModel, originBot);
    const originBotId = `${targetBot.id}`;
    targetBot.clientEnvironment = targetStage;
    const selectedVersionNodes = await this.getVersionNodes(originBotId, versionSelectedForDeployment.versionNumber);
    const updatedNodes = await this.replaceNodesInEnvironment(selectedVersionNodes, targetBot.id);

    if (originBot.initNodeId) {
      targetBot.initNodeId = this.getIntroNodeForDeployment(selectedVersionNodes, originBot, updatedNodes);
    }
    const existingVersions = await this.getBotVersions(targetBot.id);

    // throw error if version already exists
    const hasVersion = existingVersions.filter(v => v.versionNumber === versionSelectedForDeployment.versionNumber);
    if (hasVersion.length > 0) {
      return Promise.reject('Version number already exists on the environment you are deploying to');
    }
    if (!existingVersions || existingVersions.length === 0) {
      versionSelectedForDeployment.isCurrent = true;
      versionSelectedForDeployment.isLatest = true;
      await this.botsService.addBot(targetBot);
      versionSelectedForDeployment.bot.clientEnvironment = targetStage;
      await this.addVersion(targetBot.id, versionSelectedForDeployment, selectedVersionNodes);
      return Promise.resolve();
    }
    versionSelectedForDeployment.bot.clientEnvironment = targetStage;
    const updatedVersionModel = this.updateNewVersionStatuses(existingVersions, versionSelectedForDeployment);
    this.addVersion(targetBot.id, updatedVersionModel, selectedVersionNodes);
    this.versions.next([...existingVersions, updatedVersionModel]);

    await this.botsService.updateBot(targetBot);
    return Promise.resolve();
  }

  private async replaceNodesInEnvironment(selectedVersionNodes: NodeModel[], botId: string): Promise<NodeModel[]> {
    const clonedSelectedVersionNodes = this.replaceNodeIdAndConnections(cloneDeep(selectedVersionNodes), botId);
    await this.nodesService.nukeAllNodesInBot(botId);
    await Promise.all(clonedSelectedVersionNodes.map(node => this.nodesService.updateNode(node)));
    return clonedSelectedVersionNodes;
  }

  private replaceNodeIdAndConnections(selectedVersionNodes: NodeModel[], botId: string): NodeModel[] {
    return selectedVersionNodes.map(node => {
      const originalNodeId = node.id;
      const newNodeId = uuidv4();

      selectedVersionNodes.map(node$1 => {
        node$1.connections?.map(connection => {
          if (connection.id === originalNodeId) {
            connection.id = newNodeId;
          }
        });
      });
      return {
        ...node,
        id: newNodeId,
        botId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
    });
  }

  private updateNewVersionStatuses(existingVersions: VersionModel[], newVersion: VersionModel): VersionModel {
    newVersion.isCurrent = true;
    const lastestVersion = VersionModel.getLatest(existingVersions)?.versionNumber || 0;
    if (newVersion.versionNumber >= lastestVersion) {
      newVersion.isLatest = true;
    }

    return newVersion;
  }

  private async getCreatedByUser(): Promise<object> {
    const user = await this.authService.getCurrentUserProfile();
    const userName = user?.fullName;
    const userId = user?.id;

    return { userName, userId };
  }

  private async getLatestNodesSnapshot(botId: string): Promise<NodeModel[]> {
    return await this.nodesService.getNodesByBotId(botId).pipe(take(1)).toPromise();
  }

  private getIntroNodeForDeployment(originNodes: NodeModel[], originBot: BotModel, updatedNodes: NodeModel[]) {
    const originalInitNode = originNodes.find(node => node?.id === originBot?.initNodeId);
    if (!originalInitNode) {
      return '';
    }
    const updatedInitNode = updatedNodes.find(node => node && node.systemName === originalInitNode.systemName);
    return updatedInitNode?.id || '';
  }

  private async handleSameStageDeployment(
    originBot: BotModel,
    versionSelectedForDeployment: VersionModel,
    targetStage: BotEnvironment,
  ): Promise<void> {
    const existingVersions = await this.getBotVersions(originBot.id);
    if (!existingVersions || existingVersions.length === 0) {
      return;
    }
    const currentVersion = existingVersions.filter(v => v.isCurrent)[0];
    if (currentVersion.versionNumber === versionSelectedForDeployment.versionNumber) {
      return;
    }

    const selectedVersionNodes = await this.getVersionNodes(originBot.id, versionSelectedForDeployment.versionNumber);

    const updatedVersionModel = this.updateNewVersionStatuses(existingVersions, versionSelectedForDeployment);
    originBot.clientEnvironment = targetStage;
    this.addVersion(originBot.id, updatedVersionModel, selectedVersionNodes);
    await this.updateVersion(originBot.id, currentVersion, { isCurrent: false });
    await this.updateVersion(originBot.id, updatedVersionModel, { isCurrent: true });
    existingVersions.forEach(v => {
      if (v.versionNumber === updatedVersionModel.versionNumber) {
        v.isCurrent = true;
      }
      if (v.versionNumber === currentVersion.versionNumber) {
        v.isCurrent = false;
      }
    });

    this.versions.next(existingVersions);

    const updatedNodes = await this.replaceNodesInEnvironment(selectedVersionNodes, originBot.id);

    if (originBot.initNodeId) {
      versionSelectedForDeployment.bot.initNodeId = this.getIntroNodeForDeployment(
        selectedVersionNodes,
        originBot,
        updatedNodes,
      );
    }

    versionSelectedForDeployment.bot.clientEnvironment = targetStage;
    const selectedVersionBotConfig = plainToClass(BotModel, versionSelectedForDeployment.bot);
    await this.botsService.updateBot(selectedVersionBotConfig);
  }

  prefixVersionNumberAsId(versionNumber: number): string {
    const versionNumberlength = `${versionNumber}`.length;
    const zerosToAppend = DESIRED_VERSION_LENGTH - versionNumberlength;
    let zeros = '';
    if (zerosToAppend > 0) {
      for (let i = 0; i < zerosToAppend; i++) {
        zeros += '0';
      }
    }
    return `${VERSION_PREFIX}${zeros}${versionNumber}`;
  }

  private async updateVersion(botId: string, version: VersionModel, data: object): Promise<void> {
    const versionDoc = this.versionsCollection(botId).doc(this.prefixVersionNumberAsId(version.versionNumber));

    const result = await versionDoc.ref.get();
    if (!result.exists) {
      return;
    }
    return this.versionsCollection(botId).doc(result.id).update(data);
  }

  private async addVersion(botId: string, version: VersionModel, nodes: NodeModel[]): Promise<void[]> {
    const newVersionDocument = this.versionsCollection(botId).doc(this.prefixVersionNumberAsId(version.versionNumber));

    const result = await newVersionDocument.ref.get();
    if (result.exists) {
      throw new Error(`Version for bot ID "${botId}" already exists`);
    }

    if (version.isCurrent) {
      await this.updateVersionState(botId, 'isCurrent');
    }

    if (version.isLatest) {
      await this.updateVersionState(botId, 'isLatest');
    }
    await newVersionDocument.set(Object.assign({}, classToPlain(version)) as VersionModel);
    return Promise.all(
      nodes.map(node =>
        newVersionDocument
          .collection<NodeModel>(COLLECTION_NAMES.NODES)
          .doc(node.id)
          .set(classToPlain(node) as NodeModel),
      ),
    );
  }

  private getVersionNodes(botId: string, versionNumber: number): Promise<NodeModel[]> {
    return this.versionsCollection(botId)
      .doc(this.prefixVersionNumberAsId(versionNumber))
      .collection<NodeModel>(COLLECTION_NAMES.NODES)
      .valueChanges()
      .pipe(
        map(node => {
          return plainToClass(NodeModel, node);
        }),
      )
      .pipe(take(1))
      .toPromise();
  }

  private async updateVersionState(botId: string, field: string) {
    const currentBots = await this.versionsCollection(botId).ref.where(field, '==', true).get();

    await Promise.all(
      currentBots.docs.map(version$1 =>
        this.versionsCollection(botId)
          .doc(version$1.id)
          .update({ ...version$1.data(), [field]: false }),
      ),
    );
  }

  async getBotVersions(botId: string): Promise<VersionModel[]> {
    return this.versionsCollection(botId)
      .valueChanges()
      .pipe(
        map(version => {
          return plainToClass(VersionModel, version);
        }),
      )
      .pipe(take(1))
      .toPromise();
  }
}
