import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { PermissionModel } from 'src/app/models/permission';
import { CorpVM } from './_types/CorpVM';
import { getSidebarItems } from '../../utils';
import { PermissionsService } from 'src/app/services/firestore/permissions.service';
import { CorpsService, RolesService, UsersService } from 'src/app/services/firestore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  corpsId: string[];
  corps: CorpVM[];
  loading: boolean;
  permissions: PermissionModel[];

  private permissionsSubscription: Subscription;
  private dataSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private sidebarService: SidebarService,
    private permissionsService: PermissionsService,
    private toaster: ToastrService,
    private corpsService: CorpsService,
    private userService: UsersService,
    private rolesService: RolesService,
  ) {
    this.loading = false;
  }

  async ngOnInit() {
    this.headerService.setPageTitle('Dashboard');
    this.setBreadcrumb();
    this.setSidebarItems();
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }

    this.loading = true;

    const canAccess = await this.userService.CanAccessAllCompanies(user.uid);
    if (canAccess) {
      this.corpsService.getCorps().subscribe(corps => {
        combineLatest(corps.map(x => this.corpsService.getCorpById(x.id))).subscribe(corpsData => {
          console.log(corpsData);
          this.loading = false;
          this.corps = corpsData.filter(corp => corp != null && !corp.inactive) as CorpModel[];
          this.corpsId = this.corps.map(({ id }) => id);
        });
      });
      return;
    }

    this.permissionsSubscription = this.permissionsService.getPermissionsBy(user.uid).subscribe(
      (permissions: PermissionModel[]) => {
        this.permissions = permissions;
        const corpsId = permissions
          .map(permission => permission.corpId)
          .filter((value, index, self) => {
            return value && self.indexOf(value) === index;
          });
        const observables = corpsId.map(corpId => this.corpsService.getCorpById(corpId));
        this.dataSubscription = combineLatest(observables).subscribe(
          corps => {
            this.loading = false;
            this.corps = corps.filter(corp => corp != null && !corp.inactive) as CorpModel[];
            this.corpsId = this.corps.map(({ id }) => id);
          },
          error => {
            this.toaster.error(error);
            this.loading = false;
          },
        );
      },
      error => {
        this.toaster.error('Permissions subscription exception: ' + error);
        this.loading = false;
      },
    );
  }

  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Dashboard',
        route: '/portal/dashboard',
      },
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }

  navigateToCorp(corpId: string) {
    this.router.navigate(['portal/corps', corpId]);
  }

  ngOnDestroy() {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
