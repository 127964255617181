import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { HierarchyElementModel } from 'src/app/models';
import {
  HierarchyElementsService,
  ApiQueriesService,
  CorpsService,
  BotsService,
  NodesService,
} from 'src/app/services/firestore';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { take } from 'rxjs/operators';
import { NodeModel } from 'src/app/models/node';
import FileSaver from 'file-saver';
import { TemplateChannels, TemplateModel } from 'src/app/models/template';
import { makeObjectFirebaseTimestampHumanReadable } from 'src/app/utils/date/date-utils';
import { ApiQueryModel } from 'src/app/models/api-query';

type NodeExportKeys = keyof NodeModel | 'templateWeb' | 'templateFacebook';

@Component({
  selector: 'app-bot-export',
  templateUrl: './bot-export.component.html',
  styleUrls: ['./bot-export.component.scss'],
})
export class BotExportComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  hierarchyElement: HierarchyElementModel;
  corp: CorpModel;
  bot: BotModel;
  nodes: NodeModel[] = [];
  exportAbleColumnsAsArray: Array<{
    key: string;
    label: string;
  }>;
  loading = true;
  exportGlobalVariables = true;
  exportApiQueries = true;
  apiQueries: ApiQueryModel[] = [];

  exportAbleColumns: { [key in NodeExportKeys]?: string } = {
    id: 'ID',
    name: 'Label',
    action: 'Action',
    systemName: 'System Name',
    spec: 'Spec',
    templateWeb: 'Template (Web)',
    templateFacebook: 'Template (Facebook)',
    createdAt: 'Creation Date',
    updatedAt: 'Updated Date',
    inputData: 'Input Data',
    kpis: 'KPIs',
    httpRequests: 'HTTP requests',
    apiQueries: 'API Queries',
    specTemplates: 'Spec Templates',
    live: 'Is Live',
    global: 'Is Global',
    validations: 'Validations',
    connections: 'Connections',
    x: 'X',
    y: 'Y',
    notes: 'Notes',
  };

  selectedColumns: Array<NodeExportKeys> = ['id', 'name', 'action', 'templateWeb', 'templateFacebook', 'createdAt'];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private nodesService: NodesService,
    private hierarchyElementsService: HierarchyElementsService,
    private authService: AuthService,
    private toaster: ToastrService,
    private apiQueriesService: ApiQueriesService,
    private clientEnvironmentService: ClientEnvironmentService,
  ) {
    this.exportAbleColumnsAsArray = Object.entries(this.exportAbleColumns).map(([key, label]) => ({
      key,
      label: `${label}`,
    }));
  }

  async ngOnInit() {
    this.paramMapSubscription = combineLatest([
      this.route.paramMap,
      this.authService.currentUser,
      this.clientEnvironmentService.items$,
    ]).subscribe(async ([params, user, botEnvironment]) => {
      try {
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        const botCode = params.get('bot');
        if (!corpId || !hierarchyElementSystemName || !botCode || !user || !botEnvironment) {
          return;
        }
        const [corp, hierarchyElement, bot] = await combineLatest([
          this.corpsService.getCorpById(corpId),
          this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
          this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, botEnvironment),
        ])
          .pipe(take(1))
          .toPromise();

        if (!corp || !hierarchyElement || !bot) {
          return;
        }

        this.corp = corp;
        this.hierarchyElement = hierarchyElement;
        this.bot = bot;
        this.exportGlobalVariables = !!this.bot?.globalVariables.length;

        this.refreshUI();

        const [nodes, apiQueries] = await combineLatest([
          this.nodesService.getNodesByBotId(this.bot.id),
          this.apiQueriesService.getApiQueriesByBotId(this.bot.id),
        ])
          .pipe(take(1))
          .toPromise();

        this.apiQueries = apiQueries;
        this.apiQueries.forEach(apiQuery => makeObjectFirebaseTimestampHumanReadable(apiQuery));
        this.exportApiQueries = !!apiQueries.length;

        this.nodes = nodes.map(node => {
          makeObjectFirebaseTimestampHumanReadable(node);
          return Object.entries(node).reduce((nodeDetail: Partial<NodeModel>, [key, value]) => {
            if (typeof value === 'object') {
              value = JSON.stringify(value);
            }
            let objectToReduceToNode = { [key]: value };
            if (key === 'templates' && node.templates) {
              const filterTemplates = this.filterTemplates(node.templates);
              objectToReduceToNode = {
                templateWeb: filterTemplates(TemplateChannels.Web),
                templateSMS: filterTemplates(TemplateChannels.SMS),
              };
            }
            return { ...nodeDetail, ...objectToReduceToNode };
          }, {});
        }) as unknown as NodeModel[];
      } catch (error) {
        this.toaster.error(error);
      } finally {
        this.loading = false;
      }
    });
  }

  refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
    this.headerService.setPageTitle(`${this.bot.label} Export`);
  }

  toggleColumnExport(columnKey: NodeExportKeys) {
    const columnIndex = this.selectedColumns.findIndex(column => columnKey === column);
    if (columnIndex === -1) {
      this.selectedColumns = [...this.selectedColumns, columnKey];
      return;
    }
    this.selectedColumns.splice(columnIndex, 1);
  }

  exportNodes() {
    const nodesHeader = this.selectedColumns.map(column => this.exportAbleColumns[column]).join(',');
    const nodesRows = this.nodes
      .map(node => this.selectedColumns.flatMap(column => this.replaceDelimiters(node[column])).join(','))
      .join('\n');
    let dataToExport = `${nodesHeader}\n${nodesRows}`;

    dataToExport = this.addGlobalVariablesToExport(dataToExport);
    dataToExport = this.addApiQueriesVariablesToExport(dataToExport);

    if (this.exportApiQueries || this.exportGlobalVariables) {
      dataToExport = `Nodes\n${dataToExport}`;
    }

    const blob = new Blob([dataToExport], {
      type: 'text/plain;charset=utf-8',
    });
    const time = new Date().toISOString();
    FileSaver.saveAs(blob, `${this.bot.id}-${time}.csv`);
  }

  private addGlobalVariablesToExport(dataToExport: string): string {
    if (this.exportGlobalVariables) {
      const globalVariablesHeader = ['Label', 'DefaultValue', 'Type', 'Description'].join(',');
      const globalVariablesRows = this.bot?.globalVariables
        .map(
          ({ label, defaultValue, description, type }) =>
            `${label},${defaultValue},${type},${this.replaceDelimiters(description)}`,
        )
        .join('\n');
      dataToExport += `\n\nGlobal Variables\n${globalVariablesHeader}\n${globalVariablesRows}`;
    }
    return dataToExport;
  }

  private addApiQueriesVariablesToExport(dataToExport: string): string {
    if (this.exportApiQueries) {
      const apiQueriesHeader = ['ID', 'Name', 'Query', 'Example Data', 'Created Date'].join(',');
      const apiQueriesRows = this.apiQueries
        .map(
          ({ id, name, query, exampleData, createdAt }) =>
            `${id},${name},${this.replaceDelimiters(query)},${this.replaceDelimiters(exampleData)},${createdAt}`,
        )
        .join('\n');
      dataToExport += `\n\nAPI Queries\n${apiQueriesHeader}\n${apiQueriesRows}`;
    }
    return dataToExport;
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Settings & Test', 'settings'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  private filterTemplates(templates: TemplateModel[]) {
    return (templateChannel: TemplateChannels): string => {
      return this.replaceDelimiters(
        templates
          .filter(({ channel }) => channel === templateChannel)
          .map(({ rawTemplate }) => rawTemplate)
          .join('|'),
      );
    };
  }

  private removeNewLines(inputString: string) {
    return inputString.replace(/(\r\n|\n|\r)/gm, '');
  }

  private replaceDelimiters(inputString: string): string {
    if (typeof inputString !== 'string') {
      return inputString;
    }
    return this.removeNewLines(inputString.split(',').join('<COMMA>'));
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
