import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';

@Component({
  selector: 'app-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss']
})
export class SimpleListComponent implements OnInit {
  @Input()
  items: SimpleListItem[];

  @Input()
  title: string;

  @Input()
  canCreate = true;

  @Output()
  onCreate: EventEmitter<string>;
  
  constructor() {
    this.onCreate = new EventEmitter<string>();
  }

  ngOnInit() {}

  onClickCreate() {
    this.onCreate.emit();
  }
}
