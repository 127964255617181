import { Component, Input, OnDestroy, OnChanges } from '@angular/core';
import { BotModel } from 'src/app/models/bot';
import { classToPlain } from 'class-transformer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bot-web-chat',
  templateUrl: './bot-web-chat.component.html',
  styleUrls: ['./bot-web-chat.component.scss']
})
export class BotWebChatComponent implements OnChanges, OnDestroy {
  constructor() {}
  @Input()
  bot: BotModel;

  scriptNode: HTMLScriptElement;

  private isLoadingFinished = false;

  ngOnChanges() {
    if (!this.bot) {
      return;
    }
    if (!this.scriptNode) {
      this.loadScript();
    } else {
      this.initCarlabsChat();
    }
  }

  private loadScript() {
    this.isLoadingFinished = false;
    this.scriptNode = document.createElement('script');
    this.scriptNode.id = 'carlabs-bot-script';
    this.scriptNode.setAttribute('data-bot-id', this.bot.id);
    this.scriptNode.setAttribute('data-script-usage', 'internal');
    this.scriptNode.async = true;
    this.scriptNode.src = environment.botWebChatUrl;
    document.getElementsByTagName('head')[0].appendChild(this.scriptNode);
    document.addEventListener('BOT_STATE__LOADED', () => {
      this.initCarlabsChat();
    });
  }

  private removeScript() {
    if (this.scriptNode) {
      this.scriptNode.remove();
    }
  }

  private initCarlabsChat() {
    const carlabsChat = (window as any).CarLabsChat;
    const config = classToPlain(this.bot);
    this.isLoadingFinished = false;
    carlabsChat.init(config);
    this.isLoadingFinished = true;
  }

  private destroyCarlabsChat() {
    const carlabsChat = (window as any).CarLabsChat;
    if (!this.isLoadingFinished) {
      setTimeout(() => {
        this.destroyCarlabsChat();
      }, 100);
    } else {
      carlabsChat.destroy(this.bot.rootElementId);
      this.removeScript();
    }
  }

  ngOnDestroy() {
    this.destroyCarlabsChat();
  }
}
