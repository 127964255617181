import { Injectable } from '@angular/core';
import { NodeModel } from 'src/app/models/node';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import groupBy from 'lodash/groupBy';
import { diff } from 'jsondiffpatch';
import uniq from 'lodash/fp/uniq';
import { plainToClass } from 'class-transformer';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class NodesService {
  private nodesCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<NodeModel>;
  private nodesCollection: AngularFirestoreCollection<NodeModel>;

  constructor(private afs: AngularFirestore) {
    this.nodesCollectionQuery = (ref: QueryFn) => afs.collection<NodeModel>(COLLECTION_NAMES.NODES, ref);
    this.nodesCollection = afs.collection<NodeModel>(COLLECTION_NAMES.NODES);
  }

  getNodesByBotId(botId: string): Observable<NodeModel[]> {
    const collection = this.nodesCollectionQuery(ref => ref.where('botId', '==', botId));
    return collection
      .valueChanges({ idField: 'id' })
      .pipe(map(results => results.sort((a, b) => (a.name > b.name ? 1 : -1))));
  }

  getNodesBySystemNameAndCorpId(systemName: string, botId: string): Observable<NodeModel[]> {
    const collection = this.nodesCollectionQuery(ref =>
      ref.where('systemName', '==', systemName).where('botId', '==', botId),
    );

    return collection.valueChanges({ idField: 'id' });
  }

  async addNode(node: NodeModel): Promise<void> {
    const clonedNode: NodeModel = Object.assign({}, node) as NodeModel;
    NodeModel.removeExcluded(clonedNode);
    if (await this.nodeExists(clonedNode)) {
      throw new Error(`Node already exists: ${node.systemName}`);
    }
    return this.nodesCollection.doc(clonedNode.id).set(NodeModel.toPlain(clonedNode));
  }

  private async nodeExists(node: NodeModel): Promise<boolean> {
    const data = await this.getNodesBySystemNameAndCorpId(node.systemName, node.botId).pipe(take(1)).toPromise();

    return data.length > 0;
  }

  async countNodesByFlowTemplateSystemNameAndCorpId(flowTemplateSystemName: string, botId: string): Promise<number> {
    const collection = this.nodesCollectionQuery(ref =>
      ref.where('flowTemplateSystemName', '==', flowTemplateSystemName).where('botId', '==', botId),
    );
    return (await collection.valueChanges({ idField: 'id' }).pipe(take(1)).toPromise()).length;
  }

  async addNodeAndPrefixNodeNameUntilItDoesntExist(node: NodeModel, count = 0): Promise<void> {
    const clonedNode: NodeModel = Object.assign({}, node) as NodeModel;
    NodeModel.removeExcluded(clonedNode);
    if (count > 0) {
      clonedNode.name = `${clonedNode.name} ${count}`;
      NodeModel.generateSystemName(clonedNode);
    }
    if (await this.nodeExists(clonedNode)) {
      return this.addNodeAndPrefixNodeNameUntilItDoesntExist(node, ++count);
    }
    return this.nodesCollection.doc(clonedNode.id).set(NodeModel.toPlain(clonedNode));
  }

  async getNodesByBotIds(botIds: string[]) {
    return this.nodesCollection.ref.where('botId', 'in', botIds).get();
  }

  async getSupportNodesForBot(botId: string) {
    const result = await this.nodesCollectionQuery(ref =>
      ref.where('botId', '==', botId).where('availableForSupport', '==', true),
    )
      .get()
      .toPromise();
    return result.docs
      .map(row => {
        return plainToClass(NodeModel, row.data());
      })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }

  async updateNode(node: NodeModel, trackCustomisation?: boolean): Promise<void> {
    NodeModel.ifGlobalClear(node);
    const clonedNode: NodeModel = Object.assign({}, node) as NodeModel;
    NodeModel.removeExcluded(clonedNode);
    NodeModel.validateConnections(clonedNode);
    NodeModel.generateUpdatedAt(clonedNode);
    if (trackCustomisation && node.flowTemplateId) {
      const previousNodeState = await this.getNodeById(node.id).pipe(take(1)).toPromise();
      const differences = diff(previousNodeState, clonedNode) as object;
      if (differences) {
        const keysToSkip: Array<keyof NodeModel> = ['x', 'y', 'updatedAt', 'createdAt'];
        const keysThatChanged = Object.keys(differences).filter(key => !(keysToSkip as string[]).includes(key));
        if (node.flowTemplateCustomizations) {
          node.flowTemplateCustomizations = uniq(node.flowTemplateCustomizations.concat(keysThatChanged));
        } else {
          node.flowTemplateCustomizations = keysThatChanged;
        }
      }
    }
    return this.nodesCollection.doc(node.id).set(NodeModel.toPlain(clonedNode));
  }

  async getNodesDerivedFromFlowTemplate(flowSystemName: string): Promise<NodeModel[]> {
    return await this.nodesCollectionQuery(ref => ref.where('flowTemplateId', '==', flowSystemName))
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async removeNodesInFlow(flowSystemName: string): Promise<void> {
    const nodesBasedOfFlowTemplate = await this.getNodesDerivedFromFlowTemplate(flowSystemName);

    const nodesByBot = groupBy(nodesBasedOfFlowTemplate, 'botId');

    Object.entries(nodesByBot).forEach(([botId, nodes]) =>
      this.removeNodesInBot(
        botId,
        nodes.map(({ id }) => id),
      ),
    );
  }

  async removeNodesInBot(botId: string, nodeIds: string[]) {
    const nodesInBot = await this.getNodesByBotId(botId).pipe(take(1)).toPromise();

    nodeIds.forEach(nodeId => {
      this.clearConnectionBeforeDeletingNode(nodesInBot, nodeId);
    });
  }

  async nukeAllNodesInBot(botId: string) {
    const nodesInBot = await this.getNodesByBotId(botId).pipe(take(1)).toPromise();
    return await Promise.all(nodesInBot.map(({ id }) => this.deleteNodeById(id)));
  }

  async removeNode(botId: string, nodeId: string): Promise<void> {
    const nodesInBot = await this.getNodesByBotId(botId).pipe(take(1)).toPromise();
    await this.clearConnectionBeforeDeletingNode(nodesInBot, nodeId);
  }

  getNodeById(id: string): Observable<NodeModel> {
    return this.nodesCollection
      .doc(id)
      .valueChanges()
      .pipe(
        map(node => {
          return node as NodeModel;
        }),
      );
  }

  getNodesByTemplateId(templateId: string): Observable<NodeModel[]> {
    const collection = this.nodesCollectionQuery(ref => ref.where('templateId', '==', templateId));
    return collection.valueChanges({ idField: 'id' });
  }

  async deleteNodeById(nodeId: string) {
    return await this.nodesCollection.doc(nodeId).delete();
  }

  async clearConnectionBeforeDeletingNode(nodesInBot: NodeModel[], nodeId: string) {
    const nodesWithDeletedId = nodesInBot.filter(n => {
      if (!n.connections) {
        return false;
      }
      return n.connections.filter(c => c.id === nodeId).length;
    });
    await Promise.all(
      nodesWithDeletedId.map(async n => {
        if (!n.connections) {
          return;
        }
        n.connections = n.connections.filter(c => c.id !== nodeId);
        return await this.updateNode(n);
      }),
    );
    return await this.deleteNodeById(nodeId);
  }
}
