import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { NodeModel } from 'src/app/models/node';
import { BotModel } from 'src/app/models';
import { EventTriggersModel } from 'src/app/models/bot';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { validationMessages } from 'src/app/utils/validation/validation-messages';
import { NodesService } from 'src/app/services/firestore';

@Component({
  selector: 'app-add-bot-event-trigger-modal',
  templateUrl: './add-bot-event-trigger-modal.component.html',
  styleUrls: ['./add-bot-event-trigger-modal.component.scss'],
})
export class AddBotEventTriggerModalComponent implements OnInit {
  addTriggerForm: FormGroup;
  loading: boolean;
  nodes: NodeModel[];
  bot: BotModel;
  eventTriggers: EventTriggersModel;
  public onClose: Subject<object>;
  validationMessages = validationMessages;

  constructor(
    private nodesService: NodesService,
    private fb: FormBuilder,
    public modal: BsModalRef,
    options: ModalOptions,
  ) {
    this.loading = false;
    if (!options.initialState) {
      return;
    }
    const { bot, nodes } = options.initialState as any;
    this.bot = bot;
    if (nodes) {
      this.nodes = nodes;
    }
  }

  ngOnInit(): void {
    this.addTriggerForm = this.fb.group({
      eventTriggerType: ['', Validators.required],
      pageUrl: ['', [Validators.required, this.validateUniqueUrl]],
      nodeId: ['', Validators.required],
    });
    this.onClose = new Subject();
    if (!this.nodes) {
      this.nodesService.getNodesByBotId(this.bot.id).subscribe(nodes => {
        this.nodes = nodes;
      });
    }
  }

  validateUniqueUrl = (control: AbstractControl): { [key: string]: boolean } | null => {
    const isTaken = this.bot.eventTriggers.some(t => t.eventTriggerInput.trim() === control.value.trim());
    return isTaken ? { urlTaken: true } : null;
  };

  addTrigger(event) {
    event.preventDefault();

    const selectedNode = this.nodes.filter(node => node.id === this.addTriggerForm.controls.nodeId.value);
    const eventTriggerNode = selectedNode[0];
    const eventTriggerId = uuidv4();

    const newEventTrigger: EventTriggersModel = {
      eventTriggerId,
      eventTriggerNodeId: eventTriggerNode.id,
      eventTriggerNodeName: eventTriggerNode.name,
      eventTriggerInput: this.addTriggerForm.controls.pageUrl.value,
      eventTriggerType: this.addTriggerForm.controls.eventTriggerType.value,
    };

    this.onClose.next({ newEventTrigger });
  }
}
