import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SunburstData } from '../sunburst/_types/SunburstData';
import { executeSearch, index$, setAgg, setFilter, setSize } from '../../../../utils/es-reactive/es-reactive';
import { map } from 'rxjs/operators';
import { SunburstTree } from '../sunburst/_types/SunburstTree';
import { EsClientService } from '../../../../services/es-client.service';
import { SunburstSettings } from '../sunburst/_types/SunburstSettings';
import { getFilter } from '../../utils';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';

@Component({
  selector: 'app-sunburst-conversations-intents',
  templateUrl: './sunburst-conversations-intents.component.html',
  styleUrls: ['./sunburst-conversations-intents.component.scss']
})
export class SunburstConversationsIntentsComponent implements OnInit {
  @Input()
  corpId: string;

  @Input()
  hierarchyElementId: string;

  @Input()
  botId: string;

  /**
   * If not provided. will be used the default defined region {@link AwsRegionEnum.US_EAST_1}
   */
  @Input()
  awsRegion: AwsRegionEnum;

  sunburstSource: SunburstSettings;

  constructor(private esClientService: EsClientService) {}

  ngOnInit() {
    this.sunburstSource = {
      data: this.getPortalIntentsSunburstTree.bind(this),
      surtitle: 'Overview',
      title: 'Conversations Intents'
    };
  }

  private getPortalIntentsSunburstTree(indexes: string[]): Observable<SunburstData> {
    return index$(indexes).pipe(
      setFilter(getFilter.bind(this)(true)),
      setSize(0),
      setAgg('intentPathsCountAgg', {
        scripted_metric: {
          init_script: 'state.messagesList = new ArrayList();',
          map_script: `
            Map currentMessage = [
              'conversationId': doc['messageReceivedEvent.context.conversationId.keyword'].value,
              'intentsPath': doc['brainResponseEvent.brainQueryResponse.data.user_data.intentsHistoryPathString.keyword'].value
            ];
            state.messagesList.add(currentMessage);`,
          combine_script: 'return state',
          reduce_script: `
            List messages = new ArrayList();
            Map conversationsMap = new HashMap();
            Map intentsMap = new HashMap();
            boolean[] ifElseWorkaround = new boolean[1];

            for (state in states) {
              messages.addAll(state.messagesList);
            }

            messages.stream().forEach(message -> {
              Map existingMessage = conversationsMap.get(message.conversationId);
              if(existingMessage == null || message.intentsPath.length() > existingMessage.intentsPath.length()) {
                conversationsMap.put(message.conversationId, ['intentsPath': message.intentsPath]);
              } else {
                ifElseWorkaround[0] = true;
              }
            });

            conversationsMap.entrySet().forEach(conversation -> {
              if (intentsMap.containsKey(conversation.getValue().intentsPath)) {
                long intentsCount = intentsMap.get(conversation.getValue().intentsPath) + 1;
                intentsMap.put(conversation.getValue().intentsPath, intentsCount);
              } else {
                intentsMap.put(conversation.getValue().intentsPath, 1L);
              }
            });

            return intentsMap.entrySet().stream().map(intentPath -> [
              'path': intentPath.getKey().toString(),
              'count': intentPath.getValue()
            ]).collect(Collectors.toSet())`
        }
      }),
      executeSearch(this.esClientService.getClient(this.awsRegion)),
      map(searchResponse => {
        const sunburstData: SunburstData = new SunburstData(new SunburstTree(''));

        if (searchResponse.aggregations?.intentPathsCountAgg?.value) {
          const intentsPaths: [{ path: string; count: number }] = searchResponse.aggregations.intentPathsCountAgg.value;

          // creating the intents tree
          intentsPaths.forEach(intentsPath => {
            const intentsList: string[] = intentsPath.path.split('->');

            // adding every intents path leaf to the tree
            let sunburstHomeNode = sunburstData.sunburstTree;
            intentsList.forEach((intentName, index, array) => {
              /* initially all conversations are starting with the same intentName
              and because of that we need to use the first one as the 'root intent' */

              const existingChildNode = sunburstHomeNode.getChildByName(intentName.trim());
              if (existingChildNode) {
                // defining the new root
                sunburstHomeNode = existingChildNode;
              } else {
                const newSunburstNode = new SunburstTree(intentName.trim());
                sunburstHomeNode.children.push(newSunburstNode);
                sunburstHomeNode = newSunburstNode;
              }

              // increase the size to the tree leaf
              if (index === array.length - 1) {
                sunburstHomeNode.increaseSize(intentsPath.count);
              }
            });
          });
        }

        return sunburstData;
      })
    );
  }
}
