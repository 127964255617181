import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { BotModel, GlobalVariableType, GlobalVariable, BotTeaserModel } from 'src/app/models/bot';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { UploadService } from 'src/app/services/upload.service';
import { flatMap, map, take } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import { NodeModel } from 'src/app/models/node';
import { AddFacebookPageModalComponent } from 'src/app/components/modals/add-facebook-page-modal/add-facebook-page-modal.component';
// tslint:disable-next-line:max-line-length
import { AddBotEventTriggerModalComponent } from 'src/app/components/modals/add-bot-event-trigger-modal/add-bot-event-trigger-modal.component';
import { Subscription } from 'rxjs';
import { sluggify } from 'src/app/utils/strings/string.utils';
import { PermissionModel } from 'src/app/models/permission';
import { AuthService } from 'src/app/services/auth.service';
import { Permissions } from 'src/app/utils/permissions/permissions';
import uniqBy from 'lodash/uniqBy';
import { CountryEnum } from '../../../models/countries/CountryEnum';
import { IGlobalVariablesPropagationPayload } from './_components/render-global-variables/render-global-variables.component';
// tslint:disable-next-line: max-line-length
import { AddTeaserQRBModalComponentComponent } from 'src/app/components/modals/add-teaser-qrbmodal-component/add-teaser-qrbmodal-component.component';
import { validationMessages } from 'src/app/utils/validation/validation-messages';
import { BotsService, NodesService, PermissionsService, VersionService } from 'src/app/services/firestore';

@Component({
  selector: 'app-bot-general-info',
  templateUrl: './bot-general-info.component.html',
  styleUrls: ['./bot-general-info.component.scss'],
})
export class BotGeneralInfoComponent implements OnInit, OnDestroy {
  loading: boolean;
  userId: string;
  isAdmin: boolean;

  @Input()
  writable: boolean;

  @Input()
  bot: BotModel;

  @Input()
  shouldAdd = false;

  @Input()
  canSaveChanges: boolean;

  @Input()
  botNameList: string[];

  @Output()
  saved: EventEmitter<void>;

  @Output()
  canceled: EventEmitter<void>;

  botVariableItems: GlobalVariable[] = [];
  apiVariableItems: GlobalVariable[] = [];
  userVariableItems: GlobalVariable[] = [];

  countries: CountryEnum[] = CountryEnum.values();
  nodes: NodeModel[];
  avatarFile: File | null;
  nlpModels: NlpModel[] = [];

  validationMessages = validationMessages;

  private nlpGetModelsSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private botsService: BotsService,
    private permissionsService: PermissionsService,
    private nodesService: NodesService,
    private nlpDojoService: NlpDojoService,
    private uploadService: UploadService,
    private versionService: VersionService,
    private clientEnvironmentService: ClientEnvironmentService,
    private toaster: ToastrService,
    private authService: AuthService,
  ) {
    this.saved = new EventEmitter<void>();
    this.canceled = new EventEmitter<void>();
    this.isAdmin = this.getIsAdmin();
  }

  ngOnInit(): void {
    this.nlpGetModelsSubscription = this.nlpDojoService.getModels('default').subscribe(nlpModels => {
      this.nlpModels = nlpModels;
    });

    this.nodesService.getNodesByBotId(this.bot.id).subscribe(nodes => {
      this.nodes = nodes;
    });
    this.renderGlobalVariablesSimpleListItem();
  }

  private renderGlobalVariablesSimpleListItem() {
    this.botVariableItems = this.filterVariableItems(GlobalVariableType.BOT);
    this.apiVariableItems = this.filterVariableItems(GlobalVariableType.API_DATA);
    this.userVariableItems = this.filterVariableItems(GlobalVariableType.USER_INPUT);
  }

  handleGlobalVariableChanges({ variableType, globalVariables }: IGlobalVariablesPropagationPayload) {
    this.bot.globalVariables = uniqBy(
      [...this.bot.globalVariables.filter(({ type }) => type !== variableType), ...globalVariables],
      'slug',
    );
    this.renderGlobalVariablesSimpleListItem();
  }

  private filterVariableItems(variableType: GlobalVariableType) {
    return this.bot.globalVariables.filter(({ type }) => type === (variableType as GlobalVariableType));
  }

  ngOnDestroy(): void {
    if (this.nlpGetModelsSubscription) {
      this.nlpGetModelsSubscription.unsubscribe();
    }
  }

  addEventTrigger() {
    if (!this.bot) {
      return;
    }

    if (!this.bot.eventTriggers) {
      this.bot.eventTriggers = [];
    }

    const modalRef = this.modalService.show(AddBotEventTriggerModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        bot: this.bot,
      },
    });

    modalRef.content.onClose.subscribe(result => {
      modalRef.hide();
      const {
        eventTriggerInput,
        eventTriggerNodeId,
        eventTriggerType,
        eventTriggerId,
        eventTriggerNodeName,
      } = result.newEventTrigger;
      this.bot.eventTriggers.push({
        eventTriggerId,
        eventTriggerInput,
        eventTriggerNodeId,
        eventTriggerType,
        eventTriggerNodeName,
      });
    });
  }

  removeBotTrigger(id: string) {
    if (!this.bot?.config) {
      return;
    }
    this.bot.eventTriggers = this.bot.eventTriggers.filter(trigger => trigger.eventTriggerId !== id);
  }

  addFacebookPageConfig() {
    if (!this.bot) {
      return;
    }
    if (!this.bot.facebookPageIds) {
      this.bot.facebookPageIds = [];
      this.bot.facebookPageConfigs = [];
    }

    const modalRef = this.modalService.show(AddFacebookPageModalComponent, { ignoreBackdropClick: true });
    modalRef.content.onClose.subscribe(result => {
      modalRef.hide();
      this.bot.facebookPageIds.push(result.pageId);
      this.bot.facebookPageConfigs.push({
        appId: result.appId,
        appSecret: result.appSecret,
        pageId: result.pageId,
        pageAccessToken: result.pageAccessToken,
        passThreadControlTargetAppId: result.passThreadControlTargetAppId,
      });
    });
  }

  removeFacebookPageConfig(id: string) {
    if (!this.bot?.config) {
      return;
    }
    this.bot.facebookPageIds = this.bot.facebookPageIds.filter(pageId => pageId !== id);
    this.bot.facebookPageConfigs = this.bot.facebookPageConfigs.filter(pageConfig => pageConfig.pageId !== id);
  }

  save() {
    if (this.shouldAdd) {
      this.addBot();
    } else {
      this.updateBot();
    }
  }

  addTeaserQRB() {
    const modalRef = this.modalService.show(AddTeaserQRBModalComponentComponent, {
      ignoreBackdropClick: true,
      initialState: {
        teaserButtons: this.bot.styles.teaserButtons ?? [],
        nodes: this.nodes,
      },
    });
    modalRef.content.onSave.pipe(take(1)).subscribe(teaser => {
      if (!this.bot.styles.teaserButtons) {
        this.bot.styles.teaserButtons = [];
      }
      const index = this.bot.styles.teaserButtons.findIndex(t => t.title === teaser.title);
      if (index === -1) {
        this.bot.styles.teaserButtons.push({
          value: teaser.value ?? '',
          title: teaser.title,
          data: teaser.data ?? {},
          nodeId: teaser.nodeId ?? '',
          type: 'button',
        });
      } else {
        this.toaster.error('A Button with the same title already exists');
      }
    });
  }

  removeTeaserQRB(teaser: BotTeaserModel) {
    const teaserTitle = teaser.title;
    const modalRef = this.modalService.show(AddTeaserQRBModalComponentComponent, {
      ignoreBackdropClick: false,
      initialState: { teaser, nodes: this.nodes, teaserButtons: this.bot.styles.teaserButtons ?? [] },
    });
    modalRef.content.onUpdate.pipe(take(1)).subscribe((updatedTeaser: BotTeaserModel) => {
      if (this.bot.styles.teaserButtons) {
        const index = this.bot.styles.teaserButtons.findIndex(t => t.title === teaserTitle);
        if (index > -1) {
          this.bot.styles.teaserButtons[index].title = updatedTeaser.title;
          this.bot.styles.teaserButtons[index].value = updatedTeaser.value;
          this.bot.styles.teaserButtons[index].nodeId = updatedTeaser.nodeId;
          this.bot.styles.teaserButtons[index].data = updatedTeaser.data;
        }
      }
    });

    modalRef.content.onDelete.pipe(take(1)).subscribe(_deletedTeaser => {
      if (this.bot.styles.teaserButtons) {
        const index = this.bot.styles.teaserButtons.findIndex(t => t.title === teaserTitle);
        if (index > -1) {
          this.bot.styles.teaserButtons.splice(index, 1);
        }
      }
    });
  }

  get teasersQRB() {
    if (this.bot.styles.teaserButtons) {
      return this.bot.styles.teaserButtons;
    }
    return [];
  }
  /**
   *  Add Bot to Config Store
   *  Takes the form submission and creates a single bot config.
   *  TODO: Extend to ENV versions when approach is decided.
   */

  private addBot() {
    if (!this.bot) {
      return;
    }
    this.bot.code = sluggify(this.bot.label);
    // this.bot.config.type = 'live';  // I think we can safely remove this...
    const defaultClientEnvironments = this.clientEnvironmentService.generateInitialEnvironment();
    this.bot.clientEnvironment = defaultClientEnvironments.selectedStage.systemName;
    this.loading = true;
    const uploadObservable: Observable<void | null> = this.getUploadObservable();
    uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.bot) {
            return of();
          }
          return from([
            await this.botsService.addBot(this.bot),
            await this.versionService.setInitialVersion(this.bot, []),
          ]);
        }),
      )
      .subscribe(
        async () => {
          this.loading = false;
          this.saved.emit();
          await this.addPermission();
          this.toaster.success('Assistant created');
        },
        error => {
          this.loading = false;
          // should re-initialise for the next possible 'Save' action
          this.bot.code = '';
          this.bot.label = '';
          this.toaster.error(error);
        },
      );
  }

  private async addPermission() {
    const permission = new PermissionModel();
    permission.userId = this.bot.createdBy;
    permission.hierarchyElementSystemName = this.bot.hierarchyElementSystemName;
    permission.corpId = this.bot.corpId;
    permission.botCode = this.bot.code;
    await this.permissionsService.addPermission(permission);
  }

  private updateBot() {
    if (!this.bot) {
      return;
    }
    this.loading = true;
    const uploadObservable: Observable<void | null> = this.getUploadObservable();
    uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.bot) {
            return of();
          }
          return from([
            await this.botsService.updateBot(this.bot),
            await this.versionService.addNewVersion(this.bot, `Assistant ${this.bot.label} updated.`),
          ]);
        }),
      )
      .subscribe(
        () => {
          this.loading = false;
          this.saved.emit();
          this.toaster.success('Assistant updated');
        },
        error => {
          this.loading = false;
          this.toaster.error(error);
        },
      );
  }

  private getUploadObservable() {
    return this.avatarFile
      ? this.uploadService.upload('bots/' + this.bot.id + '/avatar', this.avatarFile).pipe(
          map(downloadUrl => {
            if (!this.bot) {
              return;
            }
            this.bot.styles.avatarImageUrl = downloadUrl;
          }),
        )
      : of(null);
  }

  private getIsAdmin(): boolean {
    return this.authService.hasPermissionSync(Permissions.IS_ADMIN);
  }

  get scaledAvatarSize() {
    const ratio = this.bot.styles.avatarSize ?? 100;
    const avatarSize = 52;
    return avatarSize * (ratio / 100);
  }
}
