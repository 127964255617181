import { Component, Input, OnInit } from '@angular/core';
import { ShowRecallCampaignsSettings } from './settings/recall-campaigns-settings';
import { ShowRecallCampaignsUsers } from './settings/recall-campaigns-users';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddRecallCampaignModalComponent } from './modal/add-upload-campaign-modal/add-recall-campaign-modal.component';
import { BotConfigService } from '../../../../services/bot-config.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UploadCampaignService } from '../../../../services/upload.campaign.service';
import { LocalDataSource } from 'ng2-smart-table';

const ITEMS_PER_PAGE = 5;

@Component({
  selector: 'app-bot-upload-campaigns',
  templateUrl: './bot-upload-campaigns.component.html',
  styleUrls: ['./bot-upload-campaigns.component.scss'],
})
export class BotUploadCampaignsComponent implements OnInit {
  public isLoading = true;

  public currentStep: string;
  private botConfig: any;
  public recallCampaigns: LocalDataSource;
  public recallUsers: any[];
  @Input() userEmail: string;

  @Input() userName: string;

  @Input() botId: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private modalService: BsModalService,
    private botConfigService: BotConfigService,
    private toasterService: ToastrService,
    private uploadCampaign: UploadCampaignService,
  ) {
    this.currentStep = 'SHOW_RECALL_CAMPAIGNS';
  }

  public showRecallCampaignsSettings: any;
  public showRecallCampaignUsers: any;
  public dealershipId = '';
  async ngOnInit() {
    this.showRecallCampaignsSettings = new ShowRecallCampaignsSettings(this.domSanitizer).get(ITEMS_PER_PAGE);
    this.showRecallCampaignUsers = new ShowRecallCampaignsUsers(this.domSanitizer).get();
    this.botConfig = await this.botConfigService.getBotConfigById(this.botId);
    this.dealershipId = this.botConfig.code;

    const campaignsFromDataSource = await this.uploadCampaign.getConversations(this.dealershipId);
    for (let x = 0; x < campaignsFromDataSource.length % ITEMS_PER_PAGE; x += 1) {
      campaignsFromDataSource.push({
        customersCount: 0,
        dealershipCode: '',
        fileId: '',
        fileName: '',
        id: '',
        maxBatch: 0,
        startDate: '',
        userName: '',
      });
    }
    this.recallCampaigns = new LocalDataSource(campaignsFromDataSource);
    this.recallCampaigns.setPaging(1, ITEMS_PER_PAGE, false);
    this.isLoading = false;
  }
  selectRowRecallCampaign(e): any {}

  addNewCampaign(e): any {
    const resultObservable = new Subject<string>();

    this.modalService.show(AddRecallCampaignModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        userEmail: this.userEmail,
        dealershipId: this.dealershipId,
        result: resultObservable,
        userName: this.userName,
      },
    });
    resultObservable.subscribe(async x => {
      console.log(x);
      this.toasterService.success(x);
      this.recallCampaigns = new LocalDataSource(await this.uploadCampaign.getConversations(this.dealershipId));
      this.recallCampaigns.setPaging(1, ITEMS_PER_PAGE, false);
    });
  }

  goBackCampaign(e): any {
    this.currentStep = 'SHOW_RECALL_CAMPAIGNS';
  }
}
