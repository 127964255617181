import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApiQueryModel } from 'src/app/models/api-query';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { BotModel } from 'src/app/models/bot';
import { SimpleEditorTab } from '../../editors/simple-editor/_types/SimpleEditorTab';
import { BrainService } from 'src/app/services/brain.service';
import { ApiGatewayService } from 'src/app/services/api-gateway-service.service';
import { ActionProgressComponent } from '../../action-progress/action-progress.component';

@Component({
  selector: 'app-api-query-page',
  templateUrl: './api-query-page.component.html',
  styleUrls: ['./api-query-page.component.scss'],
})
export class ApiQueryPageComponent {
  private _bot: BotModel;
  private _apiQuery: ApiQueryModel;

  tabs: SimpleEditorTab[] = [
    {
      id: 'api-query',
      label: 'API Query',
      data: '',
      language: 'graphql',
      logs: [],
    },
    {
      id: 'example-data',
      label: 'Example Data',
      data: '',
      language: 'json',
      logs: [],
    },
  ];

  schema: object;

  @Input()
  permissionToWriteApiQueries: Permissions;

  @Input()
  set bot(bot: BotModel) {
    this._bot = bot;
    this.setSchema();
  }
  get bot() {
    return this._bot;
  }

  @Input()
  set apiQuery(apiQuery: ApiQueryModel) {
    this.tabs[0].data = apiQuery.query;
    this.tabs[1].data = apiQuery.exampleData;
    this._apiQuery = apiQuery;
  }

  get apiQuery() {
    return this._apiQuery;
  }

  @Output()
  updateApiQuery: EventEmitter<ApiQueryModel>;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;

  constructor(
    private authService: AuthService,
    private brainService: BrainService,
    private apiGatewayService: ApiGatewayService,
    private toaster: ToastrService,
  ) {
    this.updateApiQuery = new EventEmitter<ApiQueryModel>();
  }

  canSaveCorpApiQuery(): boolean {
    return this.authService.hasPermissionSync(this.permissionToWriteApiQueries);
  }

  async setSchema() {
    this.schema = await this.apiGatewayService.schema(this.bot.apiGatewayServiceId);
  }

  async saveAll(tabs: SimpleEditorTab[]) {
    this.actionProgress.start();
    const apiQueryTab = tabs[0];
    const dataTab = tabs[1];

    // Validate json
    if (dataTab.data) {
      try {
        JSON.parse(dataTab.data);
      } catch (error) {
        dataTab.logs = [
          {
            className: 'simple-editor-log--error',
            text: error,
          },
        ];
        return;
      }
    }

    // Validate apiQuery
    return this.brainService
      .compileApiQuery({
        source: apiQueryTab.data,
        data: JSON.parse(dataTab.data),
      })
      .subscribe(
        async response => {
          // Direct call to an API endpoint (HACK)
          // TODO Do it properly
          if (response.value.startsWith('https://') || response.value.startsWith('http://')) {
            this.actionProgress.complete();
            this.toaster.success('Saved Direct API call');

            apiQueryTab.logs = [];
            dataTab.logs = [];
            this.apiQuery.query = apiQueryTab.data;
            this.apiQuery.exampleData = dataTab.data;
            this.apiQuery.queryType = 'direct';
            this.apiQuery.queryOperation = 'direct';
            return this.updateApiQuery.emit(this.apiQuery);
          }

          // Validate query
          const validate = (await this.apiGatewayService.validate(this.bot.apiGatewayServiceId, response.value)) as any;
          if (validate?.length) {
            this.toaster.error('Invalid API Query');
            apiQueryTab.logs = validate.map(error => {
              const lineColumn = `Line ${error.locations[0].line}, Column ${error.locations[0].column}`;
              return {
                className: 'simple-editor-log--error',
                text: `${lineColumn}: ${error.message}`,
              };
            });
            return;
          }

          // Extract operation and query type
          const line = apiQueryTab.data.replace(/(\r\n|\n|\r)/gm, '').replace(/\s/g, '');
          const regex = /^(query|mutation){(.*)\(.*}$/gi;
          const regexResult = regex.exec(line.trim());
          if (!regexResult || regexResult.length < 1 || regexResult?.length > 3) {
            this.toaster.error('Invalid API Query');
            apiQueryTab.logs = [
              {
                className: 'simple-editor-log--error',
                text: `Must only have 1 query`,
              },
            ];
            return;
          }
          const queryOperation = regexResult[1];
          const queryType = regexResult[2];
          this.actionProgress.complete();

          this.toaster.success('Saved API Query');
          apiQueryTab.logs = [];
          dataTab.logs = [];
          this.apiQuery.query = apiQueryTab.data;
          this.apiQuery.exampleData = dataTab.data;
          this.apiQuery.queryType = queryType;
          this.apiQuery.queryOperation = queryOperation;
          return this.updateApiQuery.emit(this.apiQuery);
        },
        error => {
          this.actionProgress.complete();
          this.toaster.error('Invalid API Query');
          apiQueryTab.logs = [
            {
              className: 'simple-editor-log--error',
              text: error,
            },
          ];
        },
      );
  }
}
