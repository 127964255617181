import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { AuthService } from 'src/app/services/auth.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { ActivatedRoute } from '@angular/router';
import { getSidebarItems as corpGetSidebarItems } from '../../pages/portal/corp/utils';
import { getSidebarItems as portalGetSidebarItems } from '../../pages/portal/utils';
import { combineLatest, Subscription } from 'rxjs';
import { BotModel, GlobalVariable } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { NodeModel } from 'src/app/models/node';
import firebase from 'firebase';
import { FlowTemplateModel } from 'src/app/models';
import { CorpsService, FlowTemplatesService, NodesService } from 'src/app/services/firestore';
import { FlowEditor } from '../../components/flow/flow-editor/flow-editor.types';
import { Permissions } from 'src/app/utils/permissions/permissions';
import keyBy from 'lodash/keyBy';
import { diff } from 'jsondiffpatch';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import groupBy from 'lodash/groupBy';
import { patch } from 'jsondiffpatch';
import { Dictionary } from 'lodash';
import omit from 'lodash/fp/omit';
import { FlowEditorEvents } from '../flow/flow-editor/events/flow-editor.events';
import { FlowEditorEventsDispatcher } from '../flow/flow-editor/events/flow-editor-events-dispatcher';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-flow-template-editor',
  templateUrl: './flow-template-editor.component.html',
  styleUrls: ['./flow-template-editor.component.scss'],
})
export class FlowTemplateEditorComponent extends FlowEditorEventsDispatcher implements OnInit, OnDestroy, FlowEditor {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  private flowEditorEventsSubscription: Subscription;

  private user: firebase.User;
  corp: CorpModel;
  corpId: string;
  bot: BotModel;
  flowTemplate: FlowTemplateModel;
  nodes: NodeModel[] = [];
  addedNodes: NodeModel[] = [];
  dirtyNodes: NodeModel[] = [];
  pristineNodes: NodeModel[] = [];
  deletedNodeIds: string[] = [];
  loading = true;
  private isGlobal: boolean;
  private hasChanges: boolean;
  private changesHandlingStrategy = 'LEAVE_ALL_DERIVED_NODES';

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private nodesService: NodesService,
    private flowTemplatesService: FlowTemplatesService,
    private authService: AuthService,
    private headerService: HeaderService,
    private flowEditorEvents: FlowEditorEvents,
  ) {
    super();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges;
  }

  async ngOnInit() {
    this.flowEditorEventsSubscription = this.flowEditorEvents.editorEvents$.subscribe(flowEditorEvent => {
      this.handleFlowEditorEvent(flowEditorEvent);
    });
    this.loading = true;
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      results1 => {
        const params = results1[0];
        const user = results1[1];
        const corpId = params.get('corp');
        const flowTemplateId = params.get('flowTemplateId');
        if (!flowTemplateId || !user) {
          return;
        }
        if (!corpId) {
          this.isGlobal = true;
          this.corpId = 'global'; // Just for input validation sake
        }
        this.user = user;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(`${corpId}`),
          this.flowTemplatesService.getFlowTemplateById(flowTemplateId),
          this.flowTemplatesService.getFlowTemplatesNodes(flowTemplateId).pipe(take(1)).toPromise(),
        ]).subscribe(async ([corp, flowTemplate, nodes]) => {
          if (!flowTemplate) {
            return;
          }
          const bot = flowTemplate.getBotConfig;
          if (!bot) {
            return;
          }
          this.bot = bot;
          if (corp) {
            this.corp = corp;
            this.corpId = corp.id;
          }
          this.flowTemplate = flowTemplate;
          this.nodes = nodes;
          this.loading = false;
          this.setNodesChangesTrackers();
          this.refreshUI();
        });
      },
    );
  }

  editorHasChanges(hasChanges: boolean) {
    this.hasChanges = hasChanges;
  }

  saveGlobalVariables(globalVariables: GlobalVariable[]) {
    this.flowTemplate.sourceBot = JSON.stringify({ ...this.bot, globalVariables });
  }

  async saveChanges() {
    this.actionProgress.start();
    try {
      const newLastTouchedHash = uuidv4();
      const { systemName, lastTouchedHash } = this.flowTemplate;
      const currentNodesState = this.dirtyNodes.map((node: NodeModel) => ({
        ...node,
        botId: this.flowTemplate.systemName,
      }));
      await this.flowTemplatesService.saveFlowTemplateNodes(systemName, currentNodesState);

      this.flowTemplate.lastTouchedHash = newLastTouchedHash;
      this.flowTemplate.lastTouchedBy = this.user.uid;
      this.flowTemplate.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      this.flowTemplate.numberOfNodes = currentNodesState.length;
      this.flowTemplatesService.updateFlowTemplate(this.flowTemplate);

      if (this.changesHandlingStrategy === 'TOUCH_ALL_DERIVED_NODES') {
        const derivedNodes = await this.nodesService.getNodesDerivedFromFlowTemplate(systemName);
        const derivedNodesThatAreInSyncWithCurrentFlowTemplate = derivedNodes.filter(
          ({ flowTemplateLastTouchedHash }) => lastTouchedHash === flowTemplateLastTouchedHash,
        );

        const idsOfTheBotsThatAreInSync = derivedNodesThatAreInSyncWithCurrentFlowTemplate.reduce(
          (botIds, { botId }) => botIds.add(botId),
          new Set<string>(),
        );
        const nodesByBotId = groupBy(derivedNodes, 'botId');
        await this.handleCreation(idsOfTheBotsThatAreInSync, newLastTouchedHash, nodesByBotId);
        await this.handleDeletion(nodesByBotId);
        await this.handleUpdates(derivedNodesThatAreInSyncWithCurrentFlowTemplate, newLastTouchedHash);
      }
      this.toaster.success('Changes saved successfully');
      this.setNodesChangesTrackers();
    } catch (error) {
      this.toaster.error(error);
    }
    this.actionProgress.complete();
  }

  streamLine(nodes: NodeModel[]) {
    const nodes$1 = nodes.map(node => {
      NodeModel.removeExcluded(node);
      const { botId, x, y, ...changesToTrack } = node;
      return changesToTrack;
    });
    return JSON.parse(JSON.stringify(keyBy(nodes$1, 'id')));
  }

  createNode(node: NodeModel) {
    this.nodes.push(node);

    this.addedNodes = [...this.addedNodes, node];
    this.dirtyNodes = [...this.dirtyNodes, node];
  }

  updateNode(node: NodeModel) {
    const nodeIndex = this.dirtyNodes.findIndex(n => n.id === node.id);
    if (nodeIndex > -1) {
      this.dirtyNodes[nodeIndex] = { ...node };
      return;
    }
    const addedNodeIndex = this.addedNodes.findIndex(n => n.id === node.id);
    if (addedNodeIndex > -1) {
      this.addedNodes[addedNodeIndex] = { ...node };
      return;
    }
  }

  deleteNode(node: NodeModel) {
    this.dirtyNodes = this.dirtyNodes.filter(n => n.id !== node.id);
    this.dirtyNodes = this.dirtyNodes.map(n => {
      if (!n.connections) {
        return n;
      }
      n.connections = n.connections.filter(c => c.id !== node.id);
      return n;
    });
    this.deletedNodeIds.push(node.id);
  }

  canManageFlowTemplate(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_MANAGE_FLOW_TEMPLATES);
  }

  private refreshUI() {
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
    this.headerService.setPageTitle(`${this.flowTemplate.label} Flow`);
  }

  private setSidebarItems(corpId: string) {
    if (this.isGlobal) {
      this.sidebarService.set(portalGetSidebarItems());
    } else {
      this.sidebarService.set(corpGetSidebarItems(corpId));
    }
  }

  private setBreadcrumb(corp: CorpModel) {
    if (this.isGlobal) {
      this.breadcrumbService.set([
        {
          label: 'Global Flow Templates',
          route: '/portal/global-flow-templates',
        },
      ]);
    } else {
      this.breadcrumbService.set([
        {
          label: corp.label,
          icon: corp.logo,
          route: `corps/${corp.id}`,
          testId: 'bread-crumb-corp',
        },
        {
          label: 'Flow Templates',
          route: `corps/${corp.id}/flow-templates`,
        },
      ]);
    }
  }

  private async handleCreation(
    botIds: Set<string>,
    newLastTouchedHash: string,
    nodesByBotId: Dictionary<NodeModel[]>,
  ): Promise<void> {
    let nodeCreationPromises: Promise<void>[] = [];
    botIds.forEach(botId => {
      const nodeCreationPromises$1 = this.addedNodes.map(async node => {
        const newNodeId = uuidv4();
        const oldNodeId = node.id;
        node.id = newNodeId;

        node = this.handleConnectionsForNewlyCreateNodes(node, nodesByBotId[botId]);

        node.flowTemplateId = this.flowTemplate.systemName;
        node.flowTemplateNodeId = oldNodeId;
        node.flowTemplateLastTouchedHash = newLastTouchedHash;
        await this.nodesService.addNode({ ...node, botId });
      });
      nodeCreationPromises = nodeCreationPromises.concat(nodeCreationPromises$1);
    });
    await Promise.all(nodeCreationPromises);
  }

  private handleConnectionsForNewlyCreateNodes(node$1: NodeModel, nodes: NodeModel[]) {
    let node = Object.assign({}, node$1);
    const { connections } = node;
    if (!connections) {
      return node;
    }
    connections.forEach((connection, index) => {
      const correspondingNodeInDerivation = nodes.find(
        ({ flowTemplateNodeId }) => flowTemplateNodeId === connection.id,
      );
      if (correspondingNodeInDerivation && connections) {
        connections[index].id = correspondingNodeInDerivation.id;
        node = { ...node, connections: [...connections] };
      }
    });
    return node;
  }

  private async handleDeletion(nodesByBotId: Dictionary<NodeModel[]>) {
    const nodeDeletionPromises: Promise<void>[] = [];
    Object.entries(nodesByBotId).forEach(([botId, nodes]) => {
      const nodesToDeleteIds = nodes
        .filter(({ flowTemplateNodeId }) => this.deletedNodeIds.includes(`${flowTemplateNodeId}`))
        .map(({ id }) => id);
      if (nodesToDeleteIds.length > 0) {
        const promise = this.nodesService.removeNodesInBot(botId, nodesToDeleteIds);
        nodeDeletionPromises.push(promise);
      }
    });
    return nodeDeletionPromises;
    // I am deleting flow nodes in the derived nodes if connected to others or not
  }

  private async handleUpdates(nodesToUpdate: NodeModel[], newLastTouchedHash: string) {
    const changes = diff(this.streamLine(this.pristineNodes), this.streamLine(this.dirtyNodes)) as object;
    let promises: Promise<void>[] = [];
    Object.entries(changes).forEach(([nodeId, change]) => {
      const createdAndDeletedIds = this.deletedNodeIds.concat(this.addedNodes.map(({ id: id$1 }) => id$1));
      if (createdAndDeletedIds.includes(nodeId)) {
        return;
      }
      const { id, ...changesToApply } = change;
      const nodesToUpdate$1 = nodesToUpdate.filter(({ flowTemplateNodeId }) => flowTemplateNodeId === nodeId);
      const updatePromise = nodesToUpdate$1.map(async node => {
        let changesToApply$1 = changesToApply;
        if (node.flowTemplateCustomizations) {
          changesToApply$1 = omit(node.flowTemplateCustomizations, changesToApply);
        }
        patch(node, changesToApply$1);
        node.flowTemplateLastTouchedHash = newLastTouchedHash;
        await this.nodesService.updateNode(node);
      });
      promises = promises.concat(updatePromise);
    });
    return await Promise.all(promises);
  }

  private setNodesChangesTrackers() {
    this.pristineNodes = JSON.parse(JSON.stringify(this.nodes));
    this.dirtyNodes = JSON.parse(JSON.stringify(this.nodes));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.flowEditorEventsSubscription) {
      this.flowEditorEventsSubscription.unsubscribe();
    }
  }
}
