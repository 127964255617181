import { Component } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BotModel } from 'src/app/models';
import { GlobalVariableType, GlobalVariable } from 'src/app/models/bot';
import { BotsService } from 'src/app/services/firestore';
@Component({
  selector: 'app-complete-bot-information-modal',
  templateUrl: './complete-bot-information-modal.component.html',
  styleUrls: ['./complete-bot-information-modal.component.scss'],
})
export class CompleteBotInformationModalComponent {
  bot: BotModel;
  invalidBotVariables: GlobalVariable[] = [];
  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private botsService: BotsService,
    private toaster: ToastrService,
  ) {
    const { bot } = options.initialState as any;
    this.bot = bot;
    this.invalidBotVariables = this.bot.globalVariables.filter(gv => {
      return gv.type === GlobalVariableType.BOT && !gv.defaultValue;
    });
  }

  cancelModal() {
    this.modal.hide();
  }

  async saveBotVariable() {
    // save bot information and close modal

    const invalidValues = this.invalidBotVariables.filter(gv => !gv.defaultValue);

    if (invalidValues.length > 0) {
      this.toaster.error('All fields must be filled.');
      return;
    }

    this.invalidBotVariables.forEach(gv => {
      this.bot?.globalVariables.forEach(variable => {
        if (variable.label === gv.label) {
          variable.defaultValue = gv.defaultValue;
        }
      });
    });
    if (this.bot) {
      await this.botsService.updateBot(this.bot);
      this.modal.hide();
      document.location.href = document.location.href;
    }
  }
}
