import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { UsersService } from './firestore/users.service';
import { UserModel } from '../models';
import { RolesService } from './firestore/roles.service';
import { Permissions } from '../utils/permissions/permissions';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<firebase.default.User | null>;
  private _currentUserProfile: UserModel | null;
  private _currentUserPermissions: Permissions[];
  public redirectUrl: string;
  constructor(private afAuth: AngularFireAuth, private usersService: UsersService, private rolesService: RolesService) {
    this.user$ = this.afAuth.user;
  }
  login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // changed the persistence from session to local to allow new tabs to be opened and use the same session
      this.afAuth.setPersistence('local').then(() => {
        this.afAuth
          .signInWithEmailAndPassword(email, password)
          .then(async res => {
            resolve(res);
            await this.fetchAndSetUserProfileAndPermissions();
            if (this.redirectUrl && this.redirectUrl.length > 0) {
              document.location.href = this.redirectUrl;
            }
          })
          .catch(error => {
            const errorCode = error.code;
            const errorMessage = error.message;
            reject(errorMessage + errorCode);
          });
      });
    });
  }

  get currentUser(): Promise<firebase.default.User | null> {
    return this.afAuth.currentUser;
  }

  async getCurrentUserProfile(): Promise<UserModel | null> {
    if (!this._currentUserProfile) {
      await this.fetchAndSetUserProfileAndPermissions();
    }
    return this._currentUserProfile;
  }

  async getCurrentUserPermissions(): Promise<Permissions[]> {
    // For the weird case that the role has no permission then we will making too many calls
    if (!this._currentUserProfile) {
      await this.fetchAndSetUserProfileAndPermissions();
    }
    return this._currentUserPermissions;
  }

  async hasPermission(permission: Permissions): Promise<boolean> {
    const userPermissions = await this.getCurrentUserPermissions();
    if (!userPermissions) {
      return false;
    }
    return userPermissions.includes(permission);
  }

  // For the templates and since the permissions will be set by then
  hasPermissionSync(permission: Permissions): boolean {
    if (!this._currentUserPermissions) {
      return false;
    }
    return this._currentUserPermissions.includes(permission);
  }

  logout() {
    this.reset();
    this._currentUserProfile = null;
    this._currentUserPermissions = [];
    return this.afAuth.signOut();
  }

  private async fetchAndSetUserProfileAndPermissions(): Promise<void> {
    const currentUser = await this.currentUser;
    if (!currentUser) {
      return;
    }
    const user = await this.usersService.getUserById(currentUser?.uid).pipe(take(1)).toPromise();
    if (!user) {
      return;
    }
    this._currentUserProfile = user;

    if (!user.role) {
      // TODO: Alert the user to tell the admin to give him a role
      return;
    }

    const role = await this.rolesService.getRoleById(user.role).pipe(take(1)).toPromise();
    if (!role) {
      // TODO: Alert the user to tell the admin to give him a correct role
      return;
    }

    if (role.permissions) {
      this._currentUserPermissions = role.permissions;
    }
  }

  private reset() {
    localStorage.clear();
  }
}
