import Handlebars from 'handlebars';
import { v4 as uuidv4 } from 'uuid';

const handlebarsProgramToJson = (program): any => {
  const bodies = program.body.filter(({ type }) => ['BlockStatement', 'MustacheStatement'].includes(type));
  return bodies.map(body => {
    let params;
    if (body.hash) {
      params = {};
      body.hash.pairs.forEach(({ key, value }) => {
        if (value.type === 'PathExpression') {
          params[key] = value.original;
        } else if (value.type === 'StringLiteral') {
          params[key] = `"${value.original}"`;
        } else {
          params[key] = `(${value.path.original} "${value.params.map(param => param.value).join(', ')}")`;
        }
      });
    }

    const output = { element: null, body: null, params: null, guid: null };
    const element = body.path.original;
    const outputBody = body.program ? handlebarsProgramToJson(body.program) : {};

    if (element) {
      output.element = element;
    }
    if (outputBody) {
      output.body = outputBody;
    }
    if (params) {
      output.params = params;
    }

    output.guid = uuidv4();

    return output;
  });
};

export const handlebarsToJson = (template: string): any => {
  const json = handlebarsProgramToJson(Handlebars.parse(template));
  return json.length ? json : null;
};

export const jsonToHandlebars = (json: any, indentationSpaces = 2, currentIndentation = 0): string => {
  const output: string[] = [];
  const data = Array.isArray(json) ? json : [json];
  const [KEY_INDEX, VALUE_INDEX] = [0, 1];
  for (const template of data) {
    const { element, body, params, isFinal } = template;
    const paramsText =
      params && Object.keys(params).length
        ? ' ' +
          Object.entries(params)
            .filter(param => param[KEY_INDEX] && param[VALUE_INDEX])
            .map(param => `${param[KEY_INDEX]}=${param[VALUE_INDEX]}`)
            .join(' ')
        : '';

    if (isFinal) {
      output.push(`${' '.repeat(currentIndentation)}{{${element}${paramsText}}}`);
    } else if (body && body.length) {
      output.push(`${' '.repeat(currentIndentation)}{{#${element}${paramsText}}}`);
      output.push(jsonToHandlebars(body, indentationSpaces, currentIndentation + indentationSpaces));
      output.push(`${' '.repeat(currentIndentation)}{{/${element}}}`);
    } else {
      output.push(`${' '.repeat(currentIndentation)}{{#${element}${paramsText}}}{{/${element}}}`);
    }
  }

  return output.length > 0 ? output.join('\n') : '';
};
