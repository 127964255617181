export const sluggify = (word: string): string =>
  word
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, '_'); // Please dont change this to `-`. Some logic has been baked with this

const upperCaseFirst = (word: string) => word && word[0].toUpperCase() + word.slice(1);

export const makeSystemNameUserFriendly = (word: string): string =>
  word
    .toLowerCase()
    .split('_')
    .map(upperCaseFirst)
    .join(' ');

export const tryParseToJson = (value: string): object => {
  try {
    return JSON.parse(value);
  } catch {
    return {};
  }
};

export function parseJsonIfValid<T>(value: string): T | false {
  try {
    return JSON.parse(value);
  } catch {
    return false;
  }
}

export function sortDesc(stringsArray: string[]) {
  return stringsArray.sort((n1, n2) => (n1 > n2 ? -1 : 1));
}
