import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddBotModalComponent } from 'src/app/components/modals/add-bot-modal/add-bot-modal.component';
import { BotModel } from 'src/app/models/bot';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { CorpModel } from 'src/app/models/corp';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import {
  HierarchyElementsService,
  CorpHierarchiesService,
  CorpsService,
  BotsService,
} from 'src/app/services/firestore';
import { HierarchyElementModel, CorpHierarchyModel } from 'src/app/models';
import { SidebarService } from 'src/app/services/sidebar.service';
import { getSidebarItems, getBreadcrumbItems } from '../utils';

@Component({
  selector: 'app-hierarchy-element-bots-admin',
  templateUrl: './hierarchy-element-bots-admin.component.html',
  styleUrls: ['./hierarchy-element-bots-admin.component.scss'],
})
export class HierarchyElementBotsAdminComponent implements OnInit, OnDestroy {
  corp: CorpModel | null;
  hierarchyElement: HierarchyElementModel | null;

  bots: BotModel[];
  parentSystemName: string;
  corpHierarchy: CorpHierarchyModel;
  loading: boolean;
  elementTitle: string;
  parentElements: Array<HierarchyElementModel | null>;
  hierarchyElementSystemName: string | null;

  private corpHierarchies: CorpHierarchyModel[];
  private paramMapSubscription: Subscription;
  private dataSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private botsService: BotsService,
    private corpsService: CorpsService,
    private hierarchyElementsService: HierarchyElementsService,
    private corpHierarchiesService: CorpHierarchiesService,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private breadcrumbService: BreadcrumbService,
    private toaster: ToastrService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      const corpId = params.get('corp');
      const hierarchy = params.get('hierarchy');
      this.hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      if (!corpId || !hierarchy || !this.hierarchyElementSystemName) {
        return;
      }
      this.parentSystemName = corpId;
      if (this.hierarchyElementSystemName) {
        this.parentSystemName += `-${this.hierarchyElementSystemName}`;
      }
      this.loading = true;
      this.dataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.hierarchyElementsService.getHierarchyElement(this.parentSystemName),
        this.botsService.getBotsByHierarchyElement(this.parentSystemName),
        this.corpHierarchiesService.getCorpHierarchies(corpId),
        this.hierarchyElementsService.getHierarchyElementWithParentElements(this.parentSystemName),
      ]).subscribe(
        ([corp, hierarchyElement, bots, corpHierarchies, parentElements]) => {
          const corpHierarchy = corpHierarchies.find(({ systemName }) => systemName === hierarchy);
          if (!corpHierarchy) {
            throw new Error(`Hierarchy Doesn't Exist`);
          }

          this.loading = false;
          this.corp = corp;
          this.hierarchyElement = hierarchyElement;
          this.bots = bots;
          this.corpHierarchies = corpHierarchies;
          this.parentElements = parentElements;

          this.elementTitle = this.parentElements.map(parentElement => parentElement?.label).join(' - ');

          this.corpHierarchy = corpHierarchy;
          this.refreshUI();
          this.setSidebarItems();
        },
        error => {
          this.toaster.error(error);
          this.loading = false;
        },
      );
    });
  }

  openAddBotModal() {
    if (!this.corp || !this.hierarchyElement) {
      return;
    }
    this.modalService.show(AddBotModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpId: this.corp.id,
        hierarchyElementId: this.hierarchyElement.id,
        hierarchyElementSystemName: this.hierarchyElement.systemName,
        botNameList: this.bots.map(bot => bot.code),
      },
    });
  }

  confirmAndDeleteBot(bot: BotModel) {
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.typeOfEntity = 'Bot';
    modalRef.content.entityValue = bot.label;
    modalRef.content.onDeleteConfirmed.subscribe(() => {
      this.deleteBot(bot);
    });
  }

  private async deleteBot(bot: BotModel) {
    try {
      if (this.hierarchyElement) {
        await this.botsService.removeBot(bot.code, bot.corpId, this.hierarchyElement.systemNameForUrl);
        this.toaster.success('Assistant deleted');
      }
    } catch (error) {
      this.toaster.error(error);
    }
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  refreshUI() {
    if (this.hierarchyElement) {
      this.setBreadcrumb();
      this.headerService.setPageTitle(`${this.elementTitle} Assistants`);
    }
  }

  private setSidebarItems() {
    this.sidebarService.set(
      getSidebarItems(`${this.corp?.id}`, this.corpHierarchies, this.corpHierarchy, this.parentSystemName),
    );
  }

  private setBreadcrumb() {
    if (!this.corp) {
      return;
    }
    this.breadcrumbService.set(getBreadcrumbItems(this.corp, this.corpHierarchies, this.parentElements));
  }
}
