import { CountingPeriodType } from './CountingPeriodType';

export class CountingPeriod {
  // Used to render the description to the DOM
  public readonly description: string;
  private readonly type: CountingPeriodType;

  constructor(type: CountingPeriodType) {
    this.type = type;
    this.description = this.getCountingPeriodDescription();
  }

  getType(): CountingPeriodType {
    return this.type;
  }

  getCountingPeriodDescription(): string {
    switch (this.type) {
      case '1d':
      case 'day':
        return 'Daily';
      case '1w':
        return 'Weekly';
      case '1M':
        return 'Monthly';
      default:
        throw new Error(`Description for period ${this.type} is not supported!`);
    }
  }

  equals(obj: CountingPeriod | null | undefined): boolean {
    return !!obj && this.type === obj.type;
  }
}
