import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConversationSummary } from './_type/ConversationSummary';

@Component({
  selector: 'app-conversation-summary',
  templateUrl: './conversation-summary.component.html',
  styleUrls: ['./conversation-summary.component.scss']
})
export class ConversationSummaryComponent implements OnInit {
  @Input()
  conversationSummary: ConversationSummary;

  @Input()
  forceShowConversation: boolean;

  @Input()
  selectedConversationId: string;

  @Output()
  conversationSelected: EventEmitter<void>;

  constructor() {
    this.conversationSelected = new EventEmitter<void>();
  }

  ngOnInit() {}
}
