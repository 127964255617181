import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { BrainService } from 'src/app/services/brain.service';
import { CorpModel } from 'src/app/models/corp';
import { PermissionModel } from 'src/app/models/permission';
import { InputValidationModel } from 'src/app/models/input-validation';
import { getSidebarItems } from '../utils';
import { SimpleEditorTab } from 'src/app/components/editors/simple-editor/_types/SimpleEditorTab';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { CorpsService, InputValidationsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-corp-input-validation',
  templateUrl: './corp-input-validation.component.html',
  styleUrls: ['./corp-input-validation.component.scss'],
})
export class CorpInputValidationComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  corp: CorpModel;
  permissions: PermissionModel[];
  inputValidation: InputValidationModel;
  loading: boolean;
  tabs: SimpleEditorTab[] = [
    {
      id: 'input-validation',
      label: 'Input Validation',
      data: '',
      language: 'plaintext',
      logs: [],
    },
    {
      id: 'example-data',
      label: 'Example Data',
      data: '',
      language: 'plaintext',
      logs: [],
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private toaster: ToastrService,
    private brainService: BrainService,
    private corpsService: CorpsService,
    private inputValidationsService: InputValidationsService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      results1 => {
        const params = results1[0];
        const user = results1[1];
        const corpId = params.get('corp');
        const inputValidationSystemName = params.get('inputValidation');
        if (!corpId || !user || !inputValidationSystemName) {
          return;
        }
        this.loading = true;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.inputValidationsService.getInputValidationsBySystemName(inputValidationSystemName),
        ]).subscribe(
          ([corp, inputValidation]) => {
            if (!corp || !inputValidation) {
              return;
            }
            this.loading = false;
            this.corp = corp;
            this.inputValidation = inputValidation[0];
            this.tabs[0].data = this.inputValidation.value;
            this.tabs[1].data = this.inputValidation.exampleData;
            this.refreshUI();
          },
          error => {
            this.loading = false;
            this.toaster.error(error);
          },
        );
      },
    );
  }

  refreshUI() {
    this.headerService.setPageTitle(`${this.corp.label} Input Validation`);
    this.setBreadcrumb(this.corp, this.inputValidation);
    this.setSidebarItems(this.corp.id);
  }

  private setBreadcrumb(corp: CorpModel, inputValidation: InputValidationModel) {
    this.breadcrumbService.set([
      {
        label: corp.label,
        icon: corp.logo,
        route: `corps/${corp.id}`,
        testId: 'bread-crumb-corp',
      },
      {
        label: 'Settings',
        route: `corps/${corp.id}/settings`,
      },
      {
        label: 'Input Validations',
        route: `corps/${corp.id}/input-validations`,
      },
      {
        label: this.inputValidation.name,
        route: `corps/${corp.id}/input-validations/${inputValidation.systemName}`,
      },
    ]);
  }

  private setSidebarItems(corpId: string) {
    this.sidebarService.set(getSidebarItems(corpId));
  }

  canWriteCorpInputValidations(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_WRITE_CORP_INPUT_VALIDATIONS);
  }

  saveAll(tabs: SimpleEditorTab[]) {
    const inputValidationTab = tabs[0];
    const exampleDataTab = tabs[1];

    // Validate input validation
    return this.brainService
      .compileInputValidation({
        source: inputValidationTab.data,
        data: exampleDataTab.data,
      })
      .subscribe(
        response => {
          this.toaster.success('Saved Input Validation');
          inputValidationTab.logs = [];
          exampleDataTab.logs = [];
          this.inputValidation.value = inputValidationTab.data;
          this.inputValidation.exampleData = exampleDataTab.data;
          return this.inputValidationsService.updateInputValidation(this.inputValidation);
        },
        error => {
          this.toaster.error('Invalid Input Validation');
          inputValidationTab.logs = [
            {
              className: 'simple-editor-log--error',
              text: error,
            },
          ];
        },
      );
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
