import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { RoleModel } from 'src/app/models';
import { sluggify } from 'src/app/utils/strings/string.utils';
import { RolesService } from 'src/app/services/firestore';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { ManageRoleModel } from '../_shared/manage-role-model.class';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { getPermissionsToAdd, getPermissionsToRemove } from '../utils';
import { SelectPermissionValue } from '../types';

@Component({
  selector: 'app-add-role-modal',
  templateUrl: './add-role-modal.component.html',
  styleUrls: ['./add-role-modal.component.scss'],
})
export class AddRoleModalComponent extends ManageRoleModel implements OnInit {
  role: RoleModel;
  loading: boolean;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private rolesService: RolesService,
    public modal: BsModalRef,
    private toaster: ToastrService,
    private authService: AuthService,
  ) {
    super();
    this.role = new RoleModel();
  }

  async ngOnInit() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }

    this.role.lastTouchedBy = user.uid;
  }

  newPermissionAdded(permission: Permissions) {
    this.role.permissions = getPermissionsToAdd(this.role.permissions, permission);
  }

  permissionRemoved({ value }: SelectPermissionValue) {
    this.role.permissions = getPermissionsToRemove(this.role.permissions, value);
  }

  async save() {
    if (this.role.permissions.length === 0) {
      this.toaster.info('You need at least a permission to create a role');
      return;
    }
    this.role.systemName = sluggify(this.role.name);
    this.actionProgress.start();
    this.loading = true;
    try {
      await this.rolesService.addRole(this.role);
      this.modal.hide();
      this.toaster.success('Role created');
    } catch (error) {
      this.toaster.error(error);
    }
    this.loading = false;
    this.actionProgress.complete();
  }
}
