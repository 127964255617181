import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { NodeModel } from 'src/app/models/node';
import { Subject } from 'rxjs';
import { BotTeaserModel } from 'src/app/models/bot';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-add-teaser-qrbmodal-component',
  templateUrl: './add-teaser-qrbmodal-component.component.html',
  styleUrls: ['./add-teaser-qrbmodal-component.component.scss'],
})
export class AddTeaserQRBModalComponentComponent implements OnInit {
  @Input()
  nodes: NodeModel[];
  onSave: EventEmitter<BotTeaserModel>;
  onUpdate: EventEmitter<BotTeaserModel>;
  onDelete: EventEmitter<BotTeaserModel>;
  buttonTitle: string;
  buttonValue: string;
  selectedNodeId: string;
  teaserButtons: BotTeaserModel[];
  teaser: BotTeaserModel;

  constructor(
    public modal: BsModalRef,
    private modalService: BsModalService,
    private options: ModalOptions,
    private toaster: ToastrService,
  ) {
    //
    this.onSave = new EventEmitter();
    this.onDelete = new EventEmitter();
    this.onUpdate = new EventEmitter();
    const { teaser, nodes, teaserButtons } = options.initialState as any;
    this.teaser = teaser;
    this.teaserButtons = teaserButtons;
    this.nodes = nodes;

    if (this.teaser) {
      this.buttonTitle = `${this.teaser.title}`;
      this.buttonValue = `${this.teaser.value}`;
      this.selectedNodeId = `${this.teaser.nodeId}`;
    }
  }

  ngOnInit() {}

  deleteButton() {
    const modalRef = this.modalService.show(ConfirmationModalComponent, {});
    modalRef.content.title = 'Delete Teaser Button';
    modalRef.content.message = `Are you sure you want to delete the teaser button : ${this.teaser.title}?`;
    modalRef.content.confirm.pipe(take(1)).subscribe(() => {
      modalRef.hide();
      this.onDelete.emit(this.teaser);
      this.modal.hide();
    });
  }

  save() {
    // ensure title
    if (!this.buttonTitle) {
      this.toaster.error('Title is required.');
      return;
    }

    // ensure unique button title
    const index = this.teaserButtons.findIndex(
      t => t.title.trim().toLowerCase() === this.buttonTitle.trim().toLowerCase(),
    );
    if (index !== -1) {
      this.toaster.error('A button with this title already exists.');
      return;
    }

    // send update
    if (this.teaser) {
      this.onUpdate.emit({
        title: this.buttonTitle,
        value: this.buttonValue,
        type: 'button',
        nodeId: this.selectedNodeId,
        data: {},
      });
    } else {
      // send save action
      this.onSave.emit({
        title: this.buttonTitle,
        value: this.buttonValue,
        type: 'button',
        nodeId: this.selectedNodeId,
        data: {},
      });
    }
    this.modal.hide();
  }
}
