import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NodeModel } from 'src/app/models/node';
import { GlobalVariable } from 'src/app/models/bot';

export enum FlowEventEditorTypes {
  EDITOR_HAS_CHANGES = 'EDITOR_HAS_CHANGES',
  SAVE_CHANGES = 'SAVE_CHANGES',
  UPDATE_NODE = 'UPDATE_NODE',
  DELETE_NODE = 'DELETE_NODE',
  CREATE_NODE = 'CREATE_NODE',
  SAVE_GLOBAL_VARIABLES = 'SAVE_GLOBAL_VARIABLES'
}

interface INodeEvent {
  payload: NodeModel;
}

export interface IUpdateNodeEvent extends INodeEvent {
  type: FlowEventEditorTypes.UPDATE_NODE;
}

export interface ICreateNodeEvent extends INodeEvent {
  type: FlowEventEditorTypes.CREATE_NODE;
}

export interface IDeleteNodeEvent extends INodeEvent {
  type: FlowEventEditorTypes.DELETE_NODE;
}

export interface IEditorHasChangedEvent {
  type: FlowEventEditorTypes.EDITOR_HAS_CHANGES;
  payload: boolean;
}

export interface ISaveGlobalVariablesEvent {
  type: FlowEventEditorTypes.SAVE_GLOBAL_VARIABLES;
  payload: GlobalVariable[];
}

export interface ISaveChangesEvent {
  type: FlowEventEditorTypes.SAVE_CHANGES;
}

export type IFlowEditorEvent =
  | IEditorHasChangedEvent
  | IUpdateNodeEvent
  | IDeleteNodeEvent
  | ICreateNodeEvent
  | ISaveGlobalVariablesEvent
  | ISaveChangesEvent
  | null;

@Injectable({
  providedIn: 'root'
})
export class FlowEditorEvents {
  private editorEvents: BehaviorSubject<IFlowEditorEvent>;
  editorEvents$: Observable<IFlowEditorEvent>;

  constructor() {
    this.editorEvents = new BehaviorSubject<IFlowEditorEvent>(null);
    this.editorEvents$ = this.editorEvents.asObservable();
  }

  private next(event: IFlowEditorEvent) {
    this.editorEvents.next(event);
  }

  emitUpdateNode(node: NodeModel) {
    this.next({
      type: FlowEventEditorTypes.UPDATE_NODE,
      payload: node
    });
    this.emitEditorHasChanges();
  }

  emitSaveChanges() {
    this.next({
      type: FlowEventEditorTypes.SAVE_CHANGES
    });
    this.emitEditorHasChanges(false);
  }

  emitCreateNode(node: NodeModel) {
    this.next({
      type: FlowEventEditorTypes.CREATE_NODE,
      payload: node
    });
    this.emitEditorHasChanges();
  }

  emitDeleteNode(node: NodeModel) {
    this.next({
      type: FlowEventEditorTypes.DELETE_NODE,
      payload: node
    });
    this.emitEditorHasChanges();
  }

  emitEditorHasChanges(hasChanges = true) {
    this.next({
      type: FlowEventEditorTypes.EDITOR_HAS_CHANGES,
      payload: hasChanges
    });
  }

  emitSaveGlobalVariables(globalVariables: GlobalVariable[]) {
    this.next({
      type: FlowEventEditorTypes.SAVE_GLOBAL_VARIABLES,
      payload: globalVariables
    });
    this.emitEditorHasChanges();
  }
}
