import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CorpModel } from 'src/app/models/corp';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, combineLatest } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from '../../../../services/sidebar.service';
import { HierarchyElementModel, CorpHierarchyModel } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { HierarchyElementsService, CorpHierarchiesService, CorpsService } from 'src/app/services/firestore';
// tslint:disable-next-line:max-line-length
import { AddHierarchyElementModalComponent } from 'src/app/components/modals/add-hierarchy-element-modal/add-hierarchy-element-modal.component';
import { EditHierarchyElementModalComponent } from './_components/edit-hierarchy-element-modal/edit-hierarchy-element-modal.component';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { getSidebarItems, getBreadcrumbItems } from '../utils';

@Component({
  selector: 'app-hierarchy-elements-admin',
  templateUrl: './hierarchy-elements-admin.component.html',
  styleUrls: ['./hierarchy-elements-admin.component.scss'],
})
export class HierarchyElementsAdminComponent implements OnInit, OnDestroy {
  loading: boolean;
  hierarchyElements: HierarchyElementModel[];
  parentElements: Array<HierarchyElementModel | null>;
  corp: CorpModel | null;
  corpHierarchy: CorpHierarchyModel;
  nextDownLevelCorpHierarchy: CorpHierarchyModel | undefined;
  elementTitle: string | undefined;
  private paramMapSubscription: Subscription;
  private dataSubscription: Subscription;
  private hierarchyElementSystemName: string;
  private corpHierarchies: CorpHierarchyModel[];

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private hierarchyElementsService: HierarchyElementsService,
    private corpHierarchiesService: CorpHierarchiesService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private modalService: BsModalService,
    private corpsService: CorpsService,
    private toaster: ToastrService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.refreshHierarchyElements();
  }

  private refreshHierarchyElements() {
    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      const corpId = params.get('corp');
      const hierarchy = params.get('hierarchy');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');

      if (!corpId || !hierarchy) {
        return;
      }

      this.hierarchyElementSystemName = hierarchyElementSystemName ? `${corpId}-${hierarchyElementSystemName}` : corpId;

      this.loading = true;
      this.dataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.hierarchyElementsService.getHierarchyChildrenElements(this.hierarchyElementSystemName),
        this.hierarchyElementsService.getHierarchyElementWithParentElements(this.hierarchyElementSystemName),
        this.corpHierarchiesService.getCorpHierarchies(corpId),
      ]).subscribe(
        ([corp, hierarchyElements, hierarchyElementWithParentElements, corpHierarchies]) => {
          const corpHierarchy = corpHierarchies.find(({ systemName }) => systemName === hierarchy);
          if (!corpHierarchy) {
            this.toaster.warning(`Hierarchy Doesn't Exist`);
            this.router.navigate(['/admin']);
            return;
          }
          this.corp = corp;
          this.parentElements = hierarchyElementWithParentElements;
          this.hierarchyElements = hierarchyElements;

          if (hierarchyElementSystemName) {
            this.elementTitle = this.parentElements.map(parentElement => parentElement?.label).join(' - ');
          } else {
            this.elementTitle = corp?.label;
          }

          this.corpHierarchy = corpHierarchy;
          this.corpHierarchies = corpHierarchies;
          this.nextDownLevelCorpHierarchy = corpHierarchies.find(({ order }) => order === this.corpHierarchy.order + 1);
          this.refreshUI();
          this.setSidebarItems();
          this.loading = false;
        },
        error => {
          this.toaster.error(error);
          this.loading = false;
        },
      );
    });
  }

  openAddHierarchyElementModal() {
    this.modalService.show(AddHierarchyElementModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpHierarchy: this.corpHierarchy,
        parentSystemName: this.hierarchyElementSystemName,
      },
    });
  }

  openEditHierarchyElementModal(hierarchyElement: HierarchyElementModel) {
    this.modalService.show(EditHierarchyElementModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        hierarchyElement,
        corpHierarchy: this.corpHierarchy,
      },
    });
  }

  confirmAndDeleteHierarchyElement(hierarchyElement: HierarchyElementModel) {
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.title = `Delete ${hierarchyElement.label}`;
    modalRef.content.message = `Are you sure you want to delete ${hierarchyElement.label}?`;
    modalRef.content.confirm.subscribe(async () => {
      this.actionProgress.start();
      if (await this.hierarchyElementsService.hasChildren(hierarchyElement.hierarchyName)) {
        this.toaster.error(
          `${hierarchyElement.label} has children elements. Please delete its children/bots before proceeding.`,
        );
        this.actionProgress.complete();
        return;
      }
      await this.deleteHierarchyElement(hierarchyElement);
      this.actionProgress.complete();
    });
  }

  private async deleteHierarchyElement(hierarchyElement: HierarchyElementModel) {
    try {
      await this.hierarchyElementsService.removeHierarchyElement(hierarchyElement.systemName);
      this.toaster.success(`${hierarchyElement.label} deleted successfully`);
    } catch (error) {
      this.toaster.error(error);
    }
  }

  refreshUI() {
    this.setBreadcrumb();
    this.headerService.setPageTitle(`${this.elementTitle} ${this.corpHierarchy.label}`);
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  private setBreadcrumb() {
    if (!this.corp) {
      return;
    }
    this.breadcrumbService.set(getBreadcrumbItems(this.corp, this.corpHierarchies, this.parentElements));
  }

  private setSidebarItems() {
    this.sidebarService.set(
      getSidebarItems(`${this.corp?.id}`, this.corpHierarchies, this.corpHierarchy, this.hierarchyElementSystemName),
    );
  }
}
