import { Component, Input, OnInit } from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { TimeInterval } from '../../_types/TimeInterval';
import { ToastrService } from 'ngx-toastr';
import { SortOrder } from '../../../../utils/sort/_types/SortOrder';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';

interface IKPIPerformance {
  name: string;
  frequency: number;
  percentageOfKpiHits: number;
  percentageOfTotalSessions: number;
}

const timeFrames = [
  new TimeInterval('1d'),
  new TimeInterval('7d'),
  new TimeInterval('1M'),
  new TimeInterval('6M'),
  new TimeInterval('1y'),
  new TimeInterval('YTD'),
  new TimeInterval('MAX')
];

@Component({
  selector: 'app-kpis-table',
  templateUrl: './kpis-table.component.html',
  styleUrls: ['./kpis-table.component.scss']
})
export class KpisTableComponent implements OnInit {
  loading: boolean;

  sortOrder: SortOrder = SortOrder.ASC;

  @Input()
  awsRegion: AwsRegionEnum;

  @Input()
  botId: string;

  timeFrames: TimeInterval[] = timeFrames;
  selectedTimeFrame = timeFrames[1];

  kpiPerformance: IKPIPerformance[];

  totalPercentageOfTotalSessions: number;
  totalKpisCount: number;

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private toaster: ToastrService) {}

  ngOnInit() {
    this.loadKpiPerformanceData();
  }

  processTimeFrameChange(timeFrame: TimeInterval) {
    this.selectedTimeFrame = timeFrame;
    this.loadKpiPerformanceData();
  }

  sortByField(fieldName: string) {
    this.sortOrder = this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;

    this.kpiPerformance = this.kpiPerformance.sort((a, b) => {
      const sortDifference = a[fieldName] - b[fieldName];
      if (this.sortOrder === SortOrder.DESC) {
        return -sortDifference;
      }
      return sortDifference;
    });
  }

  private async loadKpiPerformanceData() {
    this.loading = true;
    try {
      const { sessionCount, kpis } = await this.apiGatewayAnalyticsService.getKPIPerformance(
        this.botId,
        this.selectedTimeFrame.getType()
      );
      this.totalKpisCount = kpis.reduce((totalKpisCount, kpi) => (totalKpisCount += Number(kpi.count)), 0);

      this.kpiPerformance = kpis.map(({ count, kpi_name }) => ({
        name: kpi_name,
        frequency: count,
        percentageOfKpiHits: (count / this.totalKpisCount) * 100,
        percentageOfTotalSessions: (count / sessionCount) * 100
      }));
      this.totalPercentageOfTotalSessions = (this.totalKpisCount / sessionCount) * 100;
    } catch (error) {
      this.toaster.error("Error loading the KPI's data.");
    } finally {
      this.loading = false;
    }
  }
}
