export enum TemplateChannels {
  Email = 'email',
  // Facebook = 'facebook',
  SMS = 'sms',
  Web = 'directline',
}

export const TemplateChannelOptions = [
  { id: TemplateChannels.Web, name: 'Web' },
  // { id: TemplateChannels.Facebook, name: 'Facebook' },
  { id: TemplateChannels.Email, name: 'Email' },
  { id: TemplateChannels.SMS, name: 'SMS' },
];

export class TemplateModel {
  blob: string;
  exampleDataBlob: string;
  channel: string;
  rawTemplate: string;
  rawExampleData: object | null;

  static generateDefault(): TemplateModel {
    const template = new TemplateModel();
    template.blob = '';
    TemplateModel.setEncodeExampleDataBlob(template, '{}');
    template.channel = '';
    template.rawTemplate = '';
    template.rawExampleData = null;
    return template;
  }

  static setEncodeBlob(template: TemplateModel, value: string) {
    // set rawTemplate to value before encoding to base64 as blob
    template.rawTemplate = value;
    try {
      template.blob = window.btoa(value); // btoa doesn't support unicode
    } catch (e) {
      // ignore
    }
  }

  static setEncodeExampleDataBlob(template: TemplateModel, value: string) {
    template.rawExampleData = JSON.parse(value);
    try {
      template.exampleDataBlob = window.btoa(value); // btoa doesn't support unicode
    } catch (e) {
      // ignore
    }
  }

  static getDecodedBlob(blob: string): string {
    return window.atob(blob);
  }

  // Generates array of variables used in template
  static generatePaths(template: string) {
    const r1 = /{{(.*?)}}/gm;
    let paths: string[] = [];
    let m1;
    while ((m1 = r1.exec(template))) {
      // Cleanup to reduce syntax to variables
      let tokens = m1[1] + ' ';
      tokens = tokens.replace(/^(.*?)\s/, '');
      tokens = tokens.replace(/(.*?)=\"(.*?)\"/g, '');
      tokens = tokens.replace(/\s\"(.*?)\"/g, '');
      tokens = tokens.replace(/\s+(.*?)=/g, ' ');
      tokens = tokens.replace(/(.*?)=/g, ' ');
      tokens = tokens.replace(/\/(.*?)\s/g, '');
      tokens = tokens.replace(/\((.*?)\s/g, '');
      tokens = tokens.replace(/\)/g, '');
      if (!tokens) {
        continue;
      }
      paths = [
        ...paths,
        ...tokens
          .split(/\s+/)
          .filter(t => t !== '')
          .map(t => t.trim()),
      ];
    }
    return paths;
  }

  static getPathFromString(path: string) {
    return path.split(/\.|\[|\]/).filter(x => x !== '');
  }
}
