import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApiQueryModel } from 'src/app/models/api-query';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';
import { AddApiQueryModalComponent } from 'src/app/components/modals/add-api-query-modal/add-api-query-modal.component';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { BotModel } from 'src/app/models/bot';
import { ButtonType } from '../../common/_types/ButtonType';
import dayjs from 'dayjs';
import { SimpleListItemButton } from '../../lists/simple-list-item/_types/SimpleListItemButton';

@Component({
  selector: 'app-api-queries-page',
  templateUrl: './api-queries-page.component.html',
  styleUrls: ['./api-queries-page.component.scss'],
})
export class ApiQueriesPageComponent {
  _items: SimpleListItem[] = [];

  @Input()
  permissionToWriteApiQueries: Permissions;

  @Input()
  baseUrl: string;

  @Input()
  bot: BotModel;

  @Input()
  set apiQueries(apiQueries: ApiQueryModel[]) {
    this._items = (apiQueries || [])
      .filter(apiQuery => apiQuery.createdAt != null && apiQuery.updatedAt != null)
      .map(apiQuery => {
        const buttons: SimpleListItemButton[] = [];
        if (this.bot.apiGatewayServiceId) {
          buttons.push(
            {
              label: 'Edit',
              link: [this.baseUrl, 'api-queries', apiQuery.systemName],
            },
            {
              label: 'Delete',
              type: ButtonType.danger,
              action: this.handleApiQueryDelete.bind(this, apiQuery),
            },
          );
        }
        return {
          title: apiQuery.name,
          dataPoints: [
            {
              label: 'Created At',
              value: dayjs.unix(apiQuery.createdAt.seconds).format('MM-DD-YYYY hh:mm A'),
              className: 'simple-list-item-data-point--sm',
            },
            {
              label: 'Updated At',
              value: dayjs.unix(apiQuery.updatedAt.seconds).format('MM-DD-YYYY hh:mm A'),
              className: 'simple-list-item-data-point--sm',
            },
          ],
          buttons,
        };
      });
  }

  get items(): SimpleListItem[] {
    return this._items;
  }

  @Input()
  loading: boolean;

  @Output()
  handleDeleteApiQuery: EventEmitter<string>;

  @Input()
  flowTemplateId: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private toaster: ToastrService,
  ) {
    this.handleDeleteApiQuery = new EventEmitter<string>();
  }

  canWriteBotApiQueries(): boolean {
    return this.authService.hasPermissionSync(this.permissionToWriteApiQueries);
  }

  showAddModal() {
    if (!(this.bot.apiGatewayServiceId || this.flowTemplateId)) {
      if (this.authService.hasPermissionSync(Permissions.CAN_EDIT_CORP_SETTINGS)) {
        const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
        modalRef.content.title = `API ID not set for ${this.bot.label}`;
        modalRef.content.message = `Redirect me to where I can set it?`;
        modalRef.content.confirm.subscribe(() => {
          this.router.navigate([`${this.baseUrl}/settings`]);
        });
        return;
      }

      this.toaster.info(
        `Sorry! ${this.bot.label} doesn't have an associated API ID. Please add one in ${this.bot.label}'s settings page.`,
      );
      return;
    }
    this.modalService.show(AddApiQueryModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpId: this.bot.corpId,
        botId: this.bot.id,
        flowTemplateId: this.flowTemplateId,
      },
    });
  }

  handleApiQueryDelete(apiQuery: ApiQueryModel) {
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.title = `Delete ${apiQuery.name}`;
    modalRef.content.message = `Are you sure you want to delete ${apiQuery.name}?`;
    modalRef.content.confirm.subscribe(() => {
      this.handleDeleteApiQuery.emit(apiQuery.id);
    });
  }
}
