import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { FileCardItem } from './_types/FileCardItem';
import { ButtonType } from 'src/app/components/common/_types/ButtonType';
import { Subscription } from 'rxjs';
import { NlpDataset } from 'src/app/models/nlp/nlp-dataset/nlp-dataset';

@Component({
  selector: 'app-nlp-dataset-cards',
  templateUrl: './nlp-dataset-cards.component.html',
  styleUrls: ['./nlp-dataset-cards.component.scss']
})
export class NlpDatasetCardsComponent implements OnInit, OnDestroy {
  loading: boolean;
  datasetFileCardsList: FileCardItem[];
  isCollapsed = false;
  nlpDataset: NlpDataset;

  @Input()
  datasetName: string;

  @Input()
  datasetSystemName: string;

  @Input()
  hideFiles: string[] = [];

  @Input()
  lazyLoading: boolean;

  @Input()
  buttonLabel: string;

  @Input()
  buttonType: ButtonType;

  @Output()
  buttonClicked: EventEmitter<FileCardItem>;

  @Input()
  removeFileEvent: EventEmitter<FileCardItem>;

  @Input()
  addFileEvent: EventEmitter<FileCardItem>;

  removeNlpFileSubscription: Subscription;
  addNlpFileSubscription: Subscription;

  constructor(private nlpDatasetService: NlpDojoService) {
    this.buttonClicked = new EventEmitter<FileCardItem>();
  }

  ngOnInit() {
    this.checkRequiredFields();
    this.isCollapsed = !this.lazyLoading;

    if (!this.lazyLoading) {
      this.loadDatasetFiles();
    }
  }

  private loadDatasetFiles() {
    if (!this.datasetSystemName) {
      return;
    }

    this.loading = true;
    this.nlpDatasetService.getDataset('default', this.datasetSystemName).subscribe(
      nlpDataset => {
        this.nlpDataset = nlpDataset;
        this.refreshDatasetFiles();
        this.loading = false;
      }
    );

    this.removeNlpFileSubscription = this.removeFileEvent.subscribe(
      (fileCardItem: FileCardItem) => {
        this.hideFiles = this.hideFiles.filter(id => id != fileCardItem.id);
        this.refreshDatasetFiles();
      }
    );

    this.addNlpFileSubscription = this.addFileEvent.subscribe(
      (fileCardItem: FileCardItem) => {
        this.hideFiles.push(fileCardItem.id)
        this.refreshDatasetFiles();
      }
    );
  }

  private refreshDatasetFiles() {
    const filtered = this.nlpDataset.files.filter(file => file.file_valid && !this.hideFiles.includes(file.id));
    this.datasetFileCardsList = filtered.map(datasetFile => {
      const fileCardButton = {
        label: this.buttonLabel,
        type: this.buttonType,
        eventEmitter: this.buttonClicked
      };
      return new FileCardItem(
        datasetFile.id,
        this.nlpDataset.id,
        datasetFile.name,
        [fileCardButton]
      );
    });

  }

  toggleFilesSection() {
    if (!this.datasetFileCardsList) {
      this.loadDatasetFiles();
    }
    this.isCollapsed = !this.isCollapsed;
  }

  checkRequiredFields() {
    if (this.buttonLabel === undefined) {
      throw new Error("Attribute 'buttonLabel' is required");
    } else if (this.buttonType === undefined) {
      throw new Error("Attribute 'buttonType' is required");
    }
  }

  ngOnDestroy(): void {
    if (this.addFileEvent) {
      this.addFileEvent.unsubscribe();
    }
    if (this.removeFileEvent) {
      this.removeFileEvent.unsubscribe();
    }
  }
}
