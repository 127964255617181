import { Injectable } from '@angular/core';
import AWS from 'aws-sdk';
import { environment } from 'src/environments/environment';
import es from 'elasticsearch-browser';
import { AwsRegionEnum } from '../models/countries/AwsRegionEnum';

@Injectable({
  providedIn: 'root'
})
export class EsClientService {
  private static DEFAULT_REGION = AwsRegionEnum.US_EAST_1;

  private clientsMap: Map<AwsRegionEnum, es.Client> = new Map<AwsRegionEnum, es.Client>();

  constructor() {
    AWS.config.update({
      credentials: new AWS.Credentials({
        accessKeyId: environment.es[EsClientService.DEFAULT_REGION].accessKeyId,
        secretAccessKey: environment.es[EsClientService.DEFAULT_REGION].secretAccessKey
      }) // ,
      // region: 'us-east-1'
    });

    /*
    Initially will create an elasticSearch client for each region we have;
     */
    const awsRegions: AwsRegionEnum[] = Object.values(AwsRegionEnum);
    awsRegions.forEach(region => {
      const client = new es.Client({
        hosts: [environment.es[region].domain]
      });
      this.clientsMap.set(region, client);
    });
  }

  /**
   * If not provided. will be used the default defined region {@link AwsRegionEnum.US_EAST_1}
   *
   * Based on the provided region will be selected the ElasticSearch server to operate the action we need.
   */
  public getClient(region?: AwsRegionEnum) {
    return region ? this.clientsMap.get(region) : this.clientsMap.get(EsClientService.DEFAULT_REGION);
  }
}
