import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { BrainService } from 'src/app/services/brain.service';
import { ToastrService } from 'ngx-toastr';
import { NodeModel } from 'src/app/models/node';
import { SimpleEditorTab } from 'src/app/components/editors/simple-editor/_types/SimpleEditorTab';
import { NodesService } from 'src/app/services/firestore';

@Component({
  selector: 'app-edit-input-data-modal',
  templateUrl: './edit-input-data-modal.component.html',
  styleUrls: ['./edit-input-data-modal.component.scss'],
})
export class EditInputDataModalComponent {
  loading: boolean;
  node: NodeModel;
  index: string;
  tabs: SimpleEditorTab[] = [
    {
      id: 'input-data',
      label: 'Input Data',
      data: '',
      language: 'json',
      logs: [],
    },
    {
      id: 'example-data',
      label: 'Example Data',
      data: '',
      language: 'json',
      logs: [],
    },
  ];

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private nodesService: NodesService,
    private brainService: BrainService,
    private toaster: ToastrService,
  ) {
    const { node } = options.initialState as any;
    this.node = node;
    if (!this.node?.templates) {
      return;
    }

    let parsedInputData = '';
    let parsedInputDataExample = '';
    try {
      if (this.node.inputData) {
        parsedInputData = JSON.stringify(JSON.parse(this.node.inputData), null, 2);
      }
    } catch (e) {
      // ignore
    }

    try {
      if (this.node.inputDataExampleData) {
        parsedInputDataExample = JSON.stringify(JSON.parse(this.node.inputDataExampleData), null, 2);
      }
    } catch (e) {
      // ignore
    }

    if (parsedInputData) {
      this.tabs[0].data = parsedInputData;
    } else {
      if (this.node.inputData) {
        this.tabs[0].data = this.node.inputData;
      }
    }

    if (parsedInputDataExample) {
      this.tabs[1].data = parsedInputDataExample;
    } else {
      if (this.node.inputDataExampleData) {
        this.tabs[1].data = this.node.inputDataExampleData;
      }
    }
    this.loading = false;
  }

  saveAll(tabs: SimpleEditorTab[]) {
    this.loading = true;

    let parsedInputData = '';
    let parsedInputDataExample = '';

    const inputDataTab = tabs[0];
    const dataTab = tabs[1];
    let errorMessage = null;
    try {
      parsedInputData = JSON.parse(inputDataTab.data);
    } catch (e) {
      inputDataTab.logs = [
        {
          className: 'simple-editor-log--error',
          text: e,
        },
      ];
      errorMessage = e;
    }

    if (!parsedInputData) {
      this.toaster.error('Invalid input data:' + errorMessage);
      this.loading = false;
      return;
    }

    try {
      parsedInputDataExample = JSON.parse(dataTab.data);
    } catch (e) {
      dataTab.logs = [
        {
          className: 'simple-editor-log--error',
          text: e,
        },
      ];
      errorMessage = e;
    }

    if (!parsedInputDataExample) {
      this.toaster.error('Invalid example input data:' + errorMessage);
      this.loading = false;
      return;
    }

    // Validate template
    return this.brainService
      .compileTemplate({
        source: inputDataTab.data,
        data: JSON.parse(dataTab.data),
      })
      .subscribe(
        async response => {
          if (!this.node?.templates) {
            return;
          }
          this.loading = false;
          this.toaster.success('Saved Input Data');
          inputDataTab.logs = [];
          dataTab.logs = [];
          this.node.inputData = inputDataTab.data;
          this.node.inputDataExampleData = dataTab.data;
          const result = await this.nodesService.updateNode(this.node); // :eyes for flow templates
          this.modal.hide();
          return result;
        },
        error => {
          this.loading = false;
          this.toaster.error('Invalid Input Data');
          inputDataTab.logs = [
            {
              className: 'simple-editor-log--error',
              text: error,
            },
          ];
        },
      );
  }
}
