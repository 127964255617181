import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CorpModel } from 'src/app/models/corp';
import { UploadService } from 'src/app/services/upload.service';
import { flatMap, map } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CorpsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-corp-general-info',
  templateUrl: './corp-general-info.component.html',
  styleUrls: ['./corp-general-info.component.scss'],
})
export class CorpGeneralInfoComponent implements OnInit {
  loading: boolean;

  @Input()
  writable: boolean;

  @Input()
  corp: CorpModel;

  @Output()
  saved: EventEmitter<void>;

  @Output()
  updateTags: EventEmitter<string[]>;

  logoFile: File | null;

  @Input()
  corpTags: string[];

  isCorpInactive: boolean;

  constructor(
    private corpsService: CorpsService,
    private uploadService: UploadService,
    private toaster: ToastrService,
  ) {
    this.saved = new EventEmitter<void>();
    this.updateTags = new EventEmitter<string[]>();
  }

  ngOnInit() {
    this.isCorpInactive = this.corp.inactive;
  }

  save() {
    this.updateCorp();
  }

  private updateCorp() {
    if (!this.corp) {
      return;
    }
    this.loading = true;

    this.corp.inactive = this.isCorpInactive;

    const uploadObservable: Observable<void | null> = this.logoFile
      ? this.uploadService.upload('corps/' + this.corp.id + '/logo', this.logoFile).pipe(
          map(downloadUrl => {
            if (!this.corp) {
              return;
            }
            this.corp.logo = downloadUrl;
          }),
        )
      : of(null);
    uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.corp) {
            return of();
          }
          return from([await this.corpsService.updateCorp(this.corp)]);
        }),
      )
      .subscribe(
        () => {
          this.loading = false;
          const tags = this.corpTags;
          this.updateTags.emit(tags);
          this.saved.emit();

          this.toaster.success('Corp updated ');
        },
        error => {
          this.loading = false;
          this.toaster.error(error);
        },
      );
  }

  checkBoxChanged(event) {
    this.isCorpInactive = event.target.checked;
  }
}
