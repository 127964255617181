import { FormGroup, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { NodeConditionalOperator } from 'src/app/models/node';
import { isEmpty } from 'lodash';

export const getAllErrors = (form: FormGroup | FormArray): ValidationErrors | null => {
  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    const errors =
      control instanceof FormGroup || control instanceof FormArray ? getAllErrors(control) : control?.errors;
    if (errors) {
      acc[key] = errors;
    }
    return acc;
  }, {} as ValidationErrors);
  return isEmpty(result) ? null : result;
};

export const undefinedOrHasValue = (control: AbstractControl): ValidationErrors | null => {
  const isValid = control.value === undefined || control.value.length > 0;
  return isValid ? null : { notUndefined: { value: control.value } };
};

// This is for node connection conditionals. It can be empty if AND or OR is the operator.
export const validOperand = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent) {
    return null;
  }
  if ([NodeConditionalOperator.And, NodeConditionalOperator.Or].includes(control.parent.get('operator')?.value)) {
    return null;
  }
  return control.value ? null : { notUndefined: { value: control.value } };
};
