export class HierarchyElementModel {
  id: number;
  label: string;
  systemName: string;
  parent: string;
  lastTouchedBy: string;
  hierarchyName: string;
  corpId: string;
  logo?: string;

  get parentForUrl() {
    // This is fine since I want only the first to be removed
    return this.parent.replace(`${this.corpId}-`, '');
  }

  get systemNameForUrl() {
    return this.systemName.replace(`${this.corpId}-`, '');
  }
}
