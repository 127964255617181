import { Component, ComponentFactoryResolver, Injector, OnInit } from '@angular/core';
import { VisualTemplateBase } from '../visual-template-base';

@Component({
  selector: 'app-image',
  templateUrl: './visual-template-editor-image.component.html',
  styleUrls: ['./visual-template-editor-image.component.scss']
})
export class VisualTemplateEditorImageComponent extends VisualTemplateBase {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public injector: Injector) {
    super(componentFactoryResolver, injector);
  }
}
