import { Component, Input, OnInit } from '@angular/core';
import { SimpleCardItem } from '../simple-card/_types/SimpleCardItem';

@Component({
  selector: 'app-simple-card-list',
  templateUrl: './simple-card-list.component.html',
  styleUrls: ['./simple-card-list.component.scss']
})
export class SimpleCardListComponent implements OnInit {
  @Input()
  cardItemList: SimpleCardItem[];

  constructor() {}

  ngOnInit() {}
}
