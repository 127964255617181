import { Timestamp } from 'firebase-tools';

export enum NotificationType {
  ConversationAssigned = 'conversationAssigned',
}

export enum NotificationStatus {
  Read = 'read',
  Unread = 'unread',
}

export class UserNotificationModel {
  userId: string;
  id: string;
  notificationType: NotificationType;
  notificationData: Record<string, any>;
  createdAt: Timestamp;
  message: string;
  status: NotificationStatus;
  updatedAt: Timestamp;
  timeAgo: string;
}
