import { NlpModelFile } from './nlp-model-file';
import { Timestamp } from 'firebase-tools';

export class NlpModel {
  id: string;
  name: string;
  system_name: string;
  status: string;
  archived: boolean;
  archived_at: string | Timestamp;
  created_at: string | Timestamp;
  updated_at: string | Timestamp;
  training_progress: string | Timestamp;
  training_started_at: string | Timestamp;
  training_ended_at: string | Timestamp;
  evaluation: Record<string, any>;
  intents: string[];
  entities: object;
  intents_entities: object;
  artifact: string;
  files: NlpModelFile[];

  constructor(name: string) {
    this.name = name;
  }
}
