import { Component, Input } from '@angular/core';
import { ChartSettings } from '../_type/ChartSettings';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { IChartData, IFetchChartDataParameters } from '../types';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-chart-unique-users',
  templateUrl: './chart-unique-users.component.html',
  styleUrls: ['./chart-unique-users.component.scss']
})
export class ChartUniqueUsersComponent {
  @Input()
  corpId: string;

  @Input()
  hierarchyElementId: string;

  @Input()
  botId: string;

  /**
   * If not provided, will be used the default defined region {@link AwsRegionEnum.US_EAST_1}
   */
  @Input()
  awsRegion: AwsRegionEnum;

  chartData: IChartData = {
    data: [],
    labels: []
  };

  chartSettings: ChartSettings = {
    surtitle: 'Overview',
    title: 'Unique Users',
    timeIntervals: ['5d', '7d', '1M', '6M', '1y', 'YTD', 'MAX'],
    selectedTimeIntervalIndex: 0,
    type: 'line',
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          label: 'Unique Users',
          backgroundColor: 'rgba(154, 208, 245, 0.5)',
          pointBackgroundColor: 'rgb(77, 172, 237)',
          borderWidth: 1
        }
      ]
    },
    options: {
      scales: {
        xAxes: [
          {
            type: 'category',
            distribution: 'linear',
            display: true,
            ticks: {
              beginAtZero: true,
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 0,
              major: {
                enabled: false
              }
            }
          }
        ]
      }
    }
  };

  loading: boolean;

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private toaster: ToastrService) {}

  async fetchChartData({ selectedTimeFrame, countingPeriod }: IFetchChartDataParameters): Promise<void> {
    this.loading = true;
    try {
      this.chartData = await this.apiGatewayAnalyticsService.getUniqueUsersOverview(
        this.botId,
        selectedTimeFrame,
        `${countingPeriod}`
      );
    } catch (error) {
      this.toaster.error(error);
    } finally {
      this.loading = false;
    }
  }
}
