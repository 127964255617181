import { Timestamp } from 'firebase-tools';
import { NlpDatasetFile } from './nlp-dataset-file';

export class NlpDataset {
  id: string;
  name: string;
  system_name: string;
  archived: boolean;
  archived_at: string;
  created_at: Timestamp;
  updated_at: Timestamp;
  files: NlpDatasetFile[];

  constructor(name: string) {
    this.name = name;
  }
}
