import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonType } from '../../common/_types/ButtonType';
import { NlpDataset } from '../../../models/nlp/nlp-dataset/nlp-dataset';
import { FileCardItem } from '../nlp-dataset-cards/_types/FileCardItem';
import { Subscription } from 'rxjs';
import { NlpDojoService } from '../../../services/nlp-dojo.service';
import { ToastrService } from 'ngx-toastr';
import { NlpModelFile } from '../../../models/nlp/nlp-model/nlp-model-file';

@Component({
  selector: 'app-nlp-model-files',
  templateUrl: './nlp-model-files.component.html',
  styleUrls: ['./nlp-model-files.component.scss']
})
export class NlpModelFilesComponent implements OnInit, OnDestroy {
  successButtonType = ButtonType.success;

  /**
   * This should be a new and empty list, where will push all the selected files.
   * The parent component will use this files.
   *
   * Take care and DO NOT reinitialise this list because it is used to the parent component!
   */
  @Input()
  nlpModelFileItems: FileCardItem[];

  @Input()
  initialFiles: NlpModelFile[];

  @Input()
  className: string;

  @Output()
  loaded: EventEmitter<void>;

  nlpDataSets: NlpDataset[];
  hideNlpModelFiles: string[];

  removeNlpFileEvent: EventEmitter<FileCardItem>;
  addNlpFileEvent: EventEmitter<FileCardItem>;

  removeNlpFileSubscription: Subscription;
  addNlpFileSubscription: Subscription;

  constructor(private nlpDojoService: NlpDojoService, public toaster: ToastrService) {
    this.hideNlpModelFiles = [];
    this.removeNlpFileEvent = new EventEmitter<FileCardItem>();
    this.addNlpFileEvent = new EventEmitter<FileCardItem>();
    this.loaded = new EventEmitter<void>();
  }

  ngOnInit() {
    this.nlpDojoService.getDatasets('default').subscribe(result => {
      this.nlpDataSets = result;
      this.loaded.emit();
    });

    this.initialiseSubscriptions();
    this.initialiseNlpModelFileItems();
    this.refreshHideNlpModelFiles();
  }

  private initialiseSubscriptions() {
    this.addNlpFileSubscription = this.addNlpFileEvent.subscribe(fileCardItem => {
      this.addNlpFile(fileCardItem);
    });

    this.removeNlpFileSubscription = this.removeNlpFileEvent.subscribe(fileCardItem => {
      this.removeNlpFile(fileCardItem);
    });
  }

  private initialiseNlpModelFileItems() {
    // This exception helps when developing to integrate properly the component
    if (!this.nlpModelFileItems) {
      this.toaster.error('The provided nlp model files parameter should be initialised.');
      return;
    }
    if (this.initialFiles) {
      const initialNlpModelFileItems: FileCardItem[] = this.initialFiles.map(modelFile => {
        const fileItemButton = {
          label: 'Remove',
          type: ButtonType.danger,
          eventEmitter: this.removeNlpFileEvent
        };
        return new FileCardItem(modelFile.file?.id, modelFile.dataset?.id, modelFile.file?.name, [fileItemButton]);
      });

      this.nlpModelFileItems.push(...initialNlpModelFileItems);
    }
  }

  private addNlpFile(fileCardItem) {
    // convert buttons to removable buttons
    const convertedButtons = fileCardItem.buttons.map(() => {
      return {
        label: 'Remove',
        type: ButtonType.danger,
        eventEmitter: this.removeNlpFileEvent
      };
    });

    const fileCardItemToAdd = new FileCardItem(
      fileCardItem.id,
      fileCardItem.datasetId,
      fileCardItem.text,
      convertedButtons
    );

    if (this.existsModelFileItem(fileCardItemToAdd)) {
      this.toaster.warning('Dataset file already added');
    } else {
      this.nlpModelFileItems.push(fileCardItemToAdd);
      this.refreshHideNlpModelFiles();
    }
  }

  private existsModelFileItem(fileCardItemToAdd: FileCardItem): boolean {
    return this.nlpModelFileItems.find(nlpModelFileItem => nlpModelFileItem.id === fileCardItemToAdd.id) !== undefined;
  }

  private removeNlpFile(fileCardItem) {
    // removing the given element from the list
    const objectIndex = this.nlpModelFileItems.findIndex(nlpModelFile => nlpModelFile.id === fileCardItem.id);
    if (objectIndex > -1) {
      this.nlpModelFileItems.splice(objectIndex, 1);
    }

    this.refreshHideNlpModelFiles();
  }

  private refreshHideNlpModelFiles() {
    this.hideNlpModelFiles = this.nlpModelFileItems.map(file => file.id);
  }

  ngOnDestroy(): void {
    if (this.removeNlpFileSubscription) {
      this.removeNlpFileSubscription.unsubscribe();
    }
    if (this.addNlpFileSubscription) {
      this.addNlpFileSubscription.unsubscribe();
    }
  }
}
