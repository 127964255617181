import { TimeInterval } from 'src/app/components/analytics/_types/TimeInterval';

export class AnalyticsCardInterval30D extends TimeInterval {
  constructor() {
    super('30d');
  }

  getPastDate(): Date {
    const date = new Date(Date.now());
    date.setDate(date.getDate() - 59);
    return date;
  }

  getExplanation(): string {
    return 'Over the last 30 days';
  }
}
