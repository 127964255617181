import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InputValidationModel } from 'src/app/models/input-validation';
import { ToastrService } from 'ngx-toastr';
import { InputValidationsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-input-validation-general-info',
  templateUrl: './input-validation-general-info.component.html',
  styleUrls: ['./input-validation-general-info.component.scss'],
})
export class InputValidationGeneralInfoComponent implements OnInit {
  loading: boolean;

  @Input()
  writable: boolean;

  @Input()
  inputValidation: InputValidationModel;

  @Input()
  shouldAdd = false;

  @Output()
  saved: EventEmitter<void>;

  constructor(private inputValidationsService: InputValidationsService, private toaster: ToastrService) {
    this.saved = new EventEmitter<void>();
  }

  ngOnInit() {}

  save() {
    this.loading = true;
    if (this.shouldAdd) {
      this.add();
    } else {
      this.update();
    }
  }

  private add() {
    InputValidationModel.generateSystemName(this.inputValidation);
    this.inputValidationsService
      .addInputValidation(this.inputValidation)
      .then(() => {
        this.loading = false;
        this.toaster.success('Input validation created');
        this.saved.emit();
      })
      .catch(error => {
        this.loading = false;
        this.toaster.error(error);
      });
  }

  private update() {
    InputValidationModel.generateSystemName(this.inputValidation);
    this.inputValidationsService
      .updateInputValidation(this.inputValidation)
      .then(() => {
        this.loading = false;
        this.toaster.success('Input validation updated');
        this.saved.emit();
      })
      .catch(error => {
        this.loading = false;
        this.toaster.error(error);
      });
  }
}
