import { Timestamp } from 'firebase-tools';
import { TemplateChannels } from './template';
import firebase from 'firebase';
// text / webchat / email / command / localResponse / handoverStatus
enum HLConversationMessageType {
  HandOver = 'handoverStatus',
  Text = 'text',
  Email = 'email',
  WebChat = 'webchat',
  LocalResponse = 'localResponse',
  Command = 'command',
  Facebook = 'facebook',
}

export interface IConversationClickEvent {
  conversation: HLConversationModel;
  isAutoSelectedConversation: boolean;
}

export class HLConversationAgent {
  agentAvatarUrl: string;
  agentId: string;
  agentName: string;
}
export class HLCorp {
  conversation_tags: string[];
  corpId: string;
}
export class HLConversationUserData {
  botFrameworkUserId: string;
  email: string;
  fullName: string;
  firstName: string;
  ipAddress: string;
  lastName: string;
  phoneNumber: string;
}
export class HLConversationModel {
  assignments?: HLConversationAgent[];
  assignmentUserIds?: string[];
  assignmentCount?: number;
  botId: string;
  botName: string;
  channel: HLUserResponseChannels;
  conversationState: object;
  firebaseDocumentId?: string; // access to doc ID when not available in document properties
  fromEmails?: { name: string; address: string }[];
  handover: boolean;
  hierarchyElements: string[];
  id: string;
  lastChannel: TemplateChannels;
  lastMessage?: string;
  lastMessageDate?: Timestamp;
  lastUpdated: Timestamp;
  lastUpdatedString: string;
  messages: ConversationMessageModel[];
  needsAttentionContinue?: boolean;
  needsAttentionInitial?: boolean;
  nodeName?: string;
  status: HLConversationStatus;
  tags: string[];
  tagToolTipText: string;
  toEmailAddresses?: string[];
  touched: boolean;
  user: any;
  userData?: HLConversationUserData;
  userSMS?: string;
  intent?: string;
  campaign?: string;
}

export class HLConversationNotes {
  createdAt: Timestamp;
  note: string;
  user: HLConversationAgent;
}

export enum HLConversationStatus {
  Read = 'read',
  Unread = 'unread',
}

export type HLUserResponseChannels = 'web' | 'email' | 'directline' | 'facebook' | 'sms';

export class HLHumanAgentModel {
  agentId: string;
  conversationId: string;
  assignedTime: Timestamp;
  botId: string;
}

export class HLTemplateModel {
  name: string;
  id: string;
  botId: string;
  channel: string;
  content: string;
  createdBy: string;
  createdAt: Timestamp;
  lastUpdated: Timestamp;
  tags: string[];
}

export class ConversationMessageModel {
  id: string;
  message: string;
  channel: string;
  userType: User;
  createdAt: Timestamp;
  formattedTime: string;
  preview: string;
  messageType: string;
  nodeName?: string;
  emailSubject?: string;
  emailFrom?: string;
  emailTo?: string;
  userId?: string;
  agentName?: string;
  firebaseDocumentId?: string;

  static addSetNodeLogMessage(message: ConversationMessageModel) {
    const { messageType, agentName, nodeName } = message;
    if (messageType === 'setNode') {
      if (agentName && nodeName) {
        message.message = `${agentName} ${nodeName}`;
      }
    }
    return message;
  }
  static isSystemMessage(message: ConversationMessageModel) {
    return (
      message.messageType === HLConversationMessageType.LocalResponse ||
      message.messageType === HLConversationMessageType.Command
    );
  }
  static getLocalResponseMessage(message: string) {
    try {
      const obj = JSON.parse(message);
      const { localResponse } = obj;
      if (localResponse) {
        /*
        {
          "localResponse":{
            "result":{
              "parameters":{"handoverService":"carlabs"},
              "action":"enable.handoff"
            },
            "label":"Get Another Human",
            "button_type":"button"
          }
        }
        */
        if (localResponse.button_type === 'button') {
          return localResponse.label ?? null;
        }
      }
    } catch (error) {}
    return null;
  }
}

export class ConversationTurn {
  id: string;
  message: string;
  channel: string;
  replies: string[];
  responseType: string;
  timestamp: string;
}

enum User {
  Customer,
  Bot,
  Support,
}

export interface IFirebaseFilterCriteria {
  field: string | firebase.firestore.FieldPath;
  value: any;
  operator: firebase.firestore.WhereFilterOp;
}

export interface IFilterCriteria {
  id: string;
  name: string;
  value: string;
  operator: FilterOperator;
  field?: string;
  firebaseFilter?: IFirebaseFilterCriteria;
}

export interface IFirebaseFilterOperator {
  operator: firebase.firestore.WhereFilterOp;
  field: string;
}

export interface FilterFieldOption {
  id: string;
  name: string;
}
export interface FilterField {
  id: string;
  name: string;
  options?: FilterFieldOption[];
}

export interface FilterOperator {
  id: string;
  name: string;
  validator?: (value: any) => boolean;
  errorMessage?: string;
  firebaseFilter?: IFirebaseFilterOperator;
}

export type FilterFunctionType = (conversation: HLConversationModel, criteria: IFilterCriteria) => boolean;

export type FilterFunctionsType = { [field: string]: { [operator: string]: FilterFunctionType } };

export const buildFirebaseQuery = (
  query: firebase.firestore.Query,
  criteria: IFilterCriteria[],
): firebase.firestore.Query => {
  return criteria.reduce((q, c) => {
    if (c.firebaseFilter) {
      return applyFirebaseCriteria(q, c.firebaseFilter);
    } else {
      return q;
    }
  }, query);
};

export const applyFirebaseCriteria = (
  query: firebase.firestore.Query,
  criteria: IFirebaseFilterCriteria,
): firebase.firestore.Query => {
  if (criteria.field) {
    return query.where(criteria.field, criteria.operator, criteria.value);
  }
  return query;
};

const buildStringFieldFilterFunctions = key => {
  return {
    '==': (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.userData && conversation.userData[key]) {
        return conversation.userData[key].trim().toLowerCase() === criteria.value.trim().toLowerCase();
      }
      return false;
    },
    contains: (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.userData && conversation.userData[key]) {
        return conversation.userData[key].trim().toLowerCase().includes(criteria.value.trim().toLowerCase());
      }
      return false;
    },
    starts_with: (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.userData && conversation.userData[key]) {
        return conversation.userData[key].trim().toLowerCase().startsWith(criteria.value.trim().toLowerCase());
      }
      return false;
    },
  };
};

export const FilterFunctions: FilterFunctionsType = {
  email: {
    '==': (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.userData && conversation.userData.email) {
        return conversation.userData.email.trim().toLowerCase() === criteria.value.trim().toLowerCase();
      }
      return false;
    },
    contains: (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.userData && conversation.userData.email) {
        return conversation.userData.email.toLowerCase().includes(criteria.value.toLowerCase());
      }
      return false;
    },
  },
  fullName: buildStringFieldFilterFunctions('fullName'),
  lastName: buildStringFieldFilterFunctions('lastName'),
  firstName: buildStringFieldFilterFunctions('firstName'),
  tags: {
    contains: (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.tags) {
        return conversation.tags.includes(criteria.value);
      }
      return false;
    },
  },
  channel: {
    '==': (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation && conversation.lastChannel) {
        return conversation.lastChannel.trim().toLowerCase() === criteria.value.trim().toLowerCase();
      }
      return false;
    },
  },
  needsAttention: {
    '==': (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (criteria.value === 'true') {
        return conversation.needsAttentionInitial === true;
      } else if (criteria.value === 'false') {
        return !conversation.needsAttentionInitial;
      }
      return false;
    },
  },
  conversation_id: {
    '==': (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.id) {
        return conversation.id.trim().toLowerCase() === criteria.value.trim().toLowerCase();
      }
      return false;
    },
    contains: (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.id) {
        return conversation.id.trim().toLowerCase().includes(criteria.value.trim().toLowerCase());
      }
      return false;
    },
    starts_with: (conversation: HLConversationModel, criteria: IFilterCriteria) => {
      if (conversation.id) {
        return conversation.id.trim().toLowerCase().startsWith(criteria.value.trim().toLowerCase());
      }
      return false;
    },
  },
};

enum FilterOperatorTypes {
  Equals = '==',
  Contains = 'contains',
  StartsWith = 'starts_with',
}

export const FilterFields: FilterField[] = [
  {
    id: 'conversation_id',
    name: 'Conversation ID',
  },
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'botId',
    name: 'Assistant ID',
  },
  {
    id: 'tags',
    name: 'Tags',
  },
  {
    id: 'channel',
    name: 'Channel',
    options: [
      { id: TemplateChannels.Web, name: 'Web' },
      { id: TemplateChannels.SMS, name: 'SMS' },
      { id: TemplateChannels.Email, name: 'Email' },
    ],
  },
  {
    id: 'needsAttention',
    name: 'Needs Attention',
    options: [
      { id: 'true', name: 'Yes' },
      { id: 'false', name: 'No' },
    ],
  },
];
const commonStringOperators = [
  { id: FilterOperatorTypes.Equals, name: 'Equal' },
  { id: FilterOperatorTypes.Contains, name: 'Contains' },
  { id: FilterOperatorTypes.StartsWith, name: 'Starts With' },
];

export const FieldOperators: { [key: string]: FilterOperator[] } = {
  email: [
    {
      validator: (email: string) => {
        // tslint:disable-next-line: max-line-length
        const emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email.toLowerCase());
      },
      id: FilterOperatorTypes.Equals,
      name: 'Equal',
      errorMessage: 'Valid email address is required',
    },
    { id: FilterOperatorTypes.Contains, name: 'Contains' },
  ],
  tags: [
    {
      id: FilterOperatorTypes.Contains,
      name: 'Contains',
      firebaseFilter: {
        field: 'tags',
        operator: 'array-contains',
      },
    },
  ],
  fullName: commonStringOperators,
  lastName: commonStringOperators,
  firstName: commonStringOperators,
  botId: [
    {
      id: FilterOperatorTypes.Equals,
      name: 'Equal',
      firebaseFilter: {
        field: 'botId',
        operator: '==',
      },
    },
    { id: FilterOperatorTypes.Contains, name: 'Contains' },
    { id: FilterOperatorTypes.StartsWith, name: 'Starts With' },
  ],
  channel: [
    {
      id: FilterOperatorTypes.Equals,
      name: 'Equal',
      firebaseFilter: {
        field: 'lastChannel',
        operator: '==',
      },
    },
  ],
  needsAttention: [
    {
      id: FilterOperatorTypes.Equals,
      name: 'Equal',
      firebaseFilter: {
        field: 'needsAttention',
        operator: '==',
      },
    },
  ],
  conversation_id: [
    {
      id: FilterOperatorTypes.Equals,
      name: 'Equal',
      firebaseFilter: {
        field: 'id',
        operator: '==',
      },
    },
    { id: FilterOperatorTypes.Contains, name: 'Contains' },
    { id: FilterOperatorTypes.StartsWith, name: 'Starts With' },
  ],
};
