import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { map, flatMap, take } from 'rxjs/operators';
import { of, from, Observable } from 'rxjs';
import { UploadService } from 'src/app/services/upload.service';
import { ToastrService } from 'ngx-toastr';
import { HierarchyElementModel, CorpHierarchyModel } from 'src/app/models';
import { HierarchyElementsService, PermissionsService } from 'src/app/services/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { sluggify } from 'src/app/utils/strings/string.utils';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { PermissionModel } from 'src/app/models/permission';

@Component({
  selector: 'app-hierarchy-element-general-info',
  templateUrl: './hierarchy-element-general-info.component.html',
  styleUrls: ['./hierarchy-element-general-info.component.scss'],
})
export class HierarachyElementGeneralInfoComponent implements OnInit {
  loading: boolean;
  userId: string;
  hierarcyLabelSlug: string;

  @Input()
  writable: boolean;

  @Input()
  hierarchyElement: HierarchyElementModel;

  @Input()
  corpHierarchy: CorpHierarchyModel;

  @Input()
  parentSystemName: string;

  @Input()
  shouldAdd = false;

  @Output()
  saved: EventEmitter<void>;

  logoFile: File | null;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private hierarchyElementsService: HierarchyElementsService,
    private uploadService: UploadService,
    private permissionsService: PermissionsService,
    private toaster: ToastrService,
    private authService: AuthService,
  ) {
    this.saved = new EventEmitter<void>();
  }

  async ngOnInit() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }
    this.userId = user.uid;
  }

  changeHierarcyLabelSlug() {
    this.hierarcyLabelSlug = sluggify(this.hierarchyElement.label);
  }

  private async addHierarchyElement() {
    if (!this.hierarchyElement) {
      return;
    }

    const hierarchyElementSystemName = `${this.parentSystemName}-${this.hierarcyLabelSlug}`;

    const elementExist = await this.hierarchyElementsService
      .getHierarchyElement(hierarchyElementSystemName)
      .pipe(take(1))
      .toPromise();

    if (elementExist) {
      this.loading = false;
      this.actionProgress.complete();
      this.toaster.warning(`${this.corpHierarchy.singular} Already Exist`);
      return;
    }

    this.hierarchyElement.lastTouchedBy = this.userId;
    this.hierarchyElement.hierarchyName = this.corpHierarchy.systemName;
    this.hierarchyElement.parent = this.parentSystemName;
    this.hierarchyElement.systemName = hierarchyElementSystemName;
    this.hierarchyElement.corpId = this.parentSystemName.split('-')[0];

    const uploadObservable: Observable<void | null> = this.getUploadObservable();
    uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.hierarchyElement) {
            return of();
          }
          return from([await this.hierarchyElementsService.addHierarchyElement(this.hierarchyElement)]);
        }),
      )
      .subscribe(
        async () => {
          this.loading = false;
          this.saved.emit();
          await this.addPermission();
          this.actionProgress.complete();
          this.toaster.success(`${this.corpHierarchy.singular} created successfully`);
        },
        error => {
          this.loading = false;
          this.actionProgress.complete();
          this.toaster.error(error);
        },
      );
  }

  private async addPermission() {
    const permission = new PermissionModel();
    permission.userId = this.userId;
    permission.hierarchyElementSystemName = this.hierarchyElement.systemName;
    permission.corpId = this.hierarchyElement.corpId;
    await this.permissionsService.addPermission(permission);
  }

  private udpateHierarchyElement() {
    if (!this.hierarchyElement) {
      return;
    }
    this.hierarchyElement.lastTouchedBy = this.userId;
    const uploadObservable: Observable<void | null> = this.getUploadObservable();
    uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.hierarchyElement) {
            return of();
          }
          return from([await this.hierarchyElementsService.updateHierarchyElement(this.hierarchyElement)]);
        }),
      )
      .subscribe(
        () => {
          this.loading = false;
          this.actionProgress.complete();
          this.saved.emit();
          this.toaster.success(`${this.corpHierarchy.singular} updated successfully`);
        },
        error => {
          this.loading = false;
          this.actionProgress.complete();
          this.toaster.error(error);
        },
      );
  }

  private getUploadObservable() {
    return this.logoFile
      ? this.uploadService.upload('hierarchies/' + this.hierarchyElement.systemName + '/logo', this.logoFile).pipe(
          map(downloadUrl => {
            if (!this.hierarchyElement) {
              return;
            }
            this.hierarchyElement.logo = downloadUrl;
          }),
        )
      : of(null);
  }

  save() {
    this.loading = true;
    this.actionProgress.start();
    if (this.shouldAdd) {
      this.addHierarchyElement();
    } else {
      this.udpateHierarchyElement();
    }
  }
}
