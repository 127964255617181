import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { HLCorp } from 'src/app/models/conversations';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';
@Component({
  selector: 'app-hl-conversation-tags',
  templateUrl: './hl-conversation-tags.component.html',
  styleUrls: ['./hl-conversation-tags.component.scss'],
})
export class HlConversationTagsComponent implements OnInit {
  constructor(public modal: BsModalRef, options: ModalOptions, private humanLoopService: HumanInLoopService) {
    this.save = new EventEmitter<string[]>();
    const { tags, corpTags } = options.initialState as any;
    this.tags = tags ?? [];
    this.corpTags = corpTags ?? [];
  }

  @Output()
  save: EventEmitter<string[]>;
  tags: string[] = [];
  corpTags: string[];

  ngOnInit() {}

  saveTags() {
    this.save.emit(this.tags);
    this.modal.hide();
  }
  addTag(tag: string) {
    if (tag) {
      this.tags = this.tags.filter(t => t !== tag);
      this.tags.push(tag);
    }
  }
}
