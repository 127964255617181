import { VisualTemplateEditorImageComponent } from '../image/visual-template-editor-image.component';
import { VisualTemplateEditorButtonComponent } from '../button/visual-template-editor-button.component';
import { Component, ComponentFactoryResolver, Injector } from '@angular/core';
import { VisualTemplateBase } from '../visual-template-base';

@Component({
  selector: 'app-visual-template-editor-card',
  templateUrl: './visual-template-editor-card.component.html',
  styleUrls: ['./visual-template-editor-card.component.scss']
})
export class VisualTemplateEditorCardComponent extends VisualTemplateBase {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public injector: Injector) {
    super(componentFactoryResolver, injector);
  }

  public availableElements = [
    {
      name: 'button',
      component: VisualTemplateEditorButtonComponent
    },
    {
      name: 'image',
      component: VisualTemplateEditorImageComponent
    }
  ];
}
