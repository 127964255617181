import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalVariable, GlobalVariableType, VariableTypeMapping } from 'src/app/models/bot';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
import { UpsertGlobalVariableModalComponent } from '../upsert-global-variable-modal/upsert-global-variable-modal.component';
import { sluggify } from 'src/app/utils/strings/string.utils';
import { BsModalService } from 'ngx-bootstrap';
import uniqBy from 'lodash/uniqBy';

export interface IGlobalVariablesPropagationPayload {
  variableType: GlobalVariableType;
  globalVariables: GlobalVariable[];
}

@Component({
  selector: 'app-render-global-variables',
  templateUrl: './render-global-variables.component.html',
  styleUrls: ['./render-global-variables.component.scss']
})
export class RenderGlobalVariableComponent implements OnInit {
  @Input()
  items: GlobalVariable[];

  @Input()
  title: string;

  @Input()
  variableType: GlobalVariableType;

  @Input()
  description: string;

  @Output()
  handleChanges: EventEmitter<IGlobalVariablesPropagationPayload>;

  constructor(private toaster: ToastrService, private modalService: BsModalService) {
    this.handleChanges = new EventEmitter<IGlobalVariablesPropagationPayload>();
  }

  ngOnInit() {}

  onClickCreate() {
    this.openUpsertGlobalVariableModal({
      id: '',
      label: '',
      slug: '',
      defaultValue: '',
      description: '',
      type: this.variableType
    });
  }

  onClickEdit(globalVariable: GlobalVariable) {
    this.openUpsertGlobalVariableModal({ ...globalVariable });
  }

  onClickDelete(globalVariable: GlobalVariable) {
    this.items = this.items.filter(({ id }) => id !== globalVariable.id);
    this.broadcastChanges();
  }

  processCopySuccess() {
    this.toaster.success('Successfully copied to clipboard');
  }

  upsertGlobalVariable(globalVariable: GlobalVariable) {
    const variableTypeMapping = VariableTypeMapping[globalVariable.type];
    if (!variableTypeMapping) {
      throw new Error(`There is no mapping for ${globalVariable.type}`);
    }
    globalVariable.slug = sluggify(variableTypeMapping) + '__' + sluggify(globalVariable.label);
    if (globalVariable.id) {
      const index = this.items.findIndex(({ id }) => id === globalVariable.id);
      this.items[index] = globalVariable;
      this.items = uniqBy(this.items, 'slug');
      this.broadcastChanges();
      return;
    }
    globalVariable.id = uuidv4();
    this.items = uniqBy([...this.items, globalVariable], 'slug');
    this.broadcastChanges();
  }

  openUpsertGlobalVariableModal(globalVariable: GlobalVariable) {
    this.modalService.show(UpsertGlobalVariableModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        globalVariable,
        onGlobalVariableSave: (globalVariable$1: GlobalVariable) => {
          this.upsertGlobalVariable(globalVariable$1);
        }
      }
    });
  }

  private broadcastChanges() {
    this.handleChanges.emit({ variableType: this.variableType, globalVariables: this.items });
  }
}
