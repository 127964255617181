import { Component, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import classNamesNormalizer from 'classnames';
import engagementsData from '../analytics-list/data/engagements.json';
import messagesSentData from '../analytics-list/data/messages-sent.json';
import totalOutreachData from '../analytics-list/data/total-outreach.json';
import triageData from '../analytics-list/data/triage.json';
import {
  AnalyticsListHeaderItem,
  AnalyticsListItem,
  AnalyticsListItemProgressColors,
} from '../_types/AnalyticsListItem';
import { SupportedTabs } from '../overview-charts/_types/SupportedTabsType';

const data = {
  [SupportedTabs.TOTAL_OUTREACH]: totalOutreachData,
  [SupportedTabs.ENGAGEMENTS]: engagementsData,
  [SupportedTabs.MESSAGES_SENT]: messagesSentData,
  [SupportedTabs.TRIAGE]: triageData,
};

@Component({
  selector: 'app-analytics-list',
  templateUrl: './analytics-list.component.html',
  styleUrls: ['./analytics-list.component.scss'],
})
export class AnalyticsListComponent implements OnChanges {
  @Input() botId: number = 0;
  @Input() data: AnalyticsListItem[][];
  @Input() hasPagination?: boolean = false;
  @Input() header: AnalyticsListHeaderItem[][];
  @Input() hierarchyId: number = 0;
  @Input() label: string;
  @Input() rowHeaderClassNames: string = '';
  @Input() progressColor: AnalyticsListItemProgressColors = 'blue';

  @Input() selectedTab = SupportedTabs;

  loading = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const selectedTab = changes.selectedTab.currentValue;

    this.data = data[selectedTab].data;
    this.header = data[selectedTab].header;
  }

  normalizeClassNames(...classNames): string {
    return classNamesNormalizer(classNames);
  }
}
