import { Component } from '@angular/core';
import { NlpDatasetFile } from 'src/app/models/nlp/nlp-dataset/nlp-dataset-file';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rename-dataset-file-modal',
  templateUrl: './rename-dataset-file-modal.component.html',
  styleUrls: ['./rename-dataset-file-modal.component.scss']
})
export class RenameDatasetFileModalComponent {
  nlpDatasetFile: NlpDatasetFile;
  newName: string;
  datasetSystemName: string;

  constructor(
    private toaster: ToastrService,
    private nlpDojoService: NlpDojoService,
    public modal: BsModalRef,
    options: ModalOptions
  ) {
    if (!options.initialState) {
      return;
    }
    const { datasetFile, datasetSystemName } = options.initialState as any;
    this.nlpDatasetFile = datasetFile;
    this.datasetSystemName = datasetSystemName;
    this.newName = datasetFile.name;
  }

  async update() {
    this.nlpDatasetFile.name = this.newName;
    this.nlpDojoService
      .updateDatasetFile('default', this.datasetSystemName, this.nlpDatasetFile.system_name, this.nlpDatasetFile)
      .subscribe(updatedNlpDatasetFile => {
        this.modal.hide();
        this.toaster.success('Dataset file updated successfully');
      });
  }
}
