import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleModel } from 'src/app/models';
import { plainToClass } from 'class-transformer';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private rolesCollection: AngularFirestoreCollection<RoleModel>;

  constructor(private afs: AngularFirestore) {
    this.rolesCollection = afs.collection<RoleModel>(COLLECTION_NAMES.ROLES);
  }

  async addRole(role: RoleModel): Promise<void> {
    const systemName = role.systemName;
    const result = await this.rolesCollection.doc(systemName).ref.get();
    if (result.exists) {
      throw new Error(`Role "${systemName}" already exists`);
    } else {
      return this.rolesCollection.doc(systemName).set(Object.assign({}, role));
    }
  }

  updateRole(role: RoleModel): Promise<void> {
    return this.rolesCollection.doc(role.systemName).update({ ...role });
  }

  getRoleById(roleSystemName: string): Observable<RoleModel | null> {
    return this.rolesCollection
      .doc(roleSystemName)
      .valueChanges()
      .pipe(
        map(role => {
          return plainToClass(RoleModel, role);
        }),
      );
  }

  getRoles(): Observable<RoleModel[]> {
    return this.rolesCollection.valueChanges().pipe(
      map(roles => {
        return roles.map(role => plainToClass(RoleModel, role));
      }),
    );
  }

  removeRole(roleSystemName: string) {
    return this.rolesCollection.doc(roleSystemName).delete();
  }
}
