import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { ActivatedRoute } from '@angular/router';
import { getSidebarItems as corpGetSidebarItems } from '../../pages/portal/corp/utils';
import { getSidebarItems as portalGetSidebarItems } from '../../pages/portal/utils';
import { combineLatest, Subscription } from 'rxjs';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { FlowTemplateModel } from 'src/app/models';
import { CorpsService, FlowTemplatesService } from 'src/app/services/firestore';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { ApiQueryModel } from 'src/app/models/api-query';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-flow-template-api-queries',
  templateUrl: './flow-template-api-queries.component.html',
  styleUrls: ['./flow-template-api-queries.component.scss'],
})
export class FlowTemplateApiQueriesComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  private flowEditorEventsSubscription: Subscription;
  corp: CorpModel;
  corpId: string;
  bot: BotModel;
  flowTemplate: FlowTemplateModel;
  apiQueries: ApiQueryModel[] = [];
  private isGlobal: boolean;
  permissionToWriteApiQueries = Permissions.CAN_WRITE_FLOW_TEMPLATE_API_QUERIES;
  loading: boolean;
  baseUrl: string;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private corpsService: CorpsService,
    private flowTemplatesService: FlowTemplatesService,
    private headerService: HeaderService,
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.paramMapSubscription = combineLatest([this.route.paramMap]).subscribe(([params]) => {
      const corpId = params.get('corp');
      const flowTemplateId = params.get('flowTemplateId');
      if (!flowTemplateId) {
        return;
      }
      if (!corpId) {
        this.isGlobal = true;
      }

      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(`${corpId}`),
        this.flowTemplatesService.getFlowTemplateById(flowTemplateId),
        this.flowTemplatesService.getFlowTemplatesApiQueries(flowTemplateId),
      ]).subscribe(async ([corp, flowTemplate, apiQueries]) => {
        if (!flowTemplate) {
          return;
        }
        const bot = flowTemplate.getBotConfig;
        if (!bot) {
          return;
        }
        this.bot = bot;
        if (corp) {
          this.corp = corp;
          this.corpId = corp.id;
        }
        this.loading = false;
        this.flowTemplate = flowTemplate;
        this.apiQueries = apiQueries;
        if (this.isGlobal) {
          this.baseUrl = `/portal/global-flow-templates/${flowTemplate.systemName}`;
        } else {
          this.baseUrl = `/portal/corps/${this.corp.id}/flow-templates/${flowTemplate.systemName}`;
        }
        this.refreshUI();
      });
    });
  }

  handleApiQueryDelete(apiQueryId: string) {
    try {
      this.flowTemplatesService.removeApiQuery(this.flowTemplate.systemName, apiQueryId);
      this.toaster.success('API Query Deleted Successfully');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private refreshUI() {
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
    this.headerService.setPageTitle(`${this.flowTemplate.label} API Queries`);
  }

  private setSidebarItems(corpId: string) {
    if (this.isGlobal) {
      this.sidebarService.set(portalGetSidebarItems());
    } else {
      this.sidebarService.set(corpGetSidebarItems(corpId));
    }
  }

  private setBreadcrumb(corp: CorpModel) {
    if (this.isGlobal) {
      this.breadcrumbService.set([
        {
          label: 'Global Flow Templates',
          route: '/portal/global-flow-templates',
        },
      ]);
    } else {
      this.breadcrumbService.set([
        {
          label: corp.label,
          icon: corp.logo,
          route: `corps/${corp.id}`,
          testId: 'bread-crumb-corp',
        },
        {
          label: 'Flow Templates',
          route: `corps/${corp.id}/flow-templates`,
        },
      ]);
    }
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.flowEditorEventsSubscription) {
      this.flowEditorEventsSubscription.unsubscribe();
    }
  }
}
