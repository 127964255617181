import { Component } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { NodeKpiModel, NodeModel } from '../../../models/node';
import { sluggify } from '../../../utils/strings/string.utils';
import { ToastrService } from 'ngx-toastr';
import { KpiDataType } from './_types/KpiDataType';
import { ConversationMessageModel, HLConversationModel } from 'src/app/models/conversations';
import { take } from 'rxjs/internal/operators/take';
import { CorpModel } from 'src/app/models';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { CorpsService, HumanInLoopService, NodesService } from 'src/app/services/firestore';

@Component({
  selector: 'app-add-node-kpi-modal',
  templateUrl: './add-node-kpi-modal.component.html',
  styleUrls: ['./add-node-kpi-modal.component.scss'],
})
export class AddNodeKpiModalComponent {
  modalUsage: 'hil-level' | 'corp-level' | 'node-level';
  addNewKpi: boolean;
  kpyDataTypes: string[] = Object.values(KpiDataType);

  node: NodeModel;
  botUniqueKpis: NodeKpiModel[];
  conversation: HLConversationModel;
  message: ConversationMessageModel;
  corpId: string;

  nodeKpiOptions: NodeKpiModel[];
  selectedNodeKpiOption: NodeKpiModel;
  nodeKpi: NodeKpiModel = new NodeKpiModel();

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private nodesService: NodesService,
    private corpsService: CorpsService,
    private humanInLoopService: HumanInLoopService,
    private toaster: ToastrService,
    private apiGatewayAnalyticsService: ApiGatewayAnalyticsService,
  ) {
    const { node, botUniqueKpis, conversation, corpId, message } = options.initialState as any;

    // if used outside of flow, we read from all kpis available in the bot flow
    // as well as those created at the corp level
    if (conversation && message) {
      this.modalUsage = 'hil-level';
      this.conversation = conversation;
      this.message = message;
      const assumedCorpId = this.conversation.botId.split('-')[0];
      this.initHumanInLoopKpiData(this.conversation.botId, assumedCorpId);
    } else if (corpId) {
      this.modalUsage = 'corp-level';
      this.initCorpKpiData(corpId);
    } else {
      this.modalUsage = 'node-level';
      this.initNodeKpiData(node, botUniqueKpis);
    }
  }

  save(kpiForm) {
    switch (this.modalUsage) {
      case 'corp-level':
        // for corp level use, save to corp
        this.handleCorpLevelSave();
        break;

      case 'hil-level':
        // for hil level, apply to message
        this.handleHilLevelSave();
        break;

      case 'node-level':
        // for node level, push to node
        this.handleNodeLevelSave();
        break;

      default:
        this.handleNodeLevelSave();
        break;
    }
  }

  updateKpiSystemName() {
    this.nodeKpi.systemName = sluggify(this.nodeKpi.name);
  }

  checkExistingKpi(kpiSystemName: string, existingKpis: NodeKpiModel[]): boolean {
    const existsKpi = existingKpis?.findIndex(kpi => kpi.systemName === kpiSystemName);
    return existsKpi !== undefined && existsKpi >= 0;
  }

  processKpiDataTypeChange(kpiDataType: KpiDataType) {
    this.nodeKpi.dataType = kpiDataType;
    if (kpiDataType === KpiDataType.BOOLEAN) {
      this.nodeKpi.value = 'true';
    }
  }

  private async handleHilLevelSave() {
    try {
      // HL appends _incoming and _outgoing to message ID's (since the message ID is the same for a conversation turn)
      const messageIdSplit = this.message.id.split('_');
      messageIdSplit.pop();
      const messageId = messageIdSplit.join('_');

      await this.apiGatewayAnalyticsService.setAnalyticsKpi(messageId, this.selectedNodeKpiOption);
      await this.humanInLoopService.touchConversation(this.corpId, this.conversation.id);
      this.toaster.success('KPI added successfully');
      this.modal.hide();
    } catch (error) {
      console.log('Error', error);
      this.toaster.error('Something went wrong... please try again later');
    }
  }

  private handleCorpLevelSave() {
    if (!this.nodeKpiOptions?.length) {
      this.nodeKpiOptions = [];
    }

    if (this.checkExistingKpi(this.nodeKpi.systemName, this.nodeKpiOptions)) {
      this.toaster.error('This KPI is already added.');
      return;
    }

    this.nodeKpiOptions.push(this.nodeKpi);

    this.saveCorpKpiChangesToDb(this.nodeKpiOptions);
    this.modal.hide();
  }

  private handleNodeLevelSave() {
    if (!this.node.kpis?.length) {
      this.node.kpis = [];
    }

    // apply kpi
    if (!this.addNewKpi) {
      this.node.kpis.push(this.selectedNodeKpiOption);
      this.saveNodeKpiChangesToDb(this.node);
      this.modal.hide();
      return;
    }

    if (this.checkExistingKpi(this.nodeKpi.systemName, this.node.kpis)) {
      this.toaster.error('This KPI is already added.');
      return;
    }

    this.node.kpis.push(this.nodeKpi);

    this.saveNodeKpiChangesToDb(this.node);
    this.modal.hide();
  }

  private saveNodeKpiChangesToDb(node: NodeModel) {
    this.nodesService
      .updateNode(node) // :eyes for flow templates
      .then(result => {
        this.toaster.success('KPI added successfully');
      })
      .catch(error => {
        this.toaster.error(error);
      });
  }

  private async saveCorpKpiChangesToDb(kpis: NodeKpiModel[]) {
    try {
      await this.corpsService.updateCorpKpis(this.corpId, kpis);
      this.toaster.success('KPI added successfully');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private async initHumanInLoopKpiData(botId: string, corpId: string) {
    // get kpis from nodes
    const nodes: NodeModel[] = await this.nodesService.getNodesByBotId(botId).pipe(take(1)).toPromise();
    let kpis: NodeKpiModel[] = this.getKpisFromNodes(nodes);

    // get kpis from corp level
    const corpData: CorpModel | null = await this.corpsService.getCorpById(corpId).pipe(take(1)).toPromise();
    if (corpData && corpData.kpis.length) {
      kpis = [...kpis, ...corpData.kpis];
    }

    const uniqueKpis = kpis.filter(
      (currentVal, index, arr) => arr.findIndex(kpi => kpi.systemName === currentVal.systemName) === index,
    );

    // update options
    this.nodeKpiOptions = uniqueKpis;
  }

  private getKpisFromNodes(nodes: NodeModel[]): NodeKpiModel[] {
    const nodesWithKpis: NodeKpiModel[] = [];
    nodes.filter(node => {
      if (node.kpis && node.kpis.length) {
        node.kpis.map(kpi => {
          nodesWithKpis.push(kpi);
        });
      }
    });
    return nodesWithKpis;
  }

  private initNodeKpiData(node: NodeModel, botUniqueKpis: NodeKpiModel[]): void {
    this.node = node;
    this.botUniqueKpis = botUniqueKpis;
    this.nodeKpi.dataType = KpiDataType.NUMBER;

    // constructing the list of node kpis already possible to use
    this.nodeKpiOptions = this.botUniqueKpis.filter(
      botKpi => !this.checkExistingKpi(botKpi.systemName, node?.kpis || []),
    );
    if (!this.nodeKpiOptions?.length) {
      this.addNewKpi = true;
    }
  }

  private async initCorpKpiData(corpId: string) {
    const corp: CorpModel | null = await this.corpsService.getCorpById(corpId).pipe(take(1)).toPromise();
    if (corp && corp.kpis.length) {
      this.corpId = corp?.id;
      this.nodeKpiOptions = corp.kpis;
    }
  }
}
